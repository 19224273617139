import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumAutenticacaoEntrar {
    Login: string;
    Senha: string;
}

interface Dados {
    usuario_ID: number;
    tipo_usuario_ID: number;
    Nome: string;
    Foto: string;
    Usuario: string;
    Token: string;
    EmailConfirmado: boolean;
    Email: string;
}

export interface RespostaComumAutenticacaoEntrar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumAutenticacaoEntrar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/autenticacao/entrar", dados);
}

export default comumAutenticacaoEntrar