import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoEditorNoticiasAprovar {
    noticia_ID: number;
    noticia_confiabilidade_ID: number;
    Resenha: string;
    Links?: string[];
}

export interface RespostaEditorNoticiasAprovar {
    Status: boolean;
    Mensagem: string;
}

export const editorNoticiasAprovar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/noticias/aprovar", dados);
}

export default editorNoticiasAprovar
