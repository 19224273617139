import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorNoticiasReacaoAtualizar {
    noticia_ID: number;
    reacao_tipo_ID: number;
}

export interface RespostaLeitorNoticiasReacaoAtualizar {
    Status: boolean;
    Mensagem: string;
}

export const leitorNoticiasReacaoAtualizar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/noticias/reacao/atualizar", dados);
}

export default leitorNoticiasReacaoAtualizar
