import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, useMediaQuery, Paper, Card } from "@material-ui/core"
import { Fragment } from "react";

export const Carbela = ({ colunas, linhas, larguraMaxima }: { colunas: any[][], linhas: any[], larguraMaxima: number }) => {
    const movel = useMediaQuery(`(max-width: ${larguraMaxima}px)`);
    return <Fragment>
        {movel ? <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {linhas.map((linha: any[]) => {
                            return <>
                                <Card variant="outlined" style={{ margin: '10px' }}>
                                    {linha.map((coluna: any, index) => {
                                        return <TableRow>
                                            <TableCell>
                                                {colunas[index][0]}
                                            </TableCell>
                                            <TableCell>
                                                {coluna}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Card>
                            </>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </> : <>
            <TableContainer>
                <Table style={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            {colunas.map(coluna => <TableCell style={{ width: coluna[1] }}>{coluna[0]}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {linhas.map(linha => <TableRow>
                            {linha.map((coluna: any) => <>
                                <TableCell>{coluna}</TableCell>
                            </>)}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </>}
    </Fragment>
}

export default Carbela