import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorNoticiasAdicionar {
    Link: string;
    Titulo: string;
    Capa: string;
    Texto: string;
    Comentario?: string;
    Tags_IDs: number[];
}

interface Dados {
    Slug: string;
}

export interface RespostaLeitorNoticiasAdicionar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const leitorNoticiasAdicionar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/noticias/adicionar", dados);
}

export default leitorNoticiasAdicionar
