import { Capacitor } from "@capacitor/core";
import { Button, ButtonBase, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, SwipeableDrawer } from "@material-ui/core"
import { Android, Apple, Web } from "@material-ui/icons";
import { useState } from "react"
import { isIOS, isAndroid } from "react-device-detect"
const BaixarAppContainer = () => {
    const [aberto, definirAberto] = useState(true);
    const abrirApp = () => {
        //definirAberto(false);
        isAndroid && window.open("https://play.google.com/store/apps/details?id=com.nv.noticiaverificada", "_blank");
        isIOS && window.open("https://apps.apple.com/us/app/noticia-verificada/id1612331136", "_blank");
    }
    return <SwipeableDrawer
        anchor="bottom"
        open={aberto}
        onClose={() => definirAberto(false)}
        onOpen={() => definirAberto(true)}
    >
        <List subheader={<ListSubheader>
            Abrir Noticia Verificada no...
        </ListSubheader>}>

            <ListItem>

                <ButtonBase onClick={abrirApp} style={{ flexGrow: 1, display: "flex", textAlign: "left" }}>
                        <ListItemAvatar>
                            {isAndroid ? <Android /> : <Apple />}
                        </ListItemAvatar>
                        <ListItemText>
                            Aplicativo
                        </ListItemText>
                </ButtonBase>
                <ListItemSecondaryAction>
                    <Button onClick={abrirApp} style={{ float: "right" }} variant="contained">Abrir</Button>
                </ListItemSecondaryAction>

            </ListItem>


            <ListItem>
                <ButtonBase onClick={() => definirAberto(false)} style={{ flexGrow: 1, display: "flex", textAlign: "left" }}>
                    <ListItemAvatar>
                        <Web />
                    </ListItemAvatar>
                    <ListItemText>
                        Navegador
                    </ListItemText>
                </ButtonBase>
                <ListItemSecondaryAction>
                    <Button onClick={() => definirAberto(false)} variant="contained">Abrir</Button>
                </ListItemSecondaryAction>
            </ListItem>

        </List>
    </SwipeableDrawer>
}
export const BaixarApp = () => {
    const mostrarPopUp = (isIOS || isAndroid) && Capacitor.getPlatform() === "web";
    if (mostrarPopUp) {
        return <BaixarAppContainer />
    }
    return <></>
}