import { Button, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useContext } from "react";
import { contextoServico } from "../../contextos/servicos";

const BarraMensagens = () => {
    const { mensagens, despachoMensagens } = useContext(contextoServico);
    return <>
        {mensagens.map(mensagem => {
            let tipo = mensagem.tipo as "success" | "error";
            return <Snackbar
                key={mensagem.ID}
                open={!mensagem.dispensado}
                onClose={() => {
                    despachoMensagens({ acao: 'DispensarMensagem', dados: { ID: mensagem.ID } })
                }}
            >
                <Alert severity={tipo} action={<>
                    {mensagem.funcoes ? mensagem.funcoes.map((funcao, index) => {
                        return <Button key={index} color="inherit" onClick={funcao.Funcao}>{funcao.Nome}</Button>
                    }) : <Button color="inherit" onClick={() => {
                        despachoMensagens({ acao: 'DispensarMensagem', dados: { ID: mensagem.ID } })
                    }}>
                        <Close />
                    </Button>}
                </>}>{mensagem.mensagem}</Alert>
            </Snackbar>
        })}
    </>;
}

export default BarraMensagens