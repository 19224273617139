export default (Likes: number, Deslikes: number, Tipo: "Like" | "Deslike") => {
    const Total = Likes + Deslikes;
    let Percentual = 0.5;
    if(Total !== 0 && Tipo === "Like") {
        Percentual = Likes / Total;
    }
    if(Total !== 0 && Tipo === "Deslike") {
        Percentual = Deslikes / Total;
    }
    return (Percentual*100).toFixed(2) + "%";
}