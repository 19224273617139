import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorAtualizar {
    Nome?: string;
    Bio?: string;
    Usuario?: string;
    Foto?: string;
}

export interface RespostaLeitorAtualizar {
    Status: boolean;
    Mensagem: string;
}

export const leitorAtualizar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/atualizar", dados);
}

export default leitorAtualizar
