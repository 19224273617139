import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Button, ListItemSecondaryAction } from "@material-ui/core";
import { FavoriteBorder, Favorite, DeleteForever } from "@material-ui/icons";
import { useContext } from "react";
import contextoAutenticacao from "../../../contextos/autenticacao";
import contextoComentarios from "../../../contextos/comentarios";
import ResponderComentario from "./ResponderComentario";

export const ComentariosListagem = () => {
    const { estado, acoes } = useContext(contextoComentarios);
    const { estado: estadoAutenticacao } = useContext(contextoAutenticacao);
    const { usuario_ID, tipo_usuario_ID } = estadoAutenticacao;
    return <>
        {estado.Comentarios.map(comentario => {
            return <>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={comentario.usuario_Foto}>
                            {comentario.usuario_Usuario.split('')[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={
                        <Typography variant="body1">
                            {comentario.Comentario}
                        </Typography>
                    } secondary={
                        <>
                            <Typography variant="body2" style={{ fontSize: '10px' }}>
                                <b>@{comentario.usuario_Usuario}</b> {[1, 3].includes(comentario.tipo_usuario_ID) && <>
                                    <img src="/imagens/selecionado.png" />
                                </>} • {comentario.DataHora}
                            </Typography>
                            <Button style={{ minWidth: '0px', paddingLeft: '4px' }} onClick={() => { acoes.reacao(comentario.ID) }} startIcon={comentario.tipo_reacao_ID === 1 ? <FavoriteBorder /> : <Favorite />}>{comentario.Likes}</Button>
                            <ResponderComentario comentario_ID={comentario.ID} />
                        </>
                    } />
                    {(comentario.usuario_ID === usuario_ID || tipo_usuario_ID === 1) && <>
                        <ListItemSecondaryAction>
                            <Button onClick={() => {
                                acoes.excluir({ comentario_ID: comentario.ID });
                            }} size="small" style={{ minWidth: '0px' }}>
                                <DeleteForever />
                            </Button>
                        </ListItemSecondaryAction>
                    </>}
                </ListItem>

                {
                    estado.Respostas.filter(resposta => resposta.comentario_ID === comentario.ID).map(comentario => {
                        return <>
                            <ListItem style={{ paddingLeft: "min(70px, 20vw)" }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {comentario.usuario_Usuario.split('')[0]}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography variant="body1">
                                        {comentario.Comentario}
                                    </Typography>
                                } secondary={
                                    <>
                                        <Typography variant="body2" style={{ fontSize: '10px' }}>
                                            <b>@{comentario.usuario_Usuario}</b> {[1, 3].includes(comentario.tipo_usuario_ID) && <>
                                                <img src="/imagens/selecionado.png" />
                                            </>} • {comentario.DataHora}
                                        </Typography>
                                        <Button style={{ minWidth: '0px', paddingLeft: '4px' }} onClick={() => { acoes.reacao(comentario.ID) }} startIcon={comentario.tipo_reacao_ID === 1 ? <FavoriteBorder /> : <Favorite />}>{comentario.Likes}</Button>
                                        <ResponderComentario comentario_ID={comentario.comentario_ID} arroba={"@" + comentario.usuario_Usuario} />
                                    </>
                                } />
                            </ListItem>
                        </>
                    })
                }
            </>
        })}
    </>
}

export default ComentariosListagem;