import { createContext, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";

interface Estado {
    loginAberto: boolean;
    tipoLogin: "Entrar" | "Cadastrar";
    adicionarAberto: boolean;
    menuAberto: boolean;
    politicaAberto: boolean;
    usuariosPremiumAberto: boolean;
    tamanhoHistorico: number;
}

interface ContextoAplicacao {
    estado: Estado,
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
    }
}

export const contextoAplicacao = createContext({} as ContextoAplicacao);

export const ProvedorAplicacao = ({ children }: { children: ReactNode }) => {

    const history = useHistory();

    const valoresPadrao: Estado = {
        loginAberto: false,
        tipoLogin: "Entrar",
        adicionarAberto: false,
        menuAberto: false,
        politicaAberto: false,
        usuariosPremiumAberto: false,
        tamanhoHistorico: 0,
    };

    const [estado, definirEstado] = useState(valoresPadrao);

    const acoes = {
        definirEstado
    };

    const valores: ContextoAplicacao = {
        estado,
        acoes
    };

    useEffect(() => {
        history.listen((_,action) => {
            action === "PUSH" && definirEstado(e=>{
                return {
                    ...e,
                    tamanhoHistorico: e.tamanhoHistorico+1
                }
            });
            action === "POP" && definirEstado(e=>{
                return {
                    ...e,
                    tamanhoHistorico: e.tamanhoHistorico-1
                }
            });
        });
    }, []);

    return <contextoAplicacao.Provider value={valores}>
        {children}
    </contextoAplicacao.Provider>
}