export default [
    // {
    //     "ID": 1,
    //     "Nome": "Plano Prata",
    //     "ValorSemestral": 9000.00,
    //     "vantagens": [
    //         "Revisão diária de conteúdos com menção",
    //         "Hashtag exclusiva"
    //     ]
    // },
    // {
    //     "ID": 2,
    //     "Nome": "Plano Gold",
    //     "ValorSemestral": 18000.00,
    //     "vantagens": [
    //         "Revisão diária de conteúdos com menção",
    //         "Hashtag exclusiva",
    //         "Banner 728x90  com veiculação de uma semana a cada mês",
    //     ]
    // },
    {
        "ID": 1,
        "Nome": "Assinatura",
        "ValorSemestral": 3540.00,
        "vantagens": [
            "Revisão diária de conteúdos com menção",
            "2 Hashtags exclusiva",
            "Banner 940x788  com veiculação de uma semana a cada mês",
        ]
    }
];