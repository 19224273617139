import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useContext, useReducer } from "react";
import { createContext, ReactNode } from "react";
import baseURL from "../funcoes/baseURL";
import { redutorMensagens } from "../redutores/mensagens";
import { contextoAplicacao } from "./aplicacao";
import contextoConfiguracoes from "./configuracoes";

interface RespostaAPI<T> {
    Status: boolean,
    Dados?: T,
    Mensagem?: string,
}

interface ContextoServicoProps {
    buscador: AxiosInstance,
    mensagens: ReturnType<typeof redutorMensagens>,
    despachoMensagens: (value: Parameters<typeof redutorMensagens>[1]) => void,
    usarRota: (rota: (buscador: any, dados?: any) => any, dados?: any) => Promise<any>
}

export const contextoServico = createContext({} as ContextoServicoProps);

export const ProvedorServico = ({ children }: { children: ReactNode }) => {


    const [mensagens, despachoMensagens] = useReducer(redutorMensagens, []);

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);

    const token = useContext(contextoConfiguracoes).estado.tokenAutenticacao;

    const buscador = axios.create({
        baseURL: baseURL(),
        validateStatus: (_) => true,
        headers: {
            Authorization: token
        }
    });

    const manipulador = (resposta: AxiosResponse<RespostaAPI<any>>) => {

        if (resposta.status === 403) {
            acoesAplicacao.definirEstado({ ...estadoAplicacao, tipoLogin: 'Entrar', loginAberto: true })
        }
        else if (resposta.status > 300 || (resposta.data && !resposta.data.Status)) {
            const mensagem = resposta.data.Mensagem || "Erro no Servidor!";
            despachoMensagens({ acao: 'NovaMensagem', dados: { mensagem, tipo: 'error' } });
        }
        return resposta
    }

    const usarRota = async (rota: (buscador: AxiosInstance, dados?: any) => Promise<AxiosResponse>, dados?: any) => {
        try {
            const requisicao = await rota(buscador, dados);
            manipulador(requisicao);
            return requisicao.data; 
        }
        catch (err: any) {
            return
        }
    }

    const valores: ContextoServicoProps = {
        buscador,
        mensagens,
        despachoMensagens,
        usarRota,
    };

    return <contextoServico.Provider value={valores} >{children}</contextoServico.Provider>;
}