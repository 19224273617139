import { AxiosInstance, AxiosResponse } from "axios";

interface Usuario {
    ID: number,
    TipoID: number,
    Nome: string,
    Foto: string,
    Usuario: string,
    Email: string,
    EmailConfirmado: boolean
}

interface Dados {
    Usuario?: Usuario;
}

export interface RespostaComumInicio {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumInicio = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/inicio", dados);
}

export default comumInicio
