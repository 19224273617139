import React, { createContext, useContext, useEffect, useState } from "react";
import leitorNotificacoesListar, { RespostaLeitorNotificacoesListar } from "../servicos/leitorNotificacoesListar";
import contextoAutenticacao from "./autenticacao";
import contextoConfiguracoes from "./configuracoes";
import { contextoServico } from "./servicos";

interface ContextoNotificacao {
    estado: RespostaLeitorNotificacoesListar["Dados"] | undefined;
    acoes: {
        atualizar: () => Promise<void>;
    };
}

export const contextoNotificacao = createContext({} as ContextoNotificacao);

export const ProvedorNotificacao = ({ children }: { children: React.ReactNode }) => {
    const { usarRota } = useContext(contextoServico);
    const { estado: estadoUsuario } = useContext(contextoAutenticacao);
    const { estado: estadoConfiguracoes } = useContext(contextoConfiguracoes);
    const [estado, definirEstado] = useState<RespostaLeitorNotificacoesListar["Dados"]>();
    const acoes = {
        atualizar: async () => {
            const resposta: RespostaLeitorNotificacoesListar = await usarRota(leitorNotificacoesListar);
            if (resposta.Status) {
                definirEstado(resposta.Dados);
            }
        }
    }
    // Gambiarra para atualizar o estado do contextoNotificacao ao entrar na página  
    useEffect(() => {
        if (estadoUsuario.autenticado && estadoConfiguracoes.tokenAutenticacao) {
            acoes.atualizar();
        }
    }, [estadoUsuario.autenticado, estadoConfiguracoes.tokenAutenticacao]);
    const valores = { estado, acoes };
    return <contextoNotificacao.Provider value={valores}>
        {children}
    </contextoNotificacao.Provider>
}