import { Avatar, Card, CardContent, Container, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { Notifications } from "@material-ui/icons"
import { Fragment, useContext } from "react"
import { useHistory } from "react-router-dom"
import { contextoNotificacao } from "../../contextos/notificacao"
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria"

export const Notificacoes = () => {

    const { estado } = useContext(contextoNotificacao);
    const history = useHistory();

    if (estado) {
        return <>
            <BarraAplicativoSecundaria />
            <Container maxWidth="md">
                <List>{estado.map((notificacao) => {
                    return <Fragment key={notificacao.ID}>
                        <ListItem
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                            alignItems="flex-start"
                            button
                            onClick={() => history.push(`/noticia/${notificacao.noticiaSlug}`)}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <Notifications />
                                    {/* {(() => {
                                        switch (notificacao.notificacao_tipo_ID) {
                                            case 1:
                                                return <LibraryAddCheck />
                                            default:
                                                return <Notifications />
                                        }
                                    })()} */}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={notificacao.Titulo} secondary={notificacao.noticiaTitulo} />
                            <ListItemAvatar>
                                <Avatar variant="square" src={notificacao.noticiaCapa} />
                            </ListItemAvatar>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Fragment>
                })}
                </List>
            </Container>
        </>
    }

    return <Fragment>
        <BarraAplicativoSecundaria />
        <Fragment>
            <Container maxWidth="md">
                <br /><br /><Card>
                    <CardContent style={{ textAlign: 'center' }}>
                        <br />
                        <Notifications /> <br />
                        Não há notificações para mostrar
                        <br />
                    </CardContent>
                </Card>
            </Container>
        </Fragment>
    </Fragment>
}