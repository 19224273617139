import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import logicaLikes from "../funcoes/logicaLikes";
import ComumNoticiasVer, { RequisicaoComumNoticiasVer, RespostaComumNoticiasVer } from "../servicos/comumNoticiasVer";
import LeitorNoticiasReacaoAtualizar, { RequisicaoLeitorNoticiasReacaoAtualizar, RespostaLeitorNoticiasReacaoAtualizar } from "../servicos/leitorNoticiasReacaoAtualizar";
import { contextoServico } from "./servicos";

interface Estado {
    Carregado: boolean,
    Dados?: RespostaComumNoticiasVer['Dados']
}

interface ContextoNoticia {
    estado: Estado;
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        carregar: () => Promise<void>;
        reacao: (reacao_tipo_ID: number) => Promise<boolean>;
    };
}

export const contextoNoticia = createContext({} as ContextoNoticia);

export const ProvedorNoticia = ({ children, Slug }: { children: ReactNode, Slug: string }) => {

    const { usarRota } = useContext(contextoServico);

    const estadoPadrao: Estado = {
        Carregado: false
    }

    const [estado, definirEstado] = useState<Estado>(estadoPadrao);

    const acoes = {
        definirEstado,
        carregar: async () => {
            const requisicao: RequisicaoComumNoticiasVer = { Slug };
            const resposta: RespostaComumNoticiasVer = await usarRota(ComumNoticiasVer, requisicao);
            if (resposta.Status) {
                definirEstado({ Carregado: true, Dados: resposta.Dados });
            }
        },
        reacao: async (reacao_tipo_ID: number) => {

            const noticia = estado.Dados?.Noticia;

            if (!noticia) {
                return false
            }

            const {
                tipo_reacao_ID,
                Likes,
                Deslikes
            } = logicaLikes({
                reacao_tipo_ID,
                antiga_reacao_tipo_ID: noticia.tipo_reacao_ID,
                Likes: noticia.Likes,
                Deslikes: noticia.Deslikes
            });

            const requisicao: RequisicaoLeitorNoticiasReacaoAtualizar = {
                noticia_ID: Number(estado.Dados?.Noticia.ID),
                reacao_tipo_ID: tipo_reacao_ID
            };

            const resposta: RespostaLeitorNoticiasReacaoAtualizar = await usarRota(LeitorNoticiasReacaoAtualizar, requisicao);

            if (estado.Dados && resposta.Status) {
                definirEstado({
                    ...estado,
                    Dados: {
                        ...estado.Dados,
                        Noticia: {
                            ...estado.Dados.Noticia,
                            tipo_reacao_ID,
                            Likes,
                            Deslikes
                        }
                    }
                });
                return true;
            }
            return false;
        }
    };

    useEffect(() => {
        acoes.carregar();
    }, []);

    const valores: ContextoNoticia = { estado, acoes };

    return <contextoNoticia.Provider value={valores}>{children}</contextoNoticia.Provider>
}