import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoEditorNoticiasExcluir {
    noticia_ID: number;
}

export interface RespostaEditorNoticiasExcluir {
    Status: boolean;
    Mensagem: string;
}

export const editorNoticiasExcluir = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/noticias/excluir", dados);
}

export default editorNoticiasExcluir
