import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import comumTagsListar, { RequisicaoComumTagsListar, RespostaComumTagsListar } from "../servicos/comumTagsListar";
import editorTagsAdicionar, { RequisicaoEditorTagsAdicionar, RespostaEditorTagsAdicionar } from "../servicos/editorTagsAdicionar";
import editorTagsAtualizar, { RequisicaoEditorTagsAtualizar, RespostaEditorTagsAtualizar } from "../servicos/editorTagsAtualizar";
import leitorTagsSeguir, { RequisicaoLeitorTagsSeguir, RespostaLeitorTagsSeguir } from "../servicos/leitorTagsSeguir";
import { contextoServico } from "./servicos";


interface Estado {
    Tags: RespostaComumTagsListar['Dados']['Tags']
}

interface ContextoTags {
    estado: Estado;
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        seguirTag: (tag_ID: number) => Promise<boolean>;
        adicionarTag: (requisicao: RequisicaoEditorTagsAdicionar) => Promise<boolean>;
        alterarTag: ({ ID, Mostrar }: RequisicaoEditorTagsAtualizar) => Promise<boolean>;
    }
};

export const contextoTags = createContext({} as ContextoTags);

export const ProvedorTags = ({ children }: { children: ReactNode }) => {

    const valoresPadrao: Estado = {
        Tags: []
    };

    const [estado, definirEstado] = useState(valoresPadrao);

    const acoes = {
        definirEstado,
        seguirTag: async (tag_ID: number) => {
            const tag = estado.Tags.find(tag => tag.ID === tag_ID);
            if (tag) {
                const Seguir = !tag.Seguindo;
                const requisicao: RequisicaoLeitorTagsSeguir = {
                    tag_ID,
                    Seguir
                }
                const resposta: RespostaLeitorTagsSeguir = await usarRota(leitorTagsSeguir, requisicao);
                if (resposta.Status) {
                    definirEstado({
                        ...estado,
                        Tags: estado.Tags.map(
                            tag => tag.ID === tag_ID ? {
                                ...tag,
                                Seguindo: Seguir
                            } : tag
                        )
                    });
                }
            }
            return false;
        },
        adicionarTag: async (requisicao: RequisicaoEditorTagsAdicionar) => {
            const resposta: RespostaEditorTagsAdicionar = await usarRota(editorTagsAdicionar, requisicao);
            if (resposta.Status) {
                definirEstado({
                    ...estado, Tags: [{
                        ID: resposta.Dados.ID,
                        Capa: "",
                        Mostrar: true,
                        Nome: requisicao.Nome,
                        Quantidade: 0,
                        Seguindo: false,
                        Slug: resposta.Dados.Slug
                    }, ...estado.Tags]
                })
                return true;
            }
            return false;
        },
        alterarTag: async ({ ID, Mostrar }: RequisicaoEditorTagsAtualizar) => {
            const requisicao: RequisicaoEditorTagsAtualizar = {
                ID,
                Mostrar
            };
            const resposta: RespostaEditorTagsAtualizar = await usarRota(editorTagsAtualizar, requisicao);
            if (resposta.Status) {
                definirEstado({
                    ...estado,
                    Tags: estado.Tags.map(
                        tag => tag.ID === ID
                            ? {
                                ...tag,
                                Mostrar: Mostrar || false
                            }
                            : tag
                    )
                });
                return true;
            }
            return false;
        }
    };

    const valores = {
        estado,
        acoes
    };

    const { usarRota } = useContext(contextoServico);

    const atualizarTags = () => {
        const requisicao: RequisicaoComumTagsListar = {
            Limite: 100,
            Pagina: 1,
            Termo: "",
            Tipo: "Todas"
        };
        usarRota(comumTagsListar, requisicao).then((resposta: RespostaComumTagsListar) => {
            definirEstado({ Tags: resposta.Dados.Tags });
        });
    }

    useEffect(() => {
        atualizarTags();
    }, [])

    return <>
        <contextoTags.Provider value={valores}>
            {children}
        </contextoTags.Provider>
    </>

}

export default contextoTags;