import { CircularProgress } from '@material-ui/core';
import { useContext, useState } from 'react';
import contextoConfiguracoes from '../../contextos/configuracoes';

function canAccessIFrame(iframe: any) {
    var html = null;
    try {
        // deal with older browsers
        var doc = iframe.contentDocument || iframe.contentWindow.document;
        html = doc.body.innerHTML;
    } catch (err) {
        // do nothing
    }
    return (html !== null);
}

export default function FullyFrame(props: { src?: string }) {
    const [isLoading, setIsLoading] = useState(true);
    const { estado: estadoConfiguracoes } = useContext(contextoConfiguracoes);

    const substituirVariaveis = (fonteOriginal?: string) => {
        return fonteOriginal ? fonteOriginal
            .replace("{token}", estadoConfiguracoes.tokenAutenticacao || "")
            .replace("{theme}", estadoConfiguracoes.usarTemaPetro ? "main_darkly" : "main") : ""
    }

    return (
        <>
            {
                isLoading && <div style={{ paddingTop: "30vh", textAlign: "center" }}><CircularProgress /></div>
            }
            <iframe

                style={{ width: "100%", height: "100%", border: "none", margin: "0", display: isLoading ? "none" : "block" }}

                onLoadStart={() => {
                    setIsLoading(true);
                    // //console.log("Loading")
                }}

                onLoad={(e) => {
                    setIsLoading(false);
                    // //console.log("Loaded");
                    // if (canAccessIFrame(e.currentTarget)) {
                    //     /* @ts-ignore */
                    //     e.currentTarget.style.minHeight = e.currentTarget.contentWindow.document.body.scrollHeight + 10 + "px"
                    // }
                    // else {
                    //     //console.log("NAO POD!");
                    // }

                    // // //console.log(e.target);
                }} className="iframe" src={substituirVariaveis(props.src)} />
        </>
    );
}