export const molduras = [{
    nome: "Notícia",
    url: "/molduras/noticia.png",
    border: false,
    tipo: "noticia"
},
{
    nome: "Avatar",
    url: "/molduras/avatar.png",
    border: true,
    tipo: "imagem"
},
{
    nome: "Feed",
    url: "/molduras/feed.png",
    border: false,
    tipo: "imagem"
},
{
    nome: "Story",
    url: "/molduras/story.png",
    border: false,
    tipo: "imagem"
}];
export default molduras;