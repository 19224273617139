import { Container, Card, CardContent, Divider, List, ListItem, TextField, Button } from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import { useContext } from "react";
import { contextoSeguranca } from "../../../contextos/seguranca";
import BarraAplicativoSecundaria from "../../../templates/BarraAplicativoSecundaria";

export const Etapa2 = () => {
    const { estado, acoes } = useContext(contextoSeguranca);
    return <>
        <BarraAplicativoSecundaria />
        <Container maxWidth="md">
            <br /><br />
            <Card>
                <CardContent style={{ textAlign: 'center' }}>
                    <LockOpen />
                    Confirmar Código
                    <br />
                </CardContent>
                <Divider />
                <List>
                    <ListItem>
                        <TextField value={estado.codigo} onChange={(e) => {
                            acoes.definirEstado({ ...estado, codigo: e.target.value });
                        }} fullWidth variant="outlined" label="Código de segurança" />
                    </ListItem>
                    {estado.tipoAlteracao === "AlterarEmail" && <ListItem>
                        <TextField value={estado.novoEmail} onChange={(e) => {
                            acoes.definirEstado({ ...estado, novoEmail: e.target.value });
                        }} type="email" fullWidth variant="outlined" label="Novo e-mail" />
                    </ListItem>}
                    {estado.tipoAlteracao === "AlterarSenha" && <>
                        <ListItem>
                            <TextField value={estado.novaSenha} onChange={(e) => {
                                acoes.definirEstado({ ...estado, novaSenha: e.target.value });
                            }} type="password" fullWidth variant="outlined" label="Nova senha" />
                        </ListItem>
                        <ListItem>
                            <TextField value={estado.confirmarNovaSenha} onChange={(e) => {
                                acoes.definirEstado({ ...estado, confirmarNovaSenha: e.target.value });
                            }} type="password" fullWidth variant="outlined" label="Confirmar nova senha" />
                        </ListItem>
                    </>
                    }
                    {estado.tipoAlteracao === "ExcluirConta" && <ListItem>
                        Ao continuar, você confirma a exclusão da sua Conta!
                    </ListItem>}
                    <ListItem button>
                        <Button variant="outlined" fullWidth onClick={() => {
                            acoes.alterar();
                        }}>Confirmar</Button>
                    </ListItem>
                </List>
            </Card>
        </Container>
    </>
}

export default Etapa2;