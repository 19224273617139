import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ComentariosDigitacao from './ComentariosDigitacao';
import { Reply } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  }),
);

export default function ResponderComentario({ comentario_ID, arroba }: { comentario_ID: number, arroba?: string }) {
  // const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const [aberto, definirAberto] = useState(false);

  return (
    <>
      <Button startIcon={<Reply />} onClick={()=>definirAberto(true)}>
        Responder
      </Button> 
      {aberto && <ComentariosDigitacao popover={true} comentario_ID={comentario_ID} arroba={arroba} definirAberto={definirAberto} />}
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

      </Popover> */}
    </>
  );
}