import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoEditorTagsAtualizar {
    ID: number;
    Popularidade?: number;
    Nome?: string;
    Mostrar?: boolean;
}

export interface RespostaEditorTagsAtualizar {
    Status: boolean;
    Mensagem: string;
}

export const editorTagsAtualizar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/tags/atualizar", dados);
}

export default editorTagsAtualizar
