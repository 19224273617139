import { AxiosInstance, AxiosResponse } from "axios";

export interface RespostaComumAutenticacaoSair {
  Status: boolean;
  Mensagem: string;
}

export const comumAutenticacaoSair = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
  return buscador.post("/comum/autenticacao/sair", dados);
}

export default comumAutenticacaoSair