import { Capacitor } from "@capacitor/core";
import { Avatar, Badge, Button, Divider, Fab, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, SwipeableDrawer, Typography } from "@material-ui/core";
import { ChevronRight, Ballot, ThumbsUpDown, InsertLink, ReportProblem, InfoOutlined, Close, AccountCircle, AddPhotoAlternate, Phone, Mail, Lock, Star } from "@material-ui/icons";
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { contextoAplicacao } from "../../contextos/aplicacao";
import contextoAutenticacao from "../../contextos/autenticacao";
import baseURL from "../../funcoes/baseURL";

export const MenuLateral = () => {
    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
    const { estado: estadoAutenticacao, acoes: acoesAutenticacao } = useContext(contextoAutenticacao);
    const history = useHistory();

    const platform = Capacitor.getPlatform();

    const itens = [
        {
            Nome: "Atividade",
            Icone: <ThumbsUpDown />,
            Acao: () => {
                if (acoesAutenticacao.navegar('/perfil/atividade', true)) {
                    acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })
                }
            },
            Mostrar: true
        },
        {
            Nome: "Postagens",
            Icone: <Ballot />,
            Acao: () => {
                if (acoesAutenticacao.navegar('/perfil/postagens', true)) {
                    acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })
                }
            },
            Mostrar: true
        },
        {
            Nome: "Tags",
            Icone: <InsertLink />,
            Acao: () => {
                if (acoesAutenticacao.navegar('/perfil/tags', true)) {
                    acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })
                }
            },
            Mostrar: true
        },
        {
            Nome: "Editar Perfil",
            Icone: <AccountCircle />,
            Acao: () => {
                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: true });
            },
            Mostrar: true
        },
        {
            Nome: "Privacidade",
            Icone: <InfoOutlined />,
            Acao: () => {
                history.push("/privacidade")
                acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })
            },
            Mostrar: true
        },
        {
            Nome: "Segurança",
            Icone: <Lock />,
            Acao: () => {
                history.push("/seguranca")
                acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })
            },
            Mostrar: true
        },
        {
            Nome: "Assinatura",
            Icone: <Star />,
            Acao: () => {
                history.push('/pagamento');
                acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false });
            },
            Mostrar: platform !== "ios"
        },
        {
            Nome: "Selos",
            Icone: <AddPhotoAlternate />,
            Acao: () => {
                history.push('/selo');
                acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false });
            },
            Mostrar: [1, 3, 4].includes(estadoAutenticacao.tipo_usuario_ID || -1)
        },
        {
            Nome: "Reportar Problema",
            Icone: <ReportProblem />,
            Acao: () => {
                window.open('mailto:adm@noticiaverificada.com.br');
            },
            Mostrar: true
        }];
    return <SwipeableDrawer
        anchor="right"
        open={estadoAplicacao.menuAberto}
        onClose={() => acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })}
        onOpen={() => acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: true })}
    >
        <div style={{
            width: '330px',
            maxWidth: '100%',
            height: '100px',
            position: "absolute",
            backgroundImage: `url(${baseURL('imagem')}/bg.jpg)`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            textAlign: 'right'
        }} >
            <Button style={{ textAlign: 'right', color: '#333', marginTop: "24px" }} size="large" endIcon={<Close />} onClick={() => acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false })} />
        </div>
        <div style={{ width: '100%', marginTop: '30px' }}>
            <div style={{ padding: '20px' }}>

                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <Fab size="small" onClick={() => {
                            acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: true })
                        }}>
                            <AddPhotoAlternate />
                        </Fab>
                    }
                    style={{ marginLeft: 'calc(50% - 50px)', marginBottom: '10px' }}
                >
                    <Avatar style={{ width: '100px', height: '100px' }} src={estadoAutenticacao.Foto || ""} />
                </Badge>
                <Typography variant="subtitle1" align="center">

                    Olá, {estadoAutenticacao.Nome || "Visitante"}!
                    {estadoAutenticacao.Usuario ? <>
                        <br />@{estadoAutenticacao.Usuario}
                        <br />
                        {[1, 4].includes(estadoAutenticacao.tipo_usuario_ID || 0) && <>
                            <Button variant="outlined" onClick={() => {
                                history.push("/gerenciamento/noticias");
                                acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: false });
                            }}>Administração</Button> &nbsp;
                        </>}
                        <Button variant="outlined" onClick={() => {
                            acoesAutenticacao.sair();
                        }}>Desconectar</Button>
                    </> : <>
                        <br />
                        <Button variant="outlined" onClick={() => {
                            acoesAplicacao.definirEstado({ ...estadoAplicacao, tipoLogin: "Entrar", loginAberto: true, menuAberto: false });
                        }}>Entrar</Button> &nbsp;
                        <Button variant="outlined" onClick={() => {
                            acoesAplicacao.definirEstado({ ...estadoAplicacao, tipoLogin: "Cadastrar", loginAberto: true, menuAberto: false });
                        }}>Criar uma Conta</Button>
                    </>}
                    <br /><br />
                    <Divider />
                    <List>
                        {itens.map(item => {
                            if (!item.Mostrar) {
                                return <React.Fragment key={item.Nome} />
                            }
                            return <React.Fragment key={item.Nome}>
                                <ListItem onClick={item.Acao} button>
                                    <ListItemIcon>
                                        {item.Icone}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {item.Nome}
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <ChevronRight />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {/* <Divider variant="inset" component="li" /> */}
                            </React.Fragment>
                        })}
                    </List>
                    <Divider />
                    <List>
                        <ListSubheader>Informações do Editor</ListSubheader>
                        <ListItem button onClick={() => {
                            window.open('tel:+5567992046545');
                        }}>
                            <ListItemIcon>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText>
                                +55 67 99204-6545
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <ChevronRight />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button onClick={() => {
                            window.open('mailto:adm@noticiaverificada.com.br');
                        }}>
                            <ListItemIcon>
                                <Mail />
                            </ListItemIcon>
                            <ListItemText>
                                <span style={{ fontSize: "13px" }}>adm@noticiaverificada.com</span>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <ChevronRight />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Typography>
            </div>
        </div>
    </SwipeableDrawer>;
}

export default MenuLateral