import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import confiabilidadeTexto from "../funcoes/confiabilidadeTexto";
import logicaLikes from "../funcoes/logicaLikes";
import ComumNoticiasListar, { RequisicaoComumNoticiasListar, RespostaComumNoticiasListar } from "../servicos/comumNoticiasListar";
import editorNoticiasAprovar, { RequisicaoEditorNoticiasAprovar, RespostaEditorNoticiasAprovar } from "../servicos/editorNoticiasAprovar";
import editorNoticiasExcluir, { RequisicaoEditorNoticiasExcluir, RespostaEditorNoticiasExcluir } from "../servicos/editorNoticiasExcluir";
import leitorComentariosReacaoAtualizar, { RequisicaoLeitorComentariosReacaoAtualizar, RespostaLeitorComentariosReacaoAtualizar } from "../servicos/leitorComentariosReacaoAtualizar";
import leitorNoticiasReacaoAtualizar, { RequisicaoLeitorNoticiasReacaoAtualizar, RespostaLeitorNoticiasReacaoAtualizar } from "../servicos/leitorNoticiasReacaoAtualizar";
import { contextoServico } from "./servicos";

interface Estado extends RequisicaoComumNoticiasListar {
    Noticias: RespostaComumNoticiasListar['Dados'],
    maisDisponivel?: boolean,
    carregando?: boolean,
}

export interface EstadoPersonalizado extends Omit<Estado, 'Noticias'> {
    Noticias?: Estado['Noticias']
}

interface ContextoNoticias {
    estado: Estado,
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        carregar: () => Promise<void>;
        mostrarMais: () => Promise<void>;
        trocarOrdem: (Ordem: "Recentes" | "Popularidade") => Promise<void>;
        reacao: (reacao_tipo_ID: number, noticia_ID: number) => Promise<boolean>;
        reacaoComentario: (noticia_ID: number, comentario_ID: number) => Promise<boolean>;
        aprovarNoticia: (requisicao: RequisicaoEditorNoticiasAprovar) => Promise<boolean>;
        excluirNoticia: (requisicao: RequisicaoEditorNoticiasExcluir) => Promise<boolean>;
    }
}
export const contextoNoticias = createContext({} as ContextoNoticias);

export const ProvedorNoticias = ({ children, estadoPersonalizado }: { children: ReactNode, estadoPersonalizado?: EstadoPersonalizado }) => {

    const { usarRota } = useContext(contextoServico);

    const estadoInicial: Estado = {
        Limite: 10,
        Pagina: 1,
        Ordem: 'Popularidade',
        Seguindo: false,
        Pendente: false,
        TagsIDs: [],
        Termo: "",
        Noticias: [],
        tipo: "Tudo",
        maisDisponivel: true,
        carregando: true,
        ...estadoPersonalizado
    }

    const [estado, definirEstado] = useState(estadoInicial);

    const acoes = {
        definirEstado,
        carregar: async () => {
            definirEstado({ ...estado, carregando: true });
            const resposta: RespostaComumNoticiasListar = await usarRota(ComumNoticiasListar, {
                Limite: estado.Limite,
                Pagina: estado.Pagina,
                Ordem: estado.Ordem,
                Seguindo: estado.Seguindo,
                Pendente: estado.Pendente,
                TagsIDs: estado.TagsIDs,
                Termo: estado.Termo,
                tipo: estado.tipo,
                usuario_ID: estado.usuario_ID
            });
            definirEstado({
                ...estado,
                Noticias: [...estado.Noticias, ...resposta.Dados],
                maisDisponivel: resposta.Dados.length === estado.Limite,
                carregando: false
            });
        },
        mostrarMais: async () => {
            definirEstado({ ...estado, Pagina: Number(estado.Pagina) + 1 });
        },
        trocarOrdem: async (Ordem: "Recentes" | "Popularidade") => {
            definirEstado({ ...estado, Noticias: [], Pagina: 1, Ordem });
        },
        reacao: async (reacao_tipo_ID: number, noticia_ID: number) => {

            const noticia = estado.Noticias.find(noticia => noticia.ID === noticia_ID);

            if (!noticia) {
                return false
            }

            const {
                tipo_reacao_ID,
                Likes,
                Deslikes
            } = logicaLikes({
                reacao_tipo_ID,
                antiga_reacao_tipo_ID: noticia.tipo_reacao_ID,
                Likes: noticia.Likes,
                Deslikes: noticia.Deslikes
            });

            const requisicao: RequisicaoLeitorNoticiasReacaoAtualizar = {
                noticia_ID,
                reacao_tipo_ID: tipo_reacao_ID
            };
            const resposta: RespostaLeitorNoticiasReacaoAtualizar = await usarRota(leitorNoticiasReacaoAtualizar, requisicao);

            if (resposta.Status && noticia) {

                definirEstado({
                    ...estado,
                    Noticias: estado.Noticias.map(
                        noticia => noticia.ID === noticia_ID
                            ? {
                                ...noticia,
                                tipo_reacao_ID,
                                Likes,
                                Deslikes
                            }
                            : noticia
                    )
                });
            }
        },
        reacaoComentario: async (noticia_ID: number, comentario_ID: number) => {
            const noticia = estado.Noticias.find(noticia => noticia.ID === noticia_ID);
            let antigo_tipo_reacao_ID = noticia?.tipo_reacao_ID || 1;
            let tipo_reacao_ID = antigo_tipo_reacao_ID === 2 ? 1 : 2;
            const requisicao: RequisicaoLeitorComentariosReacaoAtualizar = { comentario_ID, reacao_tipo_ID: tipo_reacao_ID };
            const resposta: RespostaLeitorComentariosReacaoAtualizar = await usarRota(leitorComentariosReacaoAtualizar, requisicao);
            if (resposta.Status) {
                definirEstado({
                    ...estado,
                    Noticias: estado.Noticias.map(
                        noticia => noticia.ID === noticia_ID
                            ? { ...noticia, tipo_reacao_ID }
                            : noticia
                    )
                });
                return true;
            }
            return false;
        },
        aprovarNoticia: async (requisicao: RequisicaoEditorNoticiasAprovar) => {
            const resposta: RespostaEditorNoticiasAprovar = await usarRota(editorNoticiasAprovar, requisicao);
            if (resposta.Status) {
                definirEstado({
                    ...estado,
                    Noticias: estado.Noticias.map(noticia => noticia.ID === requisicao.noticia_ID
                        ? {
                            ...noticia,
                            noticia_confiabilidade_ID: requisicao.noticia_confiabilidade_ID,
                            noticia_confiabilidade_Nome: confiabilidadeTexto(requisicao.noticia_confiabilidade_ID),
                            Resenha: requisicao.Resenha
                        } : noticia)
                });
                return true;
            }
            return false;
        },
        excluirNoticia: async (requisicao: RequisicaoEditorNoticiasExcluir) => {
            const resposta: RespostaEditorNoticiasExcluir = await usarRota(editorNoticiasExcluir, requisicao);
            if (resposta.Status) {
                definirEstado({ ...estado, Noticias: estado.Noticias.filter(noticia => noticia.ID !== requisicao.noticia_ID) });
                return true;
            }
            return false;
        },
    }

    useEffect(() => {
        acoes.carregar();
    }, [estado.Ordem, estado.Pagina]);

    const valores = {
        estado,
        acoes
    };

    return <contextoNoticias.Provider value={valores as ContextoNoticias}>
        {children}
    </contextoNoticias.Provider>
}