import { createTheme, ThemeProvider } from "@material-ui/core";
import { useContext } from "react";
import contextoConfiguracoes from "../../contextos/configuracoes";

export const CriarTema = ({ children }: { children: React.ReactNode }) => {
    const { estado } = useContext(contextoConfiguracoes);
    const usarTemaPetro = estado.usarTemaPetro;
    const tema = createTheme({
        palette: {
            type: usarTemaPetro ? 'dark' : 'light',
            primary: !usarTemaPetro ? {
                dark: '#002884',
                light: '#757ce8',
                main: '#234D80',
                contrastText: '#fff',
            } : {
                dark: '#111111',
                light: '#191919',
                main: '#474747',
            },
            secondary: {
                light: '#ff7961',
                main: '#212121',
                dark: '#ba000d',
                contrastText: '#000',
            }
        },
    });
    return <ThemeProvider theme={tema}>{children}</ThemeProvider>
}

export default CriarTema