import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumAnuncioVer {
    anuncio_posicao_ID: number,
    anuncio_IDs: number[]
}

export interface RespostaComumAnuncioVer {
    Status: boolean,
    Dados?: {
        ID: number,
        anuncio_tipo_ID: number,
        Inicio: string,
        Fim: string,
        Imagem?: string,
        Link?: string,
        Script?: string,
        Descricao?: string,
        Visualizações: number,
        Mostrar: boolean
    },
    Mensagem: string
}

export const comumAnuncioVer = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/anuncio/ver", dados);
}

export default comumAnuncioVer