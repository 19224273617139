import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from "@capacitor/app";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OneSignalCordova from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';
import { StatusBar } from '@capacitor/status-bar';
import { AppTrackingTransparency } from "capacitor-ios-app-tracking";

function OneSignalCordovaInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);
    OneSignalCordova.setAppId("a09780b3-46b1-4f2d-96ea-da33e325540f");
    OneSignalCordova.setNotificationOpenedHandler(function (jsonData) {
        //console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignalCordova.promptForPushNotificationsWithUserResponse(function (accepted) {
        //console.log("Notificaçoes foram aceitas? Resposta: " + accepted);
    });
}

async function CapacitorStatusBarInit() {
    await StatusBar.show();
}

function HistoriadorNativo({ children }: { children: JSX.Element }) {
    const history = useHistory();
    useEffect(() => {
        history.push("/");
        CapacitorApp.addListener("backButton", () => {
            history.go(-1);
        });
        CapacitorApp.addListener("appUrlOpen", (event) => {
            const slug = event.url.split('.com').pop();
            if (slug && slug[0] === "/") {
                history.push(slug);
            }
        });
    });
    return <>{children}</>
}

const createIosNode = () => {
    const style = document.createElement('style');
    style.textContent = `
        .MuiAppBar-root, body {
          padding-top: 30pt;
        }
      `;
    document.head.appendChild(style);
}

export function ControladorNativo({ children }: { children: JSX.Element }) {
    const nativo = Capacitor.isNativePlatform();
    const ios = Capacitor.getPlatform() === 'ios';
    useEffect(() => {
        if (nativo) {
            OneSignalCordovaInit();
            CapacitorStatusBarInit();
            if (ios) {
                createIosNode();
                AppTrackingTransparency.requestPermission();
                AppTrackingTransparency.getTrackingStatus().then((status) => {
                    //console.log(status);
                });
            }
        }
        else {
            OneSignalWeb.init({
                appId: "a09780b3-46b1-4f2d-96ea-da33e325540f",
            });
        }
    }, []);

    if (nativo) {
        /* @ts-ignore */
        window.OriginalURL = document.location.toString().split("/www")[0] + "/www";
        return <HistoriadorNativo>{children}</HistoriadorNativo>
    }
    return children;
}