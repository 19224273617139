import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumAutenticacaoSegurancaAlterar {
    tipoAlteracao: "AlterarSenha" | "AlterarEmail" | "ExcluirConta",
    token: string,
    novaSenha?: string,
    novoEmail?: string,
}

export interface RespostaComumAutenticacaoSegurancaAlterar {
    Status: boolean;
    Mensagem: string;
}

export const comumAutenticacaoSegurancaAlterar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/autenticacao/seguranca/alterar", dados);
}

export default comumAutenticacaoSegurancaAlterar