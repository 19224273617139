import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoEditorUsuariosAtualizar {
    usuario_ID: number,
    tipo_usuario_ID: number,
    Ativo: boolean,
    tags_IDs: number[]
}

export interface RespostaEditorUsuariosAtualizar {
    Status: boolean;
    Mensagem: string;
}

export const editorUsuariosAtualizar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/usuarios/atualizar", dados);
}

export default editorUsuariosAtualizar