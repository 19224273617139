import { Slide, useScrollTrigger } from "@material-ui/core";

export const EsconderNaRolagem = ({ direcao, children }: {
    direcao?: "up" | "down" | "left" | "right",
    children: React.ReactElement
}) => {
    const gatilho = useScrollTrigger();
    return (
        <Slide appear={false} direction={direcao || "down"} in={!gatilho}>
            {children}
        </Slide>
    );
}