import { Container, Typography } from "@material-ui/core";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";

export const Privacidade = ({ MostrarBarra = true }) => {
    return <>
        {MostrarBarra && <BarraAplicativoSecundaria />}
        <br />
        <Container maxWidth="md">
            <Typography variant="h4">
                Política de Privacidade
            </Typography>
            <br />
            <Typography variant="body1">
                A sua privacidade é importante para nós. É política do Notícia Verificada respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site Notícia Verificada, e outros sites que possuímos e operamos.
            </Typography>
            <br />
            <Typography variant="body1">
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
            </Typography>
            <br />
            <Typography variant="body1">
                Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
            </Typography>
            <br />
            <Typography variant="body1">
                Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
            </Typography>
            <br />
            <Typography variant="body1">
                O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
            </Typography>
            <br />
            <Typography variant="body1">
                Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
            </Typography>
            <br />
            <Typography variant="body1">
                O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
            </Typography>
            <br />
            <Typography variant="h5">
                Política de Privacidade
            </Typography>
            <br />
            <Typography variant="h6">
                O que são cookies?
            </Typography>
            <br />
            <Typography variant="body1">
                Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.
            </Typography>
            <br />
            <Typography variant="h6">
                Como usamos os cookies?
            </Typography>
            <br />
            <Typography variant="body1">
                Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que você usa.
            </Typography>
            <br />
            <Typography variant="h6">
                Desativar cookies
            </Typography>
            <br />
            <Typography variant="body1">
                Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.
            </Typography>
            <br />
            <Typography variant="h6">
                Cookies que definimos
            </Typography>
            <br />
            <Typography variant="body1">
                <ul>
                    <li>
                        <Typography variant="body1">
                            Cookies relacionados à conta
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Cookies relacionados ao login
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Cookies relacionados a boletins por e-mail
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Pedidos processando cookies relacionados
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Cookies relacionados a pesquisas
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Cookies relacionados a formulários
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.
                        </Typography>
                        <br />
                    </li>
                    <li>
                        <Typography variant="body1">
                            Cookies de preferências do site
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.
                        </Typography>
                        <br />
                    </li>
                </ul>
            </Typography>
            <br />
            <Typography variant="h6">
                Cookies de Terceiros
            </Typography>
            <br />
            <Typography variant="body1">
                Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.
            </Typography>
            <br />
            <Typography variant="body1">
                Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.
            </Typography>
            <br />
            <Typography variant="body1">
                Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.
            </Typography>
            <br />
            <Typography variant="body1">
                As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.
            </Typography>
            <br />
            <Typography variant="body1">
                Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.
            </Typography>
            <br />
            <Typography variant="body1">
                À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analisar nossos custos de publicidade e produtos para garantir o melhor preço possível.
            </Typography>
            <br />
            <Typography variant="body1">
                O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.
            </Typography>
            <br />
            <Typography variant="body1">
                Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.
            </Typography>
            <br />
            <Typography variant="body1">
                Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.
            </Typography>
            <br />
            <Typography variant="body1">
                Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.
            </Typography>
            <br />
            <Typography variant="h6">
                Compromisso do usuário
            </Typography>
            <br />
            <Typography variant="body1">
                O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Notícia Verificada oferece no site e com caráter enunciativo, mas não limitativo:
            </Typography>
            <br />
            <Typography variant="body1">
                <ul>
                    <li>
                        A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
                        <br /><br />
                    </li>
                    <li>
                        B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas online, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
                        <br /><br />
                    </li>
                    <li>
                        C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Notícia Verificada, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
                        <br /><br />
                    </li>
                </ul>
            </Typography>
            <br />
            <Typography variant="h6">
                Mais informações
            </Typography>
            <br />
            <Typography variant="body1">
                <ul>
                    <li>
                        Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
                        <br /><br />
                    </li>
                    <li>
                        Esta política é efetiva a partir de Setembro/2021.
                        <br /><br />
                    </li>
                </ul>
            </Typography>
            <br />
            <Typography variant="h6">
                Os tipos de dados pessoais que usamos
            </Typography>
            <br />
            <Typography variant="body1">
                <ul>
                    <li>
                        Coletamos e usamos as seguintes informações sobre você:
                        <br /><br />
                    </li>
                    <li>
                        Suas informações de perfil
                        <br /><br />
                        Você nos fornece informações quando se registra na Plataforma, incluindo seu nome de usuário, senha, data de nascimento, endereço de e-mail e/ou número de telefone, informações divulgadas em seu perfil de usuário e sua fotografia ou vídeo de perfil.
                        <br /><br />
                    </li>
                    <li>
                        Conteúdo do Usuário e Informações Comportamentais.
                        <br /><br />
                        Processamos o conteúdo que você gera na Plataforma. Nós coletamos o Conteúdo de Usuário através das curtidas Hashtags seguidas e conteúdos postados. Coletamos informações através de pesquisas, desafios e competições nas quais você participa. Também coletamos informações sobre o uso da Plataforma, por exemplo, como você se envolve com a Plataforma, incluindo como você interage com o conteúdo que mostramos a você, os anúncios que você vê e os problemas encontrados, o conteúdo que você gosta, o conteúdo você curte.
                        <br /><br />
                    </li>
                    <li>
                        Informações Técnicas que coletamos sobre você.
                        <br /><br />
                        Coletamos certas informações sobre o dispositivo que você usa para acessar a Plataforma, tais como seu endereço IP, agente de usuário, operadora móvel, configurações de fuso horário, identificadores para fins publicitários, modelo de seu dispositivo, o sistema do dispositivo, tipo de rede, IDs do dispositivo, sua resolução de tela e sistema operacional, nomes e tipos de aplicativos e arquivos, padrões ou ritmos de teclas, estado da bateria, configurações de áudio e dispositivos de áudio conectados. Quando você faz login a partir de vários dispositivos, poderemos usar suas informações de perfil para identificar sua atividade através dos dispositivos. Também poderemos associá-lo com informações coletadas de dispositivos diferentes daqueles que você usa para fazer login na Plataforma.
                        <br /><br />
                    </li>
                </ul>
            </Typography>
            <br />
            <Typography variant="h6">
                Como usamos seus dados pessoais
            </Typography>
            <Typography variant="body1">
                <ul>
                    <li>Usaremos as informações que coletamos sobre você das seguintes maneiras: <br /><br /></li>
                    <li>Notificá-lo sobre alterações em nosso serviço; <br /><br /></li>
                    <li>Fornecer suporte ao usuário; <br /><br /></li>
                    <li>Personalizar o conteúdo que você recebe e fornecer conteúdo personalizado que será do seu interesse; <br /><br /></li>
                    <li>Permitir que você compartilhe o Conteúdo do Usuário e interaja com outros usuários; <br /><br /></li>
                    <li>Comunicar com você; <br /><br /></li>
                    <li>Para nos ajudar a detectar e combater abusos, atividades nocivas, fraudes, spam e atividades ilegais na Plataforma; <br /><br /></li>
                    <li>Garantir sua segurança e proteção, incluindo a revisão do conteúdo do usuário, mensagens e metadados associados à violação de nossas Diretrizes da Comunidade e de outro conteúdo inadequado; <br /><br /></li>
                    <li>Garantir que o conteúdo seja apresentado da maneira mais eficaz para você e seu dispositivo; <br /><br /></li>
                    <li>Melhorar, promover e desenvolver a plataforma e promover tópicos populares, hashtags e campanhas na plataforma; <br /><br /></li>
                    <li>Realizar análise de dados e testar a plataforma para garantir sua estabilidade e segurança; <br /><br /></li>
                    <li>Permitir que você participe de recursos interativos da plataforma; <br /><br /></li>
                    <li>Fornecer publicidade personalizada a você; <br /><br /></li>
                    <li>Fornecer serviços baseados em localização (quando estes serviços estiverem disponíveis em sua jurisdição); <br /><br /></li>
                    <li>Para informar nossos algoritmos  <br /><br /></li>
                    <li>Fazer cumprir nossos termos, condições e políticas;  <br /><br /></li>
                    <li>Administrar a plataforma, incluindo solução de problemas; e <br /><br /></li>
                    <li>Para facilitar a venda, promoção e compra de bens e serviços e para fornecer suporte ao usuário <br /><br /></li>
                </ul>
            </Typography>

            <Typography variant="h6">
                A segurança dos seus dados pessoais
            </Typography>

            <Typography variant="body1">
                <ul>
                    <li>Tomamos medidas para garantir que suas informações sejam tratadas com segurança e de acordo com esta política. Infelizmente, a transmissão de informações pela Internet não é completamente segura. Embora protejamos seus dados pessoais, por exemplo, por criptografia, não podemos garantir a segurança de suas informações transmitidas através da Plataforma; qualquer transmissão é por sua conta e risco. <br /><br /></li>
                    <li>Temos medidas técnicas e organizacionais apropriadas para garantir um nível de segurança adequado ao risco de probabilidade e severidade variáveis ​​dos direitos e liberdades de você e de outros usuários. Mantemos essas medidas técnicas e organizacionais e as alteramos de tempos em tempos para melhorar a segurança geral de nossos sistemas. <br /><br /></li>
                    <li>Ocasionalmente, incluiremos links de e para os sites de nossas redes de parceiros, anunciantes e afiliados. Se você seguir um link para qualquer um desses sites, observe que esses sites têm suas próprias políticas de privacidade e que não aceitamos qualquer responsabilidade por essas políticas. Verifique essas políticas antes de enviar qualquer informação para esses sites. <br /><br /></li>
                </ul>
            </Typography>




        </Container>
    </>
}

export default Privacidade;