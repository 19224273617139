import { Box, Button, ButtonBase, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, InputAdornment, Link, TextField, Typography } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank, Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { useState, useContext } from "react";
import { contextoAplicacao } from "../../contextos/aplicacao";
import contextoAutenticacao from "../../contextos/autenticacao";
import { contextoServico } from "../../contextos/servicos";
import Privacidade from "../../paginas/Privacidade";

export const AutenticacaoCadastrar = () => {

    const [nome, definirNome] = useState("");
    const [email, definirEmail] = useState("");
    const [usuario, definirUsuario] = useState("");
    const [senha, definirSenha] = useState("");

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
    const { acoes } = useContext(contextoAutenticacao);

    const [concordo, definirConcordo] = useState(false);

    const { despachoMensagens } = useContext(contextoServico);

    const acaoCadastrar = () => {

        if (!nome || !email || !usuario || !senha) {
            despachoMensagens({ acao: "NovaMensagem", dados: { tipo: "error", mensagem: "Preencha todos os dados corretamente!" } });
            return false;
        }

        if (!concordo) {
            despachoMensagens({ acao: "NovaMensagem", dados: { tipo: "error", mensagem: "Aceite os termos de uso!" } });
            return false;
        }

        acoes.cadastrar({
            Nome: nome,
            Email: email,
            Usuario: usuario,
            Senha: senha,
            Foto: ""
        });
    }

    const acaoPoliticaLink = () => {
        acoesAplicacao.definirEstado({ ...estadoAplicacao, politicaAberto: true });
    }

    const [mostrarSenha, definirMostrarSenha] = useState(false);

    return <>
        <DialogContent>

            <Typography variant="subtitle1">
                <b>Crie sua conta - Rápido e fácil</b>
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"

                        required
                        fullWidth
                        label="Nome"
                        value={nome}
                        onChange={e => definirNome(e.target.value)}
                        InputLabelProps={{ style: { color: "#858585" } }}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="E-mail"
                        type="email"
                        value={email}
                        onChange={e => definirEmail(e.target.value)}
                        InputLabelProps={{ style: { color: "#858585" } }}
                    />
                </Grid><Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="Usuário"
                        value={usuario}
                        onChange={e => definirUsuario(e.target.value)}
                        InputLabelProps={{ style: { color: "#858585" } }}
                    />
                </Grid><Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="Senha"
                        type={mostrarSenha ? "text" : "password"}
                        value={senha}
                        onChange={e => definirSenha(e.target.value)}
                        InputLabelProps={{ style: { color: "#858585" } }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <ButtonBase onClick={() => definirMostrarSenha(e => !e)}>
                                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                                    </ButtonBase>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            cursor: 'pointer'
                        }}>
                            <div onClick={() => definirConcordo(!concordo)}>
                                {concordo ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                &nbsp; <div style={{ width: '30px' }} />
                            </div>
                            <div onClick={() => definirConcordo(!concordo)}>
                                Li e concordo com a &nbsp;
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                                <Link color="inherit" underline="always" onClick={acaoPoliticaLink}>política de privacidade</Link>&nbsp;
                            </div>
                            <div onClick={() => definirConcordo(!concordo)} style={{ display: 'inline-flex' }}>
                                do Notícia Verificada.
                            </div>
                        </div>
                    </Typography>
                </Grid>
            </Grid>
            <div style={{ maxHeight: "25vh", overflowX: "hidden", overflowY: "auto" }}>
                <Privacidade MostrarBarra={false} />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { acoesAplicacao.definirEstado({ ...estadoAplicacao, tipoLogin: "Entrar" }) }}>
                Entrar
            </Button>
            <Button onClick={acaoCadastrar} variant="contained" color="primary">Cadastrar</Button>
        </DialogActions>
    </>
}

export default AutenticacaoCadastrar;