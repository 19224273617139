import { DialogContent, DialogTitle } from "@material-ui/core"
import Privacidade from "../../paginas/Privacidade"

export const PoliticaPrivacidade = () => {
    return <>
        <DialogTitle>Política de Privacidade</DialogTitle>
        <DialogContent>
            <Privacidade MostrarBarra={false}/>
        </DialogContent>
    </>
}

export default PoliticaPrivacidade