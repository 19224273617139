import { AppBar, Box, CardMedia, Paper, useTheme } from "@material-ui/core"
import { EsconderNaRolagem } from "../EsconderNaRolagem"

export default ({ Link, Imagem }: { Link: string, Imagem: string }) => {

    const theme = useTheme();
    return <EsconderNaRolagem direcao="up">
        <div style={{ position: "fixed", bottom: "0px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <a href={Link} target="_blank">
                <CardMedia component="img" style={{ width: "100%", maxWidth: "912px", boxShadow: "black -2px 3px 6px 0px" }} image={Imagem} />
            </a>
            <Paper elevation={0} style={{height: "20px"}}/>

            {/* {theme.palette.mode} */}
        </div>
    </EsconderNaRolagem>
}