import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const estiloIndex = makeStyles((_: Theme) => {
    return createStyles({
        fab: {
            position: 'fixed',
            bottom: "60px",
            right: "20px"
        },
        fab2: {
            position: 'fixed',
            bottom: "130px",
            right: "20px",
            padding: "10px"
        }
    })
});

export default estiloIndex;