import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorComentariosAdicionar {
    noticia_ID: number;
    comentario_ID?: any;
    Comentario: string;
}

interface Dados {
    ID: number
}

export interface RespostaLeitorComentariosAdicionar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const leitorComentariosAdicionar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/comentarios/adicionar", dados);
}

export default leitorComentariosAdicionar
