import { useContext } from "react";
import { contextoAnuncio, ProvedorAnuncio } from "../../contextos/anuncio";
import baseURL from "../../funcoes/baseURL";
import AnuncioFlutuante from "../AnuncioFlutuante";
import AnuncioMeio from "../AnuncioMeio";

const UsarAnuncio = () => {
    const { anuncio, anuncio_posicao_ID, carregando } = useContext(contextoAnuncio).estado;
    if (carregando || !anuncio) {
        return <></>
    }
    if (anuncio_posicao_ID === 1 || anuncio_posicao_ID === 3) {
        return <><br/><AnuncioMeio Link={anuncio.Link || ""} Imagem={baseURL("adminImagem") + anuncio.Imagem || ""} /></>
    }
    if (anuncio_posicao_ID === 2) {
        return <><br/><AnuncioFlutuante Link={anuncio.Link || ""} Imagem={baseURL("adminImagem") + anuncio.Imagem || ""} /></>
    }
    return <>NO-ADS-{anuncio_posicao_ID}</>
}

const Anuncio = ({ anuncio_posicao_ID }: { anuncio_posicao_ID: number }) => {
    return <ProvedorAnuncio anuncio_posicao_ID={anuncio_posicao_ID}>
        <UsarAnuncio />
    </ProvedorAnuncio>
}

export default Anuncio;