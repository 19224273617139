import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const estiloComentarios = makeStyles((tema: Theme) => {
    return createStyles({
        seloAnalise: {
            height: '40px',
            alignSelf: 'center'
        },
        seloIndeterminada: {
            height: '40px',
            alignSelf: 'center'
        },
        seloVerificada: {
            height: '60px',
            alignSelf: 'center',
            position: 'relative',
        },
        seloFalsa: {
            height: '40px',
            alignSelf: 'center'
        },
        texto: {
            fontSize: '15px',
            fontWeight: 300,
            marginRight: '10px',
            display: 'flex',
            flexGrow: 1
        }
    });
});