import { createContext, useContext, useEffect, useState } from "react";
import comumAnuncioVer, { RequisicaoComumAnuncioVer, RespostaComumAnuncioVer } from "../servicos/comumAnuncioVer";
import { contextoServico } from "./servicos";

interface Estado {
    anuncio?: RespostaComumAnuncioVer['Dados'],
    anuncio_posicao_ID: number,
    carregando: boolean,
};

interface ContextoAnuncio {
    estado: Estado
}

export const contextoAnuncio = createContext({} as ContextoAnuncio);

export const ProvedorAnuncio = ({ anuncio_posicao_ID, children }: { anuncio_posicao_ID: number, children: React.ReactNode }) => {

    const valorPadrao: Estado = {
        carregando: true,
        anuncio_posicao_ID
    };

    const [estado, definirEstado] = useState(valorPadrao);

    const { usarRota } = useContext(contextoServico);

    useEffect(() => {

        (async () => {

            type AnunciosExclusao = {
                anuncio_ID: number,
                dataHora: Date
            }[];

            const anunciosExclusao: AnunciosExclusao = JSON.parse(localStorage.getItem("anunciosExclusao") || "[]");

            /* Anúncio 3 é uma simulação do 2 */

            if (anuncio_posicao_ID === 3) {
                anuncio_posicao_ID = 2;
            }

            const requisicao: RequisicaoComumAnuncioVer = {
                anuncio_posicao_ID,
                anuncio_IDs: anunciosExclusao.map(e => e.anuncio_ID)
            };

            const resposta: RespostaComumAnuncioVer = await usarRota(comumAnuncioVer, requisicao);

            if (resposta.Status) {
                const dados: RespostaComumAnuncioVer['Dados'] = resposta.Dados;
                definirEstado({
                    ...estado,
                    anuncio: dados,
                    carregando: false
                });
                // if (dados && anuncio_posicao_ID === 1) {
                //     const anunciosExclusao: AnunciosExclusao = [...JSON.parse(localStorage.getItem("anunciosExclusao") || "[]"), {
                //         anuncio_ID: dados.ID,
                //         dataHora: new Date()
                //     }];
                //     localStorage.setItem("anunciosExclusao", JSON.stringify(anunciosExclusao));
                // }
            }
            else {
                definirEstado({
                    ...estado,
                    anuncio: undefined,
                    carregando: false
                });
            }
        })()
    }, [])

    const valores: ContextoAnuncio = {
        estado
    };

    return <contextoAnuncio.Provider value={valores}>
        {children}
    </contextoAnuncio.Provider>;
}