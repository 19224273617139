import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import verificarUrl from "../funcoes/verificarUrl";
import comumMetatagsListar, { RequisicaoComumMetatagsListar, RespostaComumMetatagsListar } from "../servicos/comumMetatagsListar";
import leitorNoticiasAdicionar, { RequisicaoLeitorNoticiasAdicionar, RespostaLeitorNoticiasAdicionar } from "../servicos/leitorNoticiasAdicionar";
import { contextoAplicacao } from "./aplicacao";
import { contextoServico } from "./servicos";

type Estado = {
    Link: RequisicaoComumMetatagsListar['Link'],
    Capa: RespostaComumMetatagsListar['Dados']['Metatags']['Capa'],
    Titulo: RespostaComumMetatagsListar['Dados']['Metatags']['Titulo'],
    Texto: RespostaComumMetatagsListar['Dados']['Metatags']['Texto'],
    Comentario: RequisicaoLeitorNoticiasAdicionar['Comentario'],
    Tags_IDs: RequisicaoLeitorNoticiasAdicionar['Tags_IDs'],
    Visualizacao: {
        Link: string,
        Capa: string,
        Titulo: string,
        Texto: string,
    }
}

interface ContextoPublicacao {
    estado: Estado;
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        visualizarLink: () => Promise<void>;
        publicarZap: () => Promise<void>;
        limpar: () => Promise<void>;
        publicar: () => Promise<void>;
    };
}

export const contextoPublicacao = createContext({} as ContextoPublicacao);

export const ProvedorPublicacao = ({ children }: { children: ReactNode }) => {

    const history = useHistory();

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);

    const valoresPadrao: Estado = {
        Capa: "",
        Comentario: "",
        Link: "",
        Tags_IDs: [],
        Texto: "",
        Titulo: "",
        Visualizacao: {
            Link: "noticiaverificada.com.br",
            Capa: "/imagens/perfil.png",
            Titulo: "Notícia Verificada",
            Texto: "Sua notícia comprovada!",
        }
    }
    const [estado, definirEstado] = useState(valoresPadrao);

    const { usarRota, despachoMensagens } = useContext(contextoServico);

    const acoes = {
        definirEstado,
        visualizarLink: async () => {
            if (estado.Link && verificarUrl(estado.Link)) {
                const requisicao: RequisicaoComumMetatagsListar = { Link: estado.Link };
                const resposta: RespostaComumMetatagsListar = await usarRota(comumMetatagsListar, requisicao);
                if (resposta.Status) {
                    const dados: Estado = {
                        ...estado,
                        Capa: resposta.Dados.Metatags.Capa,
                        Titulo: resposta.Dados.Metatags.Titulo,
                        Texto: resposta.Dados.Metatags.Texto,
                        Visualizacao: {
                            Capa: resposta.Dados.Metatags.Capa || estado.Capa,
                            Titulo: resposta.Dados.Metatags.Titulo || "Notícia sem título",
                            Texto: resposta.Dados.Metatags.Texto || "Notícia sem texto",
                            Link: (new URL(estado.Link)).hostname
                        }
                    };
                    definirEstado(dados);
                }
            }
        },
        publicarZap: async () => {
            const texto = estado.Texto;
            let titulo = texto.substring(0, 49);
            if(titulo.length >= 50) {
                titulo = "Boato: " + titulo.split(' ').slice(0, -1).join() + "...";
            }
            else {
                titulo = "Boato: " + titulo;
            }
            const requisicao: RequisicaoLeitorNoticiasAdicionar = {
                Link: "https://api.whatsapp.com/send/?text=" + encodeURI(texto),
                Capa: "https://noticiaverificada.com/imagens/whatsapp.png",
                Titulo: titulo,
                Texto: estado.Texto,
                Comentario: estado.Comentario?.length ? estado.Comentario : undefined,
                Tags_IDs: estado.Tags_IDs
            };
            const resposta: RespostaLeitorNoticiasAdicionar = await usarRota(leitorNoticiasAdicionar, requisicao);
            if (resposta.Status) {
                despachoMensagens({ acao: 'NovaMensagem', dados: { tipo: 'success', mensagem: resposta.Mensagem } });
                acoesAplicacao.definirEstado({ ...estadoAplicacao, adicionarAberto: false });
                history.push(`/noticia/${resposta.Dados.Slug}`);
            }
        },
        limpar: async () => {
            acoes.definirEstado({
                Capa: "",
                Comentario: "",
                Link: "",
                Tags_IDs: [],
                Texto: "",
                Titulo: "",
                Visualizacao: {
                    Link: "noticiaverificada.com.br",
                    Capa: "/imagens/perfil.png",
                    Titulo: "Notícia Verificada",
                    Texto: "Sua notícia comprovada!",
                }
            })
        },
        publicar: async () => {
            const requisicao: RequisicaoLeitorNoticiasAdicionar = {
                Link: estado.Link,
                Capa: estado.Visualizacao.Capa,
                Titulo: estado.Visualizacao.Titulo,
                Texto: estado.Visualizacao.Texto,
                Comentario: estado.Comentario?.length ? estado.Comentario : undefined,
                Tags_IDs: estado.Tags_IDs
            };
            const resposta: RespostaLeitorNoticiasAdicionar = await usarRota(leitorNoticiasAdicionar, requisicao);
            if (resposta.Status) {
                despachoMensagens({ acao: 'NovaMensagem', dados: { tipo: 'success', mensagem: resposta.Mensagem } });
                acoesAplicacao.definirEstado({ ...estadoAplicacao, adicionarAberto: false });
                history.push(`/noticia/${resposta.Dados.Slug}`);
            }
        }
    };

    const valores: ContextoPublicacao = {
        estado,
        acoes
    };

    return <contextoPublicacao.Provider value={valores}>
        {children}
    </contextoPublicacao.Provider>
}

export default contextoPublicacao;