import React, { createContext, useContext, useEffect, useState } from "react";
import editorUsuariosAtualizar, { RequisicaoEditorUsuariosAtualizar, RespostaEditorUsuariosAtualizar } from "../servicos/editorUsuariosAtualizar";
import editorUsuariosListar, { RespostaEditorUsuariosListar } from "../servicos/editorUsuariosListar";
import { contextoServico } from "./servicos";

const contextoUsuarios = createContext({} as ContextoUsuarios);

interface ContextoUsuarios {
    estado: Estado;
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        salvarUsuario: (usuario_ID: number) => Promise<boolean>;
    };
}

interface Estado {
    Usuarios: RespostaEditorUsuariosListar['Dados']
}

export const ProvedorUsuarios = ({ children }: { children: React.ReactNode }) => {

    const { usarRota, despachoMensagens } = useContext(contextoServico);

    const [estado, definirEstado] = useState({
        Usuarios: []
    } as Estado);

    const acoes = {
        definirEstado,
        carregarUsuarios: async () => {
            const resposta: RespostaEditorUsuariosListar = await usarRota(editorUsuariosListar);
            definirEstado({ ...estado, Usuarios: resposta.Dados });
        },
        salvarUsuario: async (usuario_ID: number) => {
            const usuario = estado.Usuarios.find(usuario => usuario.ID === usuario_ID);
            if (usuario) {
                const requisicao: RequisicaoEditorUsuariosAtualizar = {
                    usuario_ID: usuario.ID,
                    Ativo: true,
                    tags_IDs: usuario.Tags.map(tag => tag.tag_ID),
                    tipo_usuario_ID: usuario.tipo_usuario_ID,
                };
                const resposta: RespostaEditorUsuariosAtualizar = await usarRota(editorUsuariosAtualizar, requisicao);
                if (resposta.Status) {
                    despachoMensagens({ acao: "NovaMensagem", dados: { tipo: "success", mensagem: "Salvo" } });
                    return true;
                }
                despachoMensagens({ acao: "NovaMensagem", dados: { tipo: "error", mensagem: "Erro ao salvar" } });
                return false;
            }
            despachoMensagens({ acao: "NovaMensagem", dados: { tipo: "error", mensagem: "Erro ao salvar" } });
            return false;
        }
    };

    const valores = {
        estado,
        acoes
    };

    useEffect(() => {
        acoes.carregarUsuarios();
    }, [])

    return <contextoUsuarios.Provider value={valores}>
        {children}
    </contextoUsuarios.Provider>
}

export default contextoUsuarios;