import { Button, Card, CardContent, Container, Divider, List, ListItem, ListItemIcon, ListItemText, TextField } from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank, LockOpen } from "@material-ui/icons"
import { useContext } from "react"
import { contextoSeguranca } from "../../../contextos/seguranca";
import BarraAplicativoSecundaria from "../../../templates/BarraAplicativoSecundaria";

export const Etapa1 = () => {
    const { estado, acoes } = useContext(contextoSeguranca);
    return <>
        <BarraAplicativoSecundaria />
        <Container maxWidth="md">
            <br /><br />
            <Card>
                <CardContent style={{ textAlign: 'center' }}>
                    <LockOpen />
                    Segurança
                    <br />
                </CardContent>
                <Divider />
                <List>
                    <ListItem button onClick={() => {
                        acoes.definirEstado({ ...estado, tipoAlteracao: "AlterarSenha" });
                    }}>
                        <ListItemIcon>
                            {estado.tipoAlteracao === "AlterarSenha" ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        </ListItemIcon>
                        <ListItemText>Quero alterar minha senha</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => {
                        acoes.definirEstado({ ...estado, tipoAlteracao: "AlterarEmail" });
                    }}>
                        <ListItemIcon>
                            {estado.tipoAlteracao === "AlterarEmail" ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        </ListItemIcon>
                        <ListItemText>Quero alterar meu e-mail</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => {
                        acoes.definirEstado({ ...estado, tipoAlteracao: "ExcluirConta" });
                    }}>
                        <ListItemIcon>
                            {estado.tipoAlteracao === "ExcluirConta" ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        </ListItemIcon>
                        <ListItemText>Excluir minha conta</ListItemText>
                    </ListItem>
                    <ListItem>
                        <TextField value={estado.email} onChange={(e) => {
                            acoes.definirEstado({ ...estado, email: e.target.value });
                        }} type="email" fullWidth variant="outlined" label="Digite seu E-mail atual" />
                    </ListItem>
                    <ListItem button>
                        <Button variant="outlined" fullWidth onClick={() => {
                            acoes.solicitarAlteracao();
                        }}>Continuar</Button>
                    </ListItem>
                </List>
            </Card>
        </Container>
    </>
}

export default Etapa1