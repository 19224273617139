import { createContext, useEffect, useState } from "react";

interface Estado {
    usarTemaPetro: boolean,
    tokenAutenticacao?: string,
    cookiesAceitos: boolean
}

interface ContextoConfiguracoes {
    estado: Estado,
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>,
        trocarTema: () => void
    }
}

export const contextoConfiguracoes = createContext({} as ContextoConfiguracoes);

export const ProvedorConfiguracoes = ({ children }: { children: React.ReactNode }) => {

    const estadoPadrao: Estado = {
        usarTemaPetro: false,
        tokenAutenticacao: undefined,
        cookiesAceitos: false
    }

    const [estado, definirEstado] = useState(estadoPadrao);

    const acoes = {
        definirEstado,
        trocarTema: () => {
            definirEstado({ ...estado, usarTemaPetro: !(estado.usarTemaPetro) });
        }
    };

    const valores: ContextoConfiguracoes = {
        estado,
        acoes
    };

    const [prosiga, definirProsiga] = useState(false);

    useEffect(() => {
        const configuracoesString = localStorage.getItem("configuracoes");
        if (configuracoesString) {
            const configuracoes: Estado = JSON.parse(configuracoesString);
            definirEstado(configuracoes);
        }
        definirProsiga(true);
    }, []);

    useEffect(() => {
        localStorage.setItem("configuracoes", JSON.stringify(estado));

        //@ts-ignore
        document.querySelector("meta[name=theme-color]").content = estado.usarTemaPetro ? "#474747" : "#234D80";

    }, [{ ...estado }]);

    return <contextoConfiguracoes.Provider value={valores}>
        {prosiga && children}
    </contextoConfiguracoes.Provider>
};

export default contextoConfiguracoes;