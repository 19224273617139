import { Button, Card, CardContent, Slider, TextField, Typography } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react"
import contextoAutenticacao from "../../contextos/autenticacao"
import AvatarEditor from "react-avatar-editor";
import { RotateRight, ZoomIn } from "@material-ui/icons";
import contextoConfiguracoes from "../../contextos/configuracoes";

export const AutenticacaoPerfil = () => {

    const { estado, acoes } = useContext(contextoAutenticacao);
    const [arquivo, definirArquivo] = useState<File>();
    const [nome, definirNome] = useState(estado.Nome);
    const [usuario, definirUsuario] = useState(estado.Usuario);

    // Editor de foto
    const [ehPraAtualizarFoto, definirEhPraAtualizarFoto] = useState(false);
    const [zoom, definirZoom] = useState(1);
    const [rotacao, definirRotacao] = useState(0);
    const editorRef = useRef<AvatarEditor>(null);
    const [imageWidth, imageHeight] = [150, 150];

    useEffect(() => {
        (async () => {
            if (estado.Foto) {
                const imagem = await fetch(estado.Foto);
                const blob = await imagem.blob();
                const file = new File([blob], "foto.png", { type: "image/png" });
                definirArquivo(file);
            }
        })();
    }, [estado.Foto]);

    const pegarImagem = () => {
        return new Promise((resolve) => {
            const canvas = editorRef.current?.getImage();
            if (canvas) {
                const novoCanvas = document.createElement('canvas');
                novoCanvas.width = imageWidth;
                novoCanvas.height = imageHeight;
                const novoContexto = novoCanvas.getContext('2d');
                novoContexto && novoContexto.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, imageWidth, imageHeight);
                arquivo ? novoCanvas.toBlob((blob) => {
                    blob ? resolve(new File([blob], "foto.jpg", {
                        type: "image/jpeg",
                    })) : resolve(undefined);
                }) : resolve(undefined);
            }
            else {
                resolve(undefined);
            }
        }) as Promise<File | undefined>;
    }

    const aoSalvarPerfil = async () => {
        const fotoPerfil = ehPraAtualizarFoto ? await pegarImagem() : undefined;
        acoes.alterarPerfil({
            Nome: nome,
            Usuario: usuario,
            FotoPerfil: fotoPerfil
        });
    };

    const usarTemaPreto = useContext(contextoConfiguracoes).estado.usarTemaPetro;

    const aoAlterarImagem = () => {
        !ehPraAtualizarFoto && definirEhPraAtualizarFoto(true);
    }

    return <>
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="body2">
                Alterar Perfil
            </Typography>
            <br />
            <TextField label="Nome" variant="outlined" fullWidth value={nome} onChange={(e) => {
                definirNome(e.target.value);
            }} />
            <br /><br />
            <TextField label="Usuário (@)" variant="outlined" fullWidth value={usuario} onChange={(e) => {
                definirUsuario(e.target.value);
            }} />
            <br /><br />

            {arquivo && <>
                <Card>
                    <CardContent>
                        <AvatarEditor
                            ref={editorRef}
                            onImageChange={aoAlterarImagem}
                            image={arquivo}
                            width={150}
                            height={150}
                            border={1}
                            color={usarTemaPreto ? [66, 66, 66, 1] : [255, 255, 255, 1]} // RGBA
                            scale={zoom}
                            rotate={rotacao}
                            borderRadius={1000}

                        />
                        <br />
                        <ZoomIn />
                        <Slider
                            style={{
                                color: "#234D80",
                                width: "calc(100% - 2em)",
                            }}
                            value={zoom}
                            onChange={(e, value) => definirZoom(Number(value))}
                            min={1}
                            max={3}
                            step={0.1}
                        />
                        <br />
                        <RotateRight />
                        <Slider
                            style={{
                                color: "#234D80",
                                width: "calc(100% - 2em)",
                            }}
                            value={rotacao}
                            onChange={(e, value) => definirRotacao(Number(value))}
                            min={-180}
                            max={180}
                            step={3}
                        />
                    </CardContent>
                </Card>
            </>}

            {/* <TextField multiline minRows={2} label="BIO" variant="outlined" fullWidth value={bio} onChange={(e) => {
                definirBio(e.target.value);
            }} />
            <br /><br /> */}
            <Button
                fullWidth
                variant="contained"
                component="label"
            >
                Enviar Foto
                <input style={{ display: 'none' }} type="file" onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {

                        definirArquivo(e.target.files[0]);
                        definirEhPraAtualizarFoto(true);

                    }
                }} />
            </Button>
            <br /><br />
            <Button fullWidth variant="contained" onClick={aoSalvarPerfil}>
                Salvar
            </Button>
            <br />
        </div>
    </>
}

export default AutenticacaoPerfil