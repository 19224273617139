import { CssBaseline, AppBar, Container, IconButton, CardActionArea, useMediaQuery } from "@material-ui/core";
import { ChevronLeft, Person } from "@material-ui/icons";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { contextoAplicacao } from "../../contextos/aplicacao";
import contextoConfiguracoes from "../../contextos/configuracoes";
import baseURL from "../../funcoes/baseURL";
import se from "../../funcoes/se";
import { EsconderNaRolagem } from "../EsconderNaRolagem";
import { estiloIndex } from "./estilo";

export const BarraAplicativoSecundaria = ({ children, fullWidth, backToHome }: { children?: React.ReactNode, fullWidth?: boolean, backToHome?: boolean }) => {
    const usarTemaPreto = useContext(contextoConfiguracoes).estado.usarTemaPetro;
    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
    const history = useHistory();

    const estilo = estiloIndex();

    const acaoPerfil = () => {
        acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: true });
    };

    const acaoLogo = () => {
        history.push("/");
    };

    const desktop = useMediaQuery('(min-width: 992px)');
    const padding = fullWidth ? (desktop ? "0px 30px" : "0px 15px") : "";
    const maxWidth = fullWidth ? false : "md";

    const backClick = () => {
        //console.log(estadoAplicacao.tamanhoHistorico);
        if(backToHome || estadoAplicacao.tamanhoHistorico === 0) {
            history.push("/");
            return;
        }
        history.goBack();
    }

    return <>
        <CssBaseline />
        <EsconderNaRolagem>
            <AppBar>
                <Container style={{ padding }} maxWidth={maxWidth}>
                    <div style={{ display: "flex", width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div onClick={() => backClick()} style={{ height: '40px', alignItems: 'center', display: 'flex' }}>
                            <IconButton color="primary" className={estilo.buttonHeader}>
                                <ChevronLeft />
                            </IconButton>
                        </div>
                        <div style={{ height: '40px', alignItems: 'center', display: 'flex' }}>
                            {children ? children : <>
                                <CardActionArea onClick={acaoLogo}>
                                    <img src={`${baseURL("imagem")}/logotipo.svg`} style={{ height: '33px', maxHeight: "36px", maxWidth: "calc(100% - 15px)", flexGrow: 1, filter: se(usarTemaPreto, 'grayscale(1)') }} />
                                </CardActionArea>
                            </>}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <IconButton color="primary" className={estilo.buttonHeader} onClick={acaoPerfil}>
                                <Person />
                            </IconButton>
                        </div>
                    </div>
                </Container>
            </AppBar>
        </EsconderNaRolagem>
        <div style={{ height: '40px' }}></div>
    </>
};

export default BarraAplicativoSecundaria;