import { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Card, CardContent } from '@material-ui/core';
import contextoConfiguracoes from '../../contextos/configuracoes';
import contextoAutenticacao from '../../contextos/autenticacao';
import { contextoAplicacao } from '../../contextos/aplicacao';
import { useHistory } from 'react-router-dom';

function DireitosAutorais() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Direitos autorais © '}
            <Link color="inherit" href="#">
                Noticia Verificada
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgotPassword: {
        margin: theme.spacing(1.5, 0, 0),
    }
}));


export default function AutenticacaoEntrar() {
    const classes = useStyles();
    const usarTemaPreto = useContext(contextoConfiguracoes).estado.usarTemaPetro;

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);

    const { acoes } = useContext(contextoAutenticacao);

    const [email, definirEmail] = useState("");
    const [senha, definirSenha] = useState("");
    const acaoEntrar = () => {
        acoes.entrar(email, senha);
    };
    const acaoCriarConta = () => {
        acoesAplicacao.definirEstado({ ...estadoAplicacao, tipoLogin: "Cadastrar" });
    }
    const history = useHistory();
    return <>

        <div style={{
            width: '100%',
            height: '350px',
            background: !usarTemaPreto ? 'linear-gradient( 322deg , #1d4c85, #113561)' : 'linear-gradient( 181deg , rgb(25 25 25), rgb(9 9 9))'
        }}></div>

        <Container component="main" maxWidth="xs" style={{ marginTop: '-350px' }}>
            <CssBaseline />
            <div className={classes.paper}>
                {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar> */}
                <img src="/imagens/logotipo.svg" style={{ maxHeight: '40px', filter: usarTemaPreto ? 'grayscale(1)' : '' }} />

                <br />
                <Card>

                    <CardContent>
                        <Typography component="h1" variant="h5" style={{ textAlign: 'center' }}>
                            Entre Agora
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="E-mail ou Usuário"
                                type="text"
                                value={email}
                                onChange={e => definirEmail(e.target.value)}
                                InputLabelProps={{ style: { color: "#858585" } }}
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Senha"
                                type="password"
                                value={senha}
                                onChange={e => definirSenha(e.target.value)}
                                InputLabelProps={{ style: { color: "#858585" } }}
                            />
                            <Button
                                onClick={acaoEntrar}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Entrar
                            </Button>
                            <Button
                                onClick={acaoCriarConta}
                                fullWidth
                                variant="contained"
                            >Criar Conta</Button>
                            <Button className={classes.forgotPassword} fullWidth onClick={()=>{
                                history.push("/seguranca");
                                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: false });
                            }}>Esqueci minha senha</Button>
                            {/* <Button

                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                <img src="/imagens/iconeGoogle.svg" style={{ height: '15px', marginRight: '10px', marginTop: '-2px' }} /> Entrar com Google
                            </Button> */}
                            {/* <Grid container style={{ marginTop: '25px' }}>
                                <Grid item xs>
                                    <Link style={{ color: usarTemaPreto ? 'white' : 'black' }} href="#" variant="body2">
                                        Esqueci minha Senha
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link style={{ color: usarTemaPreto ? 'white' : 'black' }} href="#" variant="body2">
                                        {"Criar conta"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                        </form>
                    </CardContent>
                </Card>
            </div>
            <Box mt={8}>
                <DireitosAutorais />
            </Box>
        </Container>
    </>;
}