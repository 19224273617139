import { AxiosInstance, AxiosResponse } from "axios";

interface UsuarioPremium {
    ID: number,
    Nome: string,
    Usuario: string,
    Foto: string
}

export interface RespostaComumUsuariosPremiumListar {
    Status: boolean;
    Dados: UsuarioPremium[];
    Mensagem: string;
}

export const comumUsuariosPremiumListar = async (buscador: AxiosInstance): Promise<AxiosResponse> => {
    return buscador.post("/comum/usuarios/premium/listar");
}

export default comumUsuariosPremiumListar;