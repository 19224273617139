import { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Browser } from '@capacitor/browser';
import { Capacitor } from "@capacitor/core";
import { contextoServico } from "../../../contextos/servicos";
import { leitorPagamentoCriar, RequisicaoLeitorPagamentoCriar, RespostaLeitorPagamentoCriar } from "../../../servicos/leitorPagamentoCriar";
import { TermosUso } from "../termosUso";

const DialogoPagamento = ({ aberto, planoID, definirAberto }: { aberto: boolean, planoID: number, definirAberto: any }) => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { usarRota } = useContext(contextoServico);

    const [mercadoPago, definirMercadoPago] = useState<any>();

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.onload = () => {
            /* @ts-ignore */
            const mp = new MercadoPago('APP_USR-1aa9242f-f574-4a6b-9be3-6d23a775e168', {
                locale: 'pt-BR'
            });
            definirMercadoPago(mp);
        }
        script.src = "https://sdk.mercadopago.com/js/v2";
        document.head.appendChild(script);
    }, []);

    const selecionarPlano = async (plano_ID: number) => {
        const dados: RequisicaoLeitorPagamentoCriar = {
            plano_ID
        };
        const resposta: RespostaLeitorPagamentoCriar = await usarRota(leitorPagamentoCriar, dados);
        if (resposta.Status) {
            const platform = Capacitor.getPlatform();
            if (platform === 'web' && !isMobile) {
                mercadoPago.checkout({
                    preference: {
                        id: resposta.Dados.id
                    },
                    autoOpen: true
                });
            }
            else {
                Browser.open({ url: resposta.Dados.init_point });
            }
        }
    }
    const aceitarClique = () => {
        definirAberto(false);
        selecionarPlano(planoID);
    }
    const recusarClique = () => {
        definirAberto(false);
    }
    return <>
        <Dialog open={aberto}>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: "1" }}>
                    <DialogTitle>Termos de uso do usuário assinante</DialogTitle>
                </Box>
                <Box sx={{ padding: "15px" }} onClick={recusarClique}>
                    <Close />
                </Box>
            </Box>
            <DialogContent>
                <TermosUso/>
            </DialogContent>
            <DialogActions>
                <Button onClick={recusarClique}>Recusar</Button>
                <Button onClick={aceitarClique}>Aceitar</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default DialogoPagamento;