import { List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Paper } from "@material-ui/core";
import { useContext } from "react";
import { contextoNoticia } from "../../../contextos/noticia";
import baseURL from "../../../funcoes/baseURL";
import { estiloComentarios } from "../estilo";

export const Descricao = () => {

    const { estado } = useContext(contextoNoticia);
    const { Dados } = estado;
    const noticia = Dados?.Noticia;
    const estilo = estiloComentarios();
    const tipo = noticia?.noticia_confiabilidade_ID;

    if (!noticia) {
        return <></>
    }

    return <Paper variant="outlined">
        <List>
            {tipo !== 1 && <>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar variant="square">E</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={
                        <>Notícia {noticia.noticia_confiabilidade_Nome}</>
                    } secondary={
                        <div className={estilo.texto}>
                            por {noticia.editor_Nome} • {noticia.DataHoraVerificacao}
                        </div>
                    } />
                    <div style={{ display: 'flex' }}>
                        {tipo === 1 && <img className={estilo.seloAnalise} src={`${baseURL("imagem")}/analise.svg`} />}
                        {tipo === 2 && <img className={estilo.seloIndeterminada} src={`${baseURL("imagem")}/indeterminada.svg`} />}
                        {tipo === 3 && <img className={estilo.seloVerificada} src={`${baseURL("imagem")}/verificada.svg`} />}
                        {tipo === 4 && <img className={estilo.seloFalsa} src={`${baseURL("imagem")}/falsa.svg`} />}
                        {tipo === 5 && <img className={estilo.seloFalsa} src={`${baseURL("imagem")}/opiniao.svg`} />}
                    </div>
                </ListItem>
            </>}
            {tipo === 1 && <ListItem>
                <ListItemText primary={
                    <>Notícia em análise</>
                } secondary={
                    <div className={estilo.texto}>
                        A Equipe do Notícia Verificada está trabalhando para determinar a confiabilidade desta notícia.
                    </div>
                } />
                <div style={{ display: 'flex' }}>
                    <img className={estilo.seloAnalise} src="/imagens/analise.svg" />
                </div>
            </ListItem>}
        </List>
    </Paper>
}

export default Descricao;