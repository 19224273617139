export default (tipo: ("api" | "admin" | "adminImagem" | "imagem") = "api") => {
    // base url para a api 
    if (tipo === "api") {
        // Teste em localhost
        if (document.location.port.toString() === '3000') {
            return "//localhost:8080";
        }
        // Do aplicativo Android
        else {
            return "https://noticiaverificada.com/api"
        }
    }
    // base url para o admin
    if (tipo === "admin") {
        // Teste em localhost
        if (document.location.port.toString() === '3000') {
            return "//localhost/cliente";
        }
        // Do aplicativo Android
        else {
            return "https://noticiaverificada.com/cliente"
        }
    }
    // base url para imagens
    if (tipo === "imagem") {

        // //console.log("Está no aplicativo => " + (document.location.toString().indexOf("/www") > 0));
        // //console.log("Url do APP => " + document.location.toString().split("/www")[0] + "/www/imagens");
        // Do aplicativo android
        if (document.location.protocol === "file:") {
            /* @ts-ignore */
            if (window.OriginalURL) {
                /* @ts-ignore */
                return window.OriginalURL + "/imagens"
            }
            return document.location.toString().split("/www")[0] + "/www/imagens";
        }
        return "//" + document.location.host + "/imagens";
    }
    // Base url para as imagens dos anúncios
    if (tipo === "adminImagem") {
        // Teste em localhost
        if (document.location.port.toString() === '3000') {
            return "//localhost";
        }
        // Do aplicativo Android
        else {
            return "https://noticiaverificada.com"
        }
    }
    // Padrão
    if (document.location.protocol === "file:") {
        return document.location.toString().split("/www")[0] + "/www";
    }
    return "//" + document.location.hostname;
}