import { Container } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory } from "react-router";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import Atividade from "./templates/Atividade";
import Postagens from "./templates/Postagens";
import Tags from "./templates/Tags";

export const Perfil = () => {

    const [abaAtiva, definirAbaAtiva] = useState("/perfil/atividade");

    const history = useHistory();

    useEffect(() => {
        history.listen((e)=>{
            definirAbaAtiva(e.pathname);
        })
    }, [history])

    useEffect(()=> {
        definirAbaAtiva(history.location.pathname);
    }, [])

    return <>
        <BarraAplicativoSecundaria backToHome>
            <ToggleButtonGroup value={abaAtiva} size="small" style={{height: '25px'}}>
                <ToggleButton value="/perfil/atividade" onClick={() => history.push("/perfil/atividade")} style={{
                    color: "#ccc",
                    borderColor: 'gray',
                }}>
                    Atividade
                </ToggleButton>
                <ToggleButton value="/perfil/postagens" onClick={() => history.push("/perfil/postagens")} style={{
                    color: "#ccc",
                    borderColor: 'gray',
                }}>
                    Postagens
                </ToggleButton>
                <ToggleButton value="/perfil/tags" onClick={() => history.push("/perfil/tags")} style={{
                    color: "#ccc",
                    borderColor: 'gray',
                }}>
                    Tags
                </ToggleButton>
            </ToggleButtonGroup>
        </BarraAplicativoSecundaria>

        <Container maxWidth="md">
            <br />
            <Switch>
                <Route path="/perfil/atividade">
                    <Atividade />
                </Route>
                <Route path="/perfil/postagens">
                    <Postagens />
                </Route>
                <Route path="/perfil/tags">
                    <Tags />
                </Route>
                <Route path="/">
                    <Redirect to="/perfil/atividade" />
                </Route>
            </Switch>
        </Container>
    </>
}
export default Perfil