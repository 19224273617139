import { ReactNode } from "react"
import { BrowserRouter } from "react-router-dom"
import { ProvedorAplicacao } from "../../contextos/aplicacao"
import { ProvedorAutenticacao } from "../../contextos/autenticacao"
import { ProvedorConfiguracoes } from "../../contextos/configuracoes"
import { ProvedorNotificacao } from "../../contextos/notificacao"
import { ProvedorServico } from "../../contextos/servicos"
import { ProvedorTags } from "../../contextos/tags"
import { ControladorNativo } from "../ControladorNativo"
import CriarTema from "../CriarTema"

export const Provedores = ({children}: {children: ReactNode}) => {
    return <BrowserRouter>
        <ControladorNativo>
            <ProvedorAplicacao>
                <ProvedorConfiguracoes>
                    <ProvedorServico>
                        <ProvedorAutenticacao>
                            <ProvedorTags>
                                <ProvedorNotificacao>
                                    <CriarTema>
                                        {children}
                                    </CriarTema>
                                </ProvedorNotificacao>
                            </ProvedorTags>
                        </ProvedorAutenticacao>
                    </ProvedorServico>
                </ProvedorConfiguracoes>
            </ProvedorAplicacao>
        </ControladorNativo>
    </BrowserRouter>
}