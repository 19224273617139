import { CssBaseline } from '@material-ui/core';
import Dialogos from './dialogos';
import MenuLateral from './templates/Menu';
import { Provedores } from './templates/Provedores';
import { Rotas } from './templates/Rotas';
import UsuariosProfissionais from './templates/UsuariosProfissionais';


function App() {
  return (
    <>
      <Provedores>
        <CssBaseline />
        <Rotas />
        <Dialogos />
        <MenuLateral />
        <UsuariosProfissionais />
      </Provedores>
    </>
  );
}

export default App;