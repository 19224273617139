import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, InputLabel, Link, List, ListItem, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Fragment, useContext, useState } from "react";
import { contextoNoticias, ProvedorNoticias } from "../../../contextos/noticias";
import Carbela from "../../../templates/Carbela";

const DialogoConfirmar = ({ dialogoAberto, definirDialogoAberto, noticiaSelecionada }: { dialogoAberto: boolean, definirDialogoAberto: React.Dispatch<React.SetStateAction<boolean>>, noticiaSelecionada: number }) => {

    const { estado, acoes } = useContext(contextoNoticias);

    const noticia = estado.Noticias.find(noticia => noticia.ID === noticiaSelecionada);

    const valoresPadrao: {
        ID?: number,
        Resenha?: string,
        noticia_confiabilidade_ID?: number,
        Links: string[]
    } = {
        ID: noticia?.ID,
        Resenha: noticia?.Resenha,
        noticia_confiabilidade_ID: noticia?.noticia_confiabilidade_ID,
        Links: noticia?.Links.map(link => link.Link) || []
    };

    const [valores, definirValores] = useState(valoresPadrao);

    const acaoSalvar = async () => {
        if (noticia && valores.Resenha && valores.noticia_confiabilidade_ID) {
            const aprovado = await acoes.aprovarNoticia({
                noticia_ID: noticia.ID,
                noticia_confiabilidade_ID: valores.noticia_confiabilidade_ID,
                Resenha: valores.Resenha,
                Links: valores.Links
            });
            if (aprovado) {
                definirDialogoAberto(false);
                return true;
            }
            return false;
        }
        return false;
    }

    const acaoRemoverLink = (LinkIndex: number) => {
        definirValores({ ...valores, Links: valores.Links.filter((_, index) => index !== LinkIndex) });
    }

    return <Dialog open={dialogoAberto} onClose={() => definirDialogoAberto(false)}>
        <DialogTitle>
            {noticia?.Titulo}
        </DialogTitle>
        <DialogContent>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="situacao">Situação</InputLabel>
                <Select
                    labelId="situacao"
                    id="situacao-campo"
                    value={valores.noticia_confiabilidade_ID}
                    label="Situação"
                    onChange={(e) => {
                        const noticia_confiabilidade_ID = Number(e.target.value);
                        definirValores({ ...valores, noticia_confiabilidade_ID });
                    }}
                >
                    <MenuItem value={"1"}>Análise</MenuItem>
                    <MenuItem value={"2"}>Indeterminada</MenuItem>
                    <MenuItem value={"3"}>Verificada</MenuItem>
                    <MenuItem value={"4"}>Falsa</MenuItem>
                    <MenuItem value={"5"}>Opinião</MenuItem>
                </Select>
                <br />
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Resenha"
                    multiline
                    minRows={4}
                    maxRows={10}
                    value={valores.Resenha}
                    onChange={e => definirValores({ ...valores, Resenha: e.target.value })}
                    InputLabelProps={{ style: { color: "#858585" }, shrink: true }}
                    placeholder="Digite a resenha..."
                />
                <br />
                {valores.Links.map((Link, LinkIndex) => {
                    return <Fragment key={LinkIndex}>
                        <TextField
                            label={`Link ${LinkIndex + 1}`}
                            placeholder="Digite o link..."
                            style={{ width: '100%' }}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <>
                                    <Button size="small" style={{ minWidth: "0px" }} onClick={() => acaoRemoverLink(LinkIndex)}>
                                        <Delete />
                                    </Button>
                                </>
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={Link}
                            onChange={(e) => {
                                definirValores({ ...valores, Links: valores.Links.map((LinkN, LinkNIndex) => LinkIndex === LinkNIndex ? e.target.value : LinkN) });
                            }}
                        /> <br />
                    </Fragment>
                })}
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                definirValores({ ...valores, Links: [...valores.Links, ""] });
            }}>
                Link
            </Button>
            <Button onClick={() => {
                definirDialogoAberto(false);
            }}>
                Cancelar
            </Button>
            <Button onClick={acaoSalvar}>
                Salvar
            </Button>
        </DialogActions>
    </Dialog>
}

const UsarAbaNoticias = () => {

    const { acoes, estado } = useContext(contextoNoticias);

    const linhaAcoes = (noticia_ID: number) => {
        return <>
            <Fab onClick={() => {
                definirDialogoAberto(true);
                definirNoticiaSelecionada(noticia_ID);
            }} size="small" style={{ background: '#2980b9', marginRight: '10px' }} color="inherit">
                <Edit />
            </Fab>
            <Fab onClick={() => acoes.excluirNoticia({ noticia_ID })} size="small" style={{ background: '#c0392b', marginRight: '10px' }} color="inherit">
                <Delete />
            </Fab>
        </>
    }

    const criarLinkTitulo = (Slug: string, Titulo: string) => {
        return <Link
            color="inherit"
            onClick={() => {
                window.open(`/noticia/${Slug}`, "_blank");
            }}
            underline="always"
            style={{ cursor: "pointer" }}
        >
            {Titulo}
        </Link>
    }

    const linhas = estado.Noticias.map(noticia => [
        criarLinkTitulo(noticia.Slug, noticia.Titulo),
        noticia.DataHora,
        noticia.noticia_confiabilidade_Nome,
        linhaAcoes(noticia.ID)
    ]);

    const colunas = [
        ["Título", "calc(100% - 300px)"],
        ["Data", "50px"],
        ["Situação", '100px'],
        ["Ações", "150px"]
    ];

    const [dialogoAberto, definirDialogoAberto] = useState(false);
    const [noticiaSelecionada, definirNoticiaSelecionada] = useState<number>();

    return <>
        {noticiaSelecionada && <DialogoConfirmar dialogoAberto={dialogoAberto} definirDialogoAberto={definirDialogoAberto} noticiaSelecionada={noticiaSelecionada} key={("Dialogo" + noticiaSelecionada)} />}
        <div style={{ overflowY: 'scroll', height: 'calc(80vh)' }}>
            <Carbela linhas={linhas} colunas={colunas} larguraMaxima={600} />
        </div>
    </>
}

const AbaNoticias = () => {

    return <>
        <ProvedorNoticias estadoPersonalizado={{
            Limite: 1000,
            Pagina: 1,
            Termo: '',
            TagsIDs: [],
            Ordem: "Recentes",
            Seguindo: false,
            Pendente: false,
        }}>
            <UsarAbaNoticias />
        </ProvedorNoticias>
    </>
}

export default AbaNoticias;