import { createRef, useEffect, useState } from "react"
import baseURL from "../../funcoes/baseURL";
import { quebrarLinhas } from "../../funcoes/quebrarLinhas";
import QR from "qrcode";
import { Button } from "@material-ui/core";
import { baixarArquivo } from "../../funcoes/baixarArquivo";


interface Props {
    noticia: {
        Titulo: string;
        Link: string;
        Slug: string;
        Texto: string;
        usuario_Nome: string;
        usuario_Foto: string;
        Capa: string;
        DataHoraVerificacao: string;
        noticia_confiabilidade_ID: number;
        noticia_confiabilidade_Nome: string;
        usuario_Usuario: string;
    },
    moldura: {
        nome: string;
        url: string;
        border: boolean;
        tipo: string;
    },
    definirNoticiaSrc: React.Dispatch<React.SetStateAction<string>>,
}
export const RenderizarNoticia = ({ noticia, moldura, definirNoticiaSrc }: Props) => {

    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        const canvas = document.createElement("canvas");
        canvas.width = 1080;
        canvas.height = 1920;
        const context = canvas.getContext("2d");
        if (context) {
            const molduraImage = new Image();
            molduraImage.crossOrigin = "anonymous";
            molduraImage.src = moldura.url;
            molduraImage.onload = () => {

                // Desenhar base
                context.drawImage(molduraImage, 0, 0, 1080, 1920);

                // Desenhar capa
                const capaImage = new Image();
                capaImage.crossOrigin = "anonymous";
                capaImage.src = noticia.Capa;

                capaImage.onload = () => {


                    // get the scale
                    var scale = Math.max(1080 / capaImage.naturalWidth, 560 / capaImage.naturalHeight);
                    // get the top left position of the image
                    // var x = (1080 / 2) - (capaImage.naturalWidth / 2) * scale;
                    // var y = (600 / 2) - (capaImage.naturalHeight / 2) * scale;


                    context.drawImage(capaImage, 0, 1920 - 560, capaImage.naturalWidth * scale, capaImage.naturalHeight * scale);

                    // Desenhar selo
                    const seloImage = new Image();
                    seloImage.crossOrigin = "anonymous";
                    seloImage.src = `${baseURL("imagem")}/${(() => {
                        if (noticia.noticia_confiabilidade_ID == 1) return "analise";
                        if (noticia.noticia_confiabilidade_ID == 2) return "indeterminada";
                        if (noticia.noticia_confiabilidade_ID == 3) return "verificada";
                        if (noticia.noticia_confiabilidade_ID == 4) return "falsa";
                        if (noticia.noticia_confiabilidade_ID == 5) return "opiniao";
                    })()}.svg`;
                    seloImage.onload = async () => {
                        context.drawImage(seloImage, 740, 980, 300, 300);

                        // Desenhar QR Code 
                        const qrImageUrl = await QR.toDataURL("https://noticiaverificada.com/" + noticia.Slug);
                        const qrImage = new Image();
                        qrImage.src = qrImageUrl;
                        qrImage.onload = () => {

                            context.drawImage(qrImage, 52, 1070, 230, 230);

                            // Escrever Notícia
                            const linhas = quebrarLinhas(noticia.Titulo, 22);
                            context.font = "70px Roboto Mono";
                            context.fillStyle = "#fff";

                            linhas.forEach((linha, index) => {
                                index <= 4 && context.fillText(linha, 70, 400 + (70 * (index)));
                                index == 5 && context.fillText("...", 930, 400 + (70 * (index - 1)));
                            });
                            const finalLinhas = 400 + (70 * Math.min(linhas.length, 5));

                            // Escrever link da notícia
                            context.font = "bold 20px Roboto Mono";
                            context.fillText(noticia.Link, 70, finalLinhas, 960);

                            // Escrever texto da notícia
                            const linhas2 = quebrarLinhas(noticia.Texto, 40);
                            context.font = "bold 30px Roboto Mono";
                            linhas2.forEach((linha, index) => {
                                context.fillText(linha, 70, (70 + finalLinhas) + (30 * index))
                            })



                            const avatarImage = new Image();
                            avatarImage.crossOrigin = "anonymous";
                            avatarImage.src = noticia.usuario_Foto;
                            let successAvatar = false;

                            // Desenhar avatar
                            const afterLoadImage = () => {

                                // Desenhar avatar
                                if (successAvatar) {
                                    context.drawImage(avatarImage, 290, 1150, 100, 100);
                                }

                                // Escrever nome do usuário  
                                context.fillStyle = "#000";
                                context.font = "bold 30px Roboto Mono";
                                context.fillText(noticia.usuario_Nome, 400, 1190, 920);
                                context.fillText("@" + noticia.usuario_Usuario, 400, 1230, 300);


                                // Escrever link do notícia verificada
                                context.font = "bold 20px Roboto Mono";
                                context.fillStyle = "#000";
                                context.fillText("https://noticiaverificada.com/" + noticia.Slug, 70, 1326, 970);


                                // Escrever verdadeiro, falso, etc...
                                context.font = "58px Roboto Mono";
                                context.fillStyle = "#000";
                                context.fillText("Notícia " + noticia.noticia_confiabilidade_Nome, 70, 1020);
                                context.font = "30px Roboto Mono";
                                context.fillText(`Verificado em ${noticia.DataHoraVerificacao}`, 70, 1060);

                                // Renderizar Canvas
                                canvas.toBlob((blob) => {
                                    if (blob) {
                                        const url = URL.createObjectURL(blob);
                                        setImageSrc(url);
                                        definirNoticiaSrc(url);
                                    }
                                }, "image/jpeg", 0.9);
                            };
                            avatarImage.onload = () => {
                                successAvatar = true;
                                afterLoadImage();
                            }
                            avatarImage.onerror = afterLoadImage;
                        }
                    }
                }
            }
        }
    }, [noticia, moldura]);
    return <>
        <img src={imageSrc} style={{
            width: "min(100%, 300px)"
        }} />
    </>
}
export default RenderizarNoticia;