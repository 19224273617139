import { DialogContent, TextField, Card, CardMedia, CardContent, Typography, DialogActions, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useContext, useEffect } from "react";
import { contextoAplicacao } from "../../../contextos/aplicacao";
import contextoPublicacao from "../../../contextos/publicacao";
import contextoTags from "../../../contextos/tags";

export const NoticiaLink = () => {
    const { estado: estadoTags } = useContext(contextoTags);
    const { estado, acoes } = useContext(contextoPublicacao);
    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
    useEffect(() => {
        return () => {
            acoes.limpar();
        }
    }, []);
    return <>

        <DialogContent>
            <TextField onBlur={acoes.visualizarLink} value={estado.Link} onChange={e => acoes.definirEstado({ ...estado, Link: e.target.value })} InputLabelProps={{ style: { color: "#858585" } }} variant="standard" label="Link da Notícia" fullWidth />
            <br /><br />
            <TextField value={estado.Comentario} onChange={e => acoes.definirEstado({ ...estado, Comentario: e.target.value })} InputLabelProps={{ style: { color: "#858585" } }} variant="standard" label="Comentário (opcional)" fullWidth />
            <br /><br />
            <Autocomplete
                multiple
                id="tags-standard"
                options={estadoTags.Tags}
                getOptionLabel={(tag) => tag.Nome}
                value={estadoTags.Tags.filter(tag => estado.Tags_IDs.includes(tag.ID))}
                onChange={(_, value) => acoes.definirEstado({ ...estado, Tags_IDs: value.map(tag => tag.ID) })}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tags"
                        placeholder="Pesquisar por Tags..."
                    /> 
                )}
            />
            <br /><br />
            <Card style={{ display: 'flex' }}>
                <CardMedia image={estado.Visualizacao.Capa} style={{ minWidth: '116px', width: '116px', height: '116px' }} />
                <CardContent style={{ width: 'calc(100% - 116px)' }}>
                    <Typography variant="h6" noWrap>{estado.Visualizacao.Titulo}</Typography>
                    <Typography variant="body1" noWrap>{estado.Visualizacao.Texto}</Typography>
                    <Typography variant="body2" noWrap>{estado.Visualizacao.Link}</Typography>
                </CardContent>
            </Card>
            <br />
        </DialogContent>

        <DialogActions>
            <Button onClick={() => acoesAplicacao.definirEstado({ ...estadoAplicacao, adicionarAberto: false })}>Cancelar</Button>
            <Button onClick={() => acoes.publicar()}>Enviar</Button>
        </DialogActions>
    </>
}

export default NoticiaLink;