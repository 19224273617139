import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const estiloIndex = makeStyles((tema: Theme) => {
    return createStyles({
        selo: {
            height: '80px',
            position: "absolute",
            right: "10px",
        }
        // seloIndeterminada: {
        //     height: '40px',
        //     [tema.breakpoints.down('sm')]: {
        //         height: '60px',
        //         marginTop: '-30px'
        //     },
        // },
        // seloVerificada: {
        //     height: '50px',
        //     [tema.breakpoints.down('sm')]: {
        //         height: '80px',
        //         marginTop: '-40px'
        //     },
        // },
        // seloFalsa: {
        //     height: '30px',
        //     [tema.breakpoints.down('sm')]: {
        //         height: '45px',
        //         marginTop: '-15px'
        //     },
        // },
    });
});

export default estiloIndex;