export default ({reacao_tipo_ID, antiga_reacao_tipo_ID, Likes, Deslikes}: {
    reacao_tipo_ID: number,
    antiga_reacao_tipo_ID: number,
    Likes: number,
    Deslikes: number
}) => {

    //console.log({reacao_tipo_ID, antiga_reacao_tipo_ID, Likes, Deslikes});
    // Apertou no que ja estava
    if (reacao_tipo_ID === antiga_reacao_tipo_ID) {
        // Descomputar o Like
        if(reacao_tipo_ID === 2) {
            reacao_tipo_ID = 1;
            Likes--;
        }
        // Descomputador o Deslike
        if(reacao_tipo_ID === 3) {
            reacao_tipo_ID = 1;
            Deslikes--;
        }
    }
    else {
        // Não tinha reação e adicinou o Like
        if(antiga_reacao_tipo_ID === 1 && reacao_tipo_ID === 2) {
            Likes++;
        }
        // Não tinha reação e adicinou o Deslike
        if(antiga_reacao_tipo_ID === 1 && reacao_tipo_ID === 3) {
            Deslikes++;
        }
        // Reação era Like e virou deslike
        if(antiga_reacao_tipo_ID === 2 && reacao_tipo_ID === 3) {
            Likes--;
            Deslikes++;
        }
        // Reação era Deslike e virou Like
        if(antiga_reacao_tipo_ID === 3 && reacao_tipo_ID === 2) {
            Likes++;
            Deslikes--;
        }  
    }
    return {
        tipo_reacao_ID: reacao_tipo_ID,
        Likes,
        Deslikes,
    }
}