import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorTagsSeguir {
    tag_ID: number;
    Seguir: boolean;
}

export interface RespostaLeitorTagsSeguir {
    Status: boolean;
    Mensagem: string;
}

export const leitorTagsSeguir = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/tags/seguir", dados);
}

export default leitorTagsSeguir
