import { Switch, Route } from "react-router-dom";
import { ProvedorSeguranca } from "../../contextos/seguranca"
import Etapa1 from "./templates/Etapa1"
import Etapa2 from "./templates/Etapa2";

export const Seguranca = () => {
    return <ProvedorSeguranca>
        <Switch>
            <Route path="/seguranca" exact>
                <Etapa1/>
            </Route>
            <Route path="/seguranca/confirmar" exact>
                <Etapa2/>
            </Route>
        </Switch>
    </ProvedorSeguranca>
}

export default Seguranca