import { AxiosInstance, AxiosResponse } from "axios";

interface Notificacao {
    ID: number,
    notificacao_tipo_ID: number,
    Titulo: string,
    noticiaTitulo?: string,
    noticiaSlug?: string,
    noticiaCapa?: string
}

export interface RespostaLeitorNotificacoesListar {
    Status: boolean;
    Mensagem: string;
    Dados: Notificacao[];
}

export const leitorNotificacoesListar = async (buscador: AxiosInstance): Promise<AxiosResponse> => {
    return buscador.post("/leitor/notificacoes/listar");
}

export default leitorNotificacoesListar;