import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { ChatBubble, ListAlt, ThumbDown, ThumbUp } from "@material-ui/icons";
import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { contextoServico } from "../../../contextos/servicos";
import leitorReacoesListar, { RespostaLeitorReacoesListar } from "../../../servicos/leitorReacoesListar";

export const Atividade = () => {

    const { usarRota } = useContext(contextoServico);
    const [estado, definirEstado] = useState<RespostaLeitorReacoesListar['Dados']>([]);

    useEffect(() => {
        (async () => {
            const resposta: RespostaLeitorReacoesListar = await usarRota(leitorReacoesListar);
            if (resposta.Status) {
                definirEstado(resposta.Dados);
            }
        })()
    }, []);

    const history = useHistory();

    return <>
        <List>
            {estado.map(reacao => (
                <Fragment key={reacao.Tipo + reacao.ID + (new Date).toString()}>
                    <ListItem
                        alignItems="flex-start"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        button
                        onClick={() => history.push(`/noticia/${reacao.Slug}`)}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {reacao.Tipo === "comentario" && <ChatBubble />}
                                {reacao.Tipo === "reacao" && reacao.ID === 2 && <ThumbUp />}
                                {reacao.Tipo === "reacao" && reacao.ID === 3 && <ThumbDown />}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={
                            <Typography noWrap>
                                {reacao.Linha1}
                            </Typography>
                        } secondary={
                            <Typography noWrap>
                                {reacao.Linha2}
                            </Typography>
                        } />
                        <ListItemAvatar>
                            <Avatar src={reacao.Capa} variant="square">
                                <ListAlt />
                            </Avatar>
                        </ListItemAvatar>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </Fragment>
            ))}
        </List>
    </>
}

export default Atividade;