import React, { useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { useContext } from "react";
import contextoAutenticacao from "../../contextos/autenticacao";
import { contextoNoticias, ProvedorNoticias } from "../../contextos/noticias";

type SetState = React.Dispatch<React.SetStateAction<{
    Titulo: string;
    Link: string;
    Slug: string;
    Texto: string;
    usuario_Nome: string;
    usuario_Foto: string;
    Capa: string;
    DataHoraVerificacao: string;
    noticia_confiabilidade_ID: number;
    noticia_confiabilidade_Nome: string;
    usuario_Usuario: string;
} | undefined>>;

const ListaNoticias = ({ setState }: { setState: SetState }) => {
    const { estado: estadoNoticias } = useContext(contextoNoticias);
    const noticias = estadoNoticias.Noticias;
    const [noticiaID, definirNoticiaID] = useState<Number>(0);
    return <>
        <Select variant="outlined" value={noticiaID} onChange={(e) => {
            definirNoticiaID(Number(e.target.value));
            const noticia = noticias.find(noticia => noticia.ID === Number(e.target.value));
            noticia && setState({
                Titulo: noticia.Titulo,
                Link: noticia.Link,
                Slug: noticia.Slug,
                Texto: noticia.Texto,
                usuario_Nome: noticia.usuario_Nome,
                usuario_Foto: noticia.usuario_Foto,
                Capa: noticia.Capa,
                DataHoraVerificacao: noticia.DataHoraVerificacao,
                noticia_confiabilidade_ID: noticia.noticia_confiabilidade_ID,
                noticia_confiabilidade_Nome: noticia.noticia_confiabilidade_Nome,
                usuario_Usuario: noticia.usuario_Usuario
            });
        }}>
            <MenuItem value={0}>Selecione</MenuItem>
            {noticias.map(noticia => {
                return <MenuItem key={noticia.ID} value={noticia.ID}>
                    {noticia.Titulo}
                </MenuItem>
            })}
        </Select>
        <br />
    </>
}

const SelecionarNoticia = ({ setState }: { setState: SetState }) => {
    const { usuario_ID, tipo_usuario_ID } = useContext(contextoAutenticacao).estado;
    return <ProvedorNoticias key={"PublicacoesLeitor"} estadoPersonalizado={{
        Limite: 45,
        Pagina: 1,
        Termo: '',
        TagsIDs: [],
        Ordem: "Recentes",
        Seguindo: false,
        Pendente: false,
        usuario_ID: tipo_usuario_ID === 1 ? undefined : usuario_ID,
    }}>
        <ListaNoticias setState={setState} />
    </ProvedorNoticias>
}
export default SelecionarNoticia;