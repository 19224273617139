import { Box, Button, Card, CardContent, Container, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useContext, useState } from "react";
import contextoConfiguracoes from "../../contextos/configuracoes";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import DialogoPagamento from "./dialogoPagamento";
import planos from "./planos";


const Pagamento = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const usarTemaPreto = useContext(contextoConfiguracoes).estado.usarTemaPetro;

    const [planoSelecionado, definirPlanoSelecionado] = useState(1);
    const [aberto, definirAberto] = useState(false);

    const selecionarPlano = (planoID: number) => {
        definirPlanoSelecionado(planoID);
        definirAberto(true);
    }

    return <>
        <BarraAplicativoSecundaria backToHome />
        <DialogoPagamento aberto={aberto} planoID={planoSelecionado} definirAberto={definirAberto}/>
        <Box style={{
            background: "linear-gradient(322deg, rgb(29, 76, 133), rgb(17, 53, 97))",
            color: "white",
            width: "100%",
        }} >
            <Container maxWidth="md">
                <br />
                <Typography
                    variant="h3"
                    style={{
                        textAlign: "center"
                    }}
                >
                    Seja Assinante
                </Typography>
                <Typography
                    variant="body1"
                    style={{
                        textAlign: "center"
                    }}
                >

                    Com a conta Assinante, você pode dar um bust na sua marca e imagem liberando funções e serviços especiais se comprometendo com a verdade!
                </Typography>
                <br /> <br /><br /><br />
            </Container>
        </Box>
        <Box style={{
            position: "fixed",
            top: "0px",
            height: "100vh",
            width: "100%",
            background: usarTemaPreto ? "#303030" : "#ddd",
            zIndex: -1,
        }} />
        <Container maxWidth="md">
            <Grid container spacing={4}>
                {planos.map((plano, index) => {
                    return <Grid key={plano.ID} item xs={12} md={12} style={{
                        marginTop: isMobile ? "30px" : "0px"
                    }}>
                        <Card style={{
                            marginTop: (index === 1 && !isMobile) ? "-50px" : "-20px",
                        }}>
                            <CardContent style={{
                                display: "flex",
                                flexDirection: "column",
                                height: index === 1 ? "430px" : "400px",
                            }}>
                                <Typography variant="h5" style={{
                                    textAlign: "center"
                                }}>
                                    {plano.Nome}
                                </Typography>
                                <Typography variant="h4" style={{
                                    textAlign: "center"
                                }}>
                                    R$ {(plano.ValorSemestral/6).toFixed(2).replace(".", ",")} 
                                    <Typography variant="body1">/mês</Typography>                                    
                                </Typography>

                                <Box style={{ flexGrow: 1 }}>
                                    <Typography variant="body1">
                                        <ul>
                                            {plano.vantagens.map(vantagem => {
                                                return <li key={vantagem} style={{
                                                    paddingBottom: "8px"
                                                }}>{vantagem}</li>
                                            })}
                                        </ul>
                                        
                                    </Typography>
                                </Box>
                                <Box style={{textAlign: "center", fontWeight: "bold"}}>Plano Semestral</Box>
                                <Button variant="outlined" fullWidth onClick={() => selecionarPlano(plano.ID)}>Selecionar Plano</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </Container>
    </>
}
export default Pagamento;