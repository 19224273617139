import { Button, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { Add, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { useContext, useState } from "react";
import contextoTags from "../../../contextos/tags";
import criarTermoPesquisa from "../../../funcoes/removerAcentos";

const AbaTags = () => {

    const { estado, acoes } = useContext(contextoTags);

    const acoesLinha = (ID: number, Mostrar: boolean) => {
        return <>
            <Button size="small" onClick={() => {
                acoes.alterarTag({ ID, Mostrar: !Mostrar });
            }}>
                {Mostrar ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </Button>
        </>
    };

    const colunas = [
        ["Nome", "calc(50% - 25px)"],
        ["Slug", "calc(50% - 25px)"],
        ["Ações", "50px"]
    ];

    const [termoPesquisa, definirTermoPesquisa] = useState("");

    const linhas = estado.Tags.filter(tag => {
        if (termoPesquisa) {
            return criarTermoPesquisa(tag.Nome + " " + tag.Slug).search(criarTermoPesquisa(termoPesquisa)) >= 0
        }
        return true;
    }).map(tag => [
        tag.Nome,
        tag.Slug,
        acoesLinha(tag.ID, tag.Mostrar)
    ]);

    const quantidade = linhas.length;

    const acaoAdicionarTag = () => {
        acoes.adicionarTag({ Nome: termoPesquisa });
    }

    return <>
        <Paper style={{ height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100px', padding: '20px' }}>
                <div style={{ flexGrow: 1, alignItems: 'center' }}>
                    <TextField value={termoPesquisa} onChange={e => definirTermoPesquisa(e.target.value)} label="Pesquisar ou adicionar" placeholder="Digite o termo..." variant="standard" InputLabelProps={{ style: { color: '#666' }, shrink: true }} />
                </div>
                <Fab size="small" onClick={acaoAdicionarTag}>
                    <Add />
                </Fab>
            </div>
            <div style={{ overflowY: 'scroll', height: 'calc(80vh - 100px)' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {colunas.map(coluna => <TableCell style={{ width: coluna[1] }}>{coluna[0]}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {linhas.map(linha => <TableRow>

                                {linha.map(tag => <>
                                    <TableCell>{tag}</TableCell>
                                </>)}

                            </TableRow>)}
                            {(termoPesquisa && quantidade === 0) && <TableRow>
                                <TableCell colSpan={3}>
                                    <Typography variant="subtitle2">
                                        Nenhum resultado encontrado. <Button size="small" color="default" variant="outlined" onClick={acaoAdicionarTag}>
                                            {"Adicionar a tag " + termoPesquisa}
                                        </Button>
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Paper>
    </>
}

export default AbaTags;