import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorComentariosReacaoAtualizar {
    comentario_ID: number;
    reacao_tipo_ID: number;
}

export interface RespostaLeitorComentariosReacaoAtualizar {
    Status: boolean;
    Mensagem: string;
}

export const leitorComentariosReacaoAtualizar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/comentarios/reacao/atualizar", dados);
}

export default leitorComentariosReacaoAtualizar
