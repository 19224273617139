import { createStyles, makeStyles, Theme } from "@material-ui/core";
 
export const estiloIndex = makeStyles((tema: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        buttonHeader: {
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "4px",
            marginRight: "5px",
            '&:hover': {
                backgroundColor: "white",
            },
            '&:last-child': {
                marginRight: "0px"
            }
        },
        containerMargin: {
            marginTop: '91px'
        },
        topoPrincipal: {
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
            alignItems: 'center'
        },
        link: {
            flexGrow: 1,
        },
        linkAtivo: {
            display: "inline-block",
            borderBottom: "3px solid white"
        },
        linkTexto: {
            display: "inline-block",
            cursor: "pointer",
            '&:hover': {
                borderBottom: "3px solid gray"
            }
        }
    });
});