
import { Switch, Route } from 'react-router-dom';
import Administracao from '../../paginas/Administracao';
import Gerenciamento from '../../paginas/Gerenciamento';
import Inicio from '../../paginas/Inicio';
import { Notificacoes } from '../../paginas/Notificacoes';
import Perfil from '../../paginas/Perfil';
import { Pesquisa } from '../../paginas/Pesquisa';
import Privacidade from '../../paginas/Privacidade';
import Tag from '../../paginas/Tag';
import VerNoticia from '../../paginas/VerNoticia';
import Seguranca from '../../paginas/Seguranca';
import Pagamento from '../../paginas/Pagamento';
import Selador from '../../paginas/Selador';

export const Rotas = () => {
    // O "Browser Router" está localizado em Contextos, pois a aplicação e outros contextos dependem dele.
    return (
        <>
            <Switch>
                <Route path="/noticia/:slug" render={props => {
                    return <VerNoticia key={props.match.params.slug} />
                }}>
                </Route>
                <Route path="/tag/:slug">
                    <Tag />
                </Route>
                <Route path="/administracao">
                    <Administracao />
                </Route>
                <Route path="/gerenciamento">
                    <Gerenciamento />
                </Route>
                <Route path="/perfil">
                    <Perfil />
                </Route>
                <Route path="/pesquisa">
                    <Pesquisa />
                </Route>
                <Route path="/notificacoes">
                    <Notificacoes />
                </Route>
                <Route path="/privacidade">
                    <Privacidade />
                </Route>
                <Route path="/seguranca">
                    <Seguranca />
                </Route>
                <Route path="/pagamento">
                    <Pagamento />
                </Route>
                <Route path="/selo">
                    <Selador />
                </Route>
                <Route path="/">
                    <Inicio />
                </Route>
            </Switch>
        </>
    );
}