import { Typography } from "@material-ui/core"

export const TermosUso = () => {
    return <>
        <br />
        <Typography variant="body1">
            O app Notícia Verificada ("aplicativo") fornece um serviço personalizado de averiguação de Fake News,
            voltado a checagem de notícias por meio do aplicativo (notícia verificada) e o site
            (www.noticiaverificada.com), contando com uma ampla equipe técnica juntando ferramentas de
            inteligência artificial e a expertise de informações da equipe jurídica.
            Estes Termos de Uso regulam a sua utilização dos serviços Notícia Verificada. Usados nestes Termos de
            Uso, os termos “serviços Notícia Verificada”, “nossos serviços” ou “os serviços” significam o serviço
            personalizado fornecido pelo app Notícia Verificada, para buscar e acessar nossos conteúdos, incluindo
            todos os recursos e funcionalidades, recomendações e avaliações, nossos sites e as interfaces do usuário,
            assim como todo o conteúdo e software associados ao serviço.
        </Typography>
        <br />
        <Typography variant="body1">
            Ultima atualização em: 11 de Julho de 2022.
        </Typography>        <br />
        <Typography variant="h6">
            1. DAS FUNCIONALIDADES DO APP
        </Typography>
        <br />
        <Typography variant="body1">
            <ul>
                <li>
                    <b>1.1. </b> Somos um aplicativo para verificação de notícias, que ajuda o usuário a reforçar o seu compromisso
                    com a informação verdadeira e que vai te proteger dos geradores e propagadores de FAKE NEWS.
                </li>
                <li>
                    <b>1.2. </b>O aplicativo e o site Notícia Verificada têm por finalidade divulgar notícias de interesse público,
                    cadastradas pelos usuários para sua checagem e confirmadas como verdadeiras, falsas ou indefinidas;
                </li>
                <li>
                    <b>1.3. </b>Nossos serviços estão disponibilizado de forma online, em licenças individuais, utilizando
                    ferramentas tecnológicas de informação e comunicação e ambiente virtual para que o usuário possa
                    acessar e de forma autônoma através de usuário e senha.
                </li>
            </ul>
        </Typography>
        <br />
        <Typography variant="h6">
            2. DOS PLANOS E VALORES
        </Typography>
        <br />
        <Typography variant="body1">
            <ul>
                {/* <li>
                    <b>2.1. </b>Os planos são destinados a usuários que buscam o selo ("banner") de "Compromisso com a
                    Verdade", ou ainda aquele usuário que necessita a checagem de notícias para o seu dia a dia, seja para
                    fins comerciais ou pessoais.
                </li>
                <li>
                    <b>2.2. </b>PLANO PRATA: conta com revisão diária de conteúdos com menção ao usuário, notificando-o
                    para exercer seu direito à réplica. Neste plano o usuário possui uma hashtag (#) exclusiva. Valor do
                    plano R$ 1.500,00 por mês.
                </li>
                <li>
                    <b>2.3. </b>PLANO GOLD: conta com revisão diária de conteúdos com menção ao usuário, notificando-o
                    para exercer seu direito à réplica. Neste plano o usuário possui uma hashtag (#) exclusiva e um
                    Banner (Randômico 728 x 90 Pixels), com veiculação de uma semana a cada mês. Valor do plano R$
                    3.000,00 por mês.
                </li> */}
                <li>
                    <b>2.1. </b>ASSINATURA: conta com revisão diária de conteúdos com menção ao usuário,
                    notificando-o para exercer seu direito à réplica. Neste plano o usuário possui duas hashtags (#)
                    exclusivas e um Banner (Randômico 940 x 788 Pixels), com veiculação de uma semana a cada mês.
                    Valor do plano R$ 590,00 por mês.
                </li>
                <li>
                    <b>2.2. </b>Os valores descritos acima são de recorrência mensal, sendo que o prazo de contratação mínima
                    dos planos é semestral.
                </li>
                <li>
                    <b>2.3. </b>Decorrido o prazo da prestação de serviços, não haverá mais permissão de acessos a todo conteúdo
                    exclusivo contratado na plataforma, devendo o usuário renovar o plano caso queira.
                </li>
            </ul>
        </Typography>

        <Typography variant="h6">
            3. DA ASSINATURA E FORMAS DE PAGAMENTO
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>3.1. </b>O Usuário poderá contratar nossos serviços através de atendimento personalizado pelo telefone (67) 99204-6545 (com whatsapp) ou através do e-mail: adm@noticia verificada.com; </li>
                <li><b>3.2. </b>O valor total a ser pago pelo Usuário dependerá da modalidade de licença adquirida, que deve ser informada na solicitação de plano (prata, gold ou premium), desta mesma forma, o Usuário deverá escolher a forma de pagamento através da solicitação. </li>
                <li><b>3.3. </b>O usuário pode atualizar as suas Formas de Pagamento através de contato com o nosso suporte. </li>
                <li><b>3.4. </b>O Usuário compreende que o preço pago em contraprestação aos serviços contratados independe de sua respectiva utilização, de modo que, sua obrigação de pagar mantem‐se válida e eficaz, independentemente de o Usuário ter optado por não fazer ou tenha sido impossibilitado de realizar os direitos inerentes a utilização da plataforma, por razões que não sejam imputadas ao Notícia Verificada. </li>
                <li><b>3.5. </b>A confirmação da assinatura só será realizada após o recebimento da primeira parcela do pagamento. 3.6. Em caso de inadimplemento, poderá o CONTRATADO cobrar multa de 2% sobre a parcela devida, juros de mora de 1% ao mês e atualização monetária com base no IPCA, bem como adotar todas as providências legais de cobrança cabíveis, inclusive a inscrição do Usuário em órgãos de proteção ao crédito. </li>
            </ul>
        </Typography>

        <Typography variant="h6">
            4. DAS OBRIGAÇÕES DO USUÁRIO
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>4.1 </b>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Notícia Verificada oferece no site e com caráter enunciativo, mas não limitativo:
                    Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
                    Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas online, jogos de sorte e azar, de estabelecimentos comerciais e qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
                    Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Notícia Verificada, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados. </li>
                <li><b>4.2. </b>Manter sigilo das credenciais de acesso ao ambiente virtual da plataforma (usuário e senha), não compartilhando ou autorizando que outras pessoas utilizem seu acesso. </li>
            </ul>
        </Typography>

        <Typography variant="body1">
            <ul>
                <li><b>4.3 </b>Fazer bom uso do Banner disponibilizado, utilizando-o com preceitos de ética e boa-fé, entendendo que a divulgação de fake news pode acarretar a perda do selo de compromisso com a verdade. </li>
            </ul>
        </Typography>

        <Typography variant="h6">
            5. DAS OBRIGAÇÕES DO APLICATIVO
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>5.1. </b> Disponibilizar em até 5 (cinco) dias o acesso ao ambiente virtual por meio de usuário e senha, que será feito após a confirmação do pagamento; </li>
                <li><b>5.2. </b> Disponibilizar a entrega dos conteúdos e itens referente ao plano contratado; </li>
                <li><b>5.3. </b> Fornecer ao Usuário informações sobre o desempenho no ambiente virtual; </li>
                <li><b>5.4. </b> Oferecer suporte ao usuário mediante contato via WhatsApp, e-mail ou pela plataforma, de acordo com a necessidade ou dúvida</li>
                <li><b>5.5. </b> Garantir a funcionalidade das especificações do plano contratado. </li>
            </ul>
        </Typography>

        <Typography variant="h6">
            6. RESPONSABILIDADE DIGITAL E PROTEÇÃO DE DADOS
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>6.1. </b>  O aplicativo, submete-se ao cumprimento dos deveres e das obrigações referentes à proteção de Dados Pessoais e se compromete a tratar os Dados Pessoais coletados no âmbito do presente Termo de Uso, se houver, de acordo com a legislação vigente aplicável, incluindo, mas não se limitando, à Lei n. 13.709/18 (Lei Geral de Proteção de Dados), no que couber e conforme aplicável. </li>
                <li><b>6.2. </b>  O aplicativo se compromete a tratar seus dados conforme sua política de privacidade que pode ser acessada no seguinte link: </li>
            </ul>
        </Typography>

        <Typography variant="h6">
            7. DO CANCELAMENTO DOS SERVIÇOS
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>7.1.</b> O Usuário poderá exercer o seu direito do arrependimento previsto no art. 49 do Código de Defesa do Consumidor, no prazo de 7 (sete) dias contados do fornecimento de usuário e senha para acesso ao ambiente virtual</li>
                <li><b>7.2.</b> O Usuário pode cancelar sua assinatura a qualquer momento e continuará a ter acesso aos serviços até o fim do período de faturamento. Para cancelamento entre em contato com o suporte conforme item 3.1.</li>
            </ul>
        </Typography>

        <Typography variant="h6">
            8. DISPOSIÇÕES GERAIS

        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>8.1.</b>    Estes Termos de Uso devem ser regidos por e interpretados de acordo com a legislação do Brasil.</li>
                <li><b>8.2.</b>    O USUÁRIO fica ciente de que uma vez adquirida a licença individual da plataforma as credenciais de acesso (usuário e senha) ao ambiente virtual são pessoais e intransferíveis, responsabilizando-se por toda e qualquer utilização em descumprimento ao disposto neste termo.</li>
                <li><b>8.3.</b>    O Aplicativo não será responsabilizado por eventuais problemas de acesso à plataforma decorrentes de conexão com a internet e/ou equipamentos inadequados (computadores, celulares ou tablets) que não tenham capacidade de garantir bom acesso à plataforma e ao seu conteúdo.</li>
                <li><b>8.4.</b>    O Usuário deverá comunicar através da plataforma ou ainda via telefone: (67)99204-6545 (com whatsapp) ou através do e-mail: adm@noticiaverificada.com, se houver qualquer problema técnico ou falha sistêmica no aplicativo, para que obtenha a melhor solução técnica.</li>
                <li><b>8.5.</b>    O Aplicativo se reserva ao direito de introduzir melhorias e aperfeiçoamento na plataforma, sem que haja a necessidade de aviso prévio ao Usuário, salvo se tal melhoria acarretar na necessidade de suspensão temporária do uso.</li>
                <li><b>8.6.</b>    Qualquer concessão ou tolerância pelo não cumprimento de qualquer disposição deste contrato, constitui mera liberalidade, não constituindo novação, precedente invocável, alteração tácita ou renúncia de direitos.</li>
                <li><b>8.7.</b>    Se qualquer disposição ou disposições destes Termos de Uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade, legalidade e aplicabilidade das demais disposições devem permanecer em pleno vigor.</li>
                <li><b>8.8.</b>    Enviaremos informações relacionadas à sua conta (por exemplo, autorizações de pagamento, cobranças, alterações de senha ou Forma de Pagamento, mensagens de confirmação, notificações) somente em formato eletrônico como, por exemplo, por meio de e-mails ou telefone para o endereço fornecido durante a inscrição.</li>
                <li><b>8.9.</b>    A aceitação destes termos pode ser realizada através de assinatura eletrônica, aceite direto no aplicativo ou ainda pela assinatura física destes termos.</li>
            </ul>
        </Typography>

        <Typography variant="h6">
            9. FORO
        </Typography>
        <br />

        <Typography variant="body1">
            <ul>
                <li><b>9.1.</b> Caso surjam conflitos relacionados a este Termo de Uso, fica eleito o foro da Comarca da Cidade de Campo Grande - MS, com exclusão de qualquer outro, por mais privilegiado que seja.</li>
            </ul>
        </Typography>
    </>
}