import { Card, CardContent, Slider } from "@material-ui/core";
import { ZoomIn, RotateRight } from "@material-ui/icons";
import { useContext, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import contextoConfiguracoes from "../../contextos/configuracoes";

interface EditorProps {
    imageRef: React.RefObject<HTMLImageElement>,
    editorRef: React.RefObject<AvatarEditor>,
    moldura: {
        nome: string;
        url: string;
        border: boolean;
        tipo: string;
    },
    foto: string
}

export const EditorFotos = ({ imageRef, editorRef, moldura, foto }: EditorProps) => {

    const [zoom, definirZoom] = useState(1);
    const [rotacao, definirRotacao] = useState(0);

    const preVisualizarContainer = useRef<HTMLDivElement>(null);

    const [editorWidth, setEditorWidth] = useState(0);
    const [editorHeight, setEditorHeight] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const imageWidth = imageRef.current?.width || 0;
            const imageHeight = imageRef.current?.height || 0;
            if (imageWidth != editorWidth || imageHeight != editorHeight) {
                setEditorWidth(imageRef.current?.width || 0);
                setEditorHeight(imageRef.current?.height || 0);
            }
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const usarTemaPreto = useContext(contextoConfiguracoes).estado.usarTemaPetro;

    return <Card style={{ maxWidth: "332px", margin: "auto" }}>
        <CardContent ref={preVisualizarContainer}>
            <ZoomIn />
            <Slider
                style={{
                    color: "#234D80",
                    width: "calc(100% - 2em)",
                }}
                value={zoom}
                onChange={(e, value) => definirZoom(Number(value))}
                min={1}
                max={3}
                step={0.1}
            />
            <br />
            <RotateRight />
            <Slider
                style={{
                    color: "#234D80",
                    width: "calc(100% - 2em)",
                }}
                value={rotacao}
                onChange={(e, value) => definirRotacao(Number(value))}
                min={-180}
                max={180}
                step={3}
            />
            <br /><br />
            <img style={{
                pointerEvents: "none",
                position: "absolute",
                borderRadius: moldura.border ? "100%" : "0%",
                margin: "1px",
                width: `min(300px, ${preVisualizarContainer.current?.clientWidth ?
                    preVisualizarContainer.current.clientWidth - 32
                    : 300
                    }px)`,
            }}
                src={moldura.url}
                ref={imageRef}
            />
            <AvatarEditor
                ref={editorRef}
                image={String(foto)}
                width={editorWidth}
                height={editorHeight}
                border={1}
                color={usarTemaPreto ? [66, 66, 66, 1] : [255, 255, 255, 1]} // RGBA
                scale={zoom}
                rotate={rotacao}
                borderRadius={moldura.border ? 1000 : 0}
            />
            <br />
        </CardContent>
    </Card>
}
export default EditorFotos;