import { Chip, Fab, MenuItem, Select, TextField } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useContext } from "react";
import contextoTags from "../../../contextos/tags";
import contextoUsuarios, { ProvedorUsuarios } from "../../../contextos/usuarios";
import tipoUsuarioTexto from "../../../funcoes/tipoUsuarioTexto";
import Carbela from "../../../templates/Carbela";


const UsarAbaUsuarios = () => {
    const { estado, acoes } = useContext(contextoUsuarios);
    const Tags = useContext(contextoTags).estado.Tags;

    const colunas = [
        ["Nome", ""],
        ["Usuário", ""],
        ["Tipo", "120px"],
        ["Tags", "300px"],
        ["Ações", ""]
    ];

    const linhas = estado.Usuarios.map(usuario => {
        return [
            usuario.Nome,
            "@" + usuario.Usuario,
            <Select fullWidth value={usuario.tipo_usuario_ID} onChange={(e) => {
                acoes.definirEstado({
                    ...estado,
                    Usuarios: estado.Usuarios.map(
                        nUsuario => nUsuario.ID === usuario.ID ? {
                            ...nUsuario,
                            tipo_usuario_ID: Number(e.target.value)
                        } : nUsuario
                    )
                });
            }}>
                {[{
                    ID: 1,
                    Nome: "Editor"
                },
                {
                    ID: 2,
                    Nome: "Leitor"
                },
                {
                    ID: 3,
                    Nome: "Certificado"
                }].map(item => (
                    <MenuItem value={item.ID}>{item.Nome}</MenuItem>
                ))}
            </Select>,
            <Autocomplete
                fullWidth
                multiple
                id="tags"
                options={Tags.map(tag => ({ ID: tag.ID, Nome: tag.Nome }))}
                getOptionLabel={(tag) => tag.Nome}
                value={usuario.Tags.map(tag => ({ ID: tag.tag_ID, Nome: tag.Nome }))}
                onChange={(_, v) => {
                    acoes.definirEstado({
                        ...estado,
                        Usuarios: estado.Usuarios.map(novoUsuario => novoUsuario.ID === usuario.ID ? {
                            ...novoUsuario,
                            Tags: v.map(tag => ({ ...estado, tag_ID: tag.ID, Nome: tag.Nome, usuario_ID: 0 }))
                        } : novoUsuario)
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                    />
                )}
                ChipProps={{ size: "small" }}
                ListboxProps={{ fullWidth: true }}
            />,
            <Fab size="small" onClick={() => {
                acoes.salvarUsuario(usuario.ID);
            }}>
                <Save />
            </Fab>
        ]
    });

    return <>
        <div style={{ overflowY: 'scroll', height: 'calc(80vh)' }}>
            <Carbela linhas={linhas} colunas={colunas} larguraMaxima={700} />
        </div>
    </>
}

export const AbaUsuarios = () => {
    return <ProvedorUsuarios>
        <UsarAbaUsuarios />
    </ProvedorUsuarios>
}

export default AbaUsuarios;