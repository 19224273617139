interface DadosCompartilhamento {
    title: string,
    text: string,
    url: string,
}

export const compartilharNoticia = async (noticia: DadosCompartilhamento) => {
    try {
        await navigator.share(noticia)
    } catch (err) {
        //console.log(err);
    }
}

export default compartilharNoticia;