import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumAutenticacaoSegurancaSolicitar {
    tipoAlteracao: "AlterarSenha" | "AlterarEmail" | "ExcluirConta";
    email?: string;
}

export interface RespostaComumAutenticacaoSegurancaSolicitar {
    Status: boolean;
    Mensagem: string;
}

export const comumAutenticacaoSegurancaSolicitar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/autenticacao/seguranca/solicitar", dados);
}

export default comumAutenticacaoSegurancaSolicitar