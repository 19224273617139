import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorEmailAlterar {
	novoEmail: string;
}

export interface RespostaLeitorEmailAlterar {
    Status: boolean;
    Mensagem: string;
}

export const leitorEmailAlterar = async (buscador: AxiosInstance, dados: RequisicaoLeitorEmailAlterar): Promise<AxiosResponse> => {
    return buscador.post("/leitor/email/alterar", dados);
}

export default leitorEmailAlterar;