import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumTagsListar {
    Tipo: string;
    Pagina: number;
    Limite: number;
    Termo: string;
}

interface Tag {
    ID: number,
    Nome: string,
    Slug: string,
    Capa: string,
    Quantidade: number,
    Seguindo: boolean,
    Mostrar: boolean,
}

interface Dados {
    Tags: Tag[];
}

export interface RespostaComumTagsListar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumTagsListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/tags/listar", dados);
}

export default comumTagsListar;