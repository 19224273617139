import { AxiosInstance, AxiosResponse } from "axios";

interface Reacao {
    Tipo: "comentario" | "reacao",
    ID: number,
    Linha1: string,
    Linha2: string,
    Slug: string,
    Capa: string;
    DataHora: string;
}

export interface RespostaLeitorReacoesListar {
    Status: boolean;
    Dados: Reacao[];
    Mensagem: string;
}

export const leitorReacoesListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/reacoes/listar", dados);
}

export default leitorReacoesListar
