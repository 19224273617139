import { Container, Divider, Grid, LinearProgress, Typography } from "@material-ui/core";
import { useContext } from "react";
import { useParams } from "react-router";
import { ProvedorComentarios } from "../../contextos/comentarios";
import { contextoNoticia, ProvedorNoticia } from "../../contextos/noticia";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import Noticias from "../../templates/Noticias";
import { Cartao } from "./templates/Cartao";
import Comentarios from "./templates/Comentarios";
import TagsLista from "../../templates/TagsLista";
import Resenha from "./templates/Resenha";

const UsarVerNoticia = () => {
    const { estado } = useContext(contextoNoticia);
    if (estado.Carregado && estado.Dados) {
        const noticia = estado.Dados.Noticia;
        return <>
            <br />
            <Cartao />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {(noticia.noticia_confiabilidade_ID !== 1 && noticia.Resenha) && <Resenha />}
                    <Comentarios />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5">Tags em Destaque</Typography>
                    <TagsLista tipo="destaques" />
                </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Typography variant="h6">Veja mais notícias</Typography>
            <Noticias estadoPersonalizado={
                {
                    Limite: 15,
                    Pagina: 1,
                    Termo: '',
                    TagsIDs: [],
                    Ordem: "Popularidade",
                    Seguindo: false,
                    Pendente: false,
                    usuario_ID: undefined,
                    tipo: "Tudo"
                }
            } />
        </>
    }
    return <><br /><br /><LinearProgress /></>
}

const VerNoticia = () => {
    const params: { slug: string } = useParams();
    return <>
        <ProvedorNoticia Slug={params.slug}>
            <ProvedorComentarios noticia_Slug={params.slug}>
                <BarraAplicativoSecundaria />
                <Container maxWidth={"md"}>
                    <UsarVerNoticia />
                </Container>
            </ProvedorComentarios>
        </ProvedorNoticia>
    </>
};

export default VerNoticia;