import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumNoticiasListar {
    Limite: number;
    Pagina: number;
    Termo: string;
    TagsIDs: any[];
    Ordem: string;
    Seguindo: boolean;
    Pendente: boolean;
    usuario_ID?: number;
    tipo?: "Tudo" | "Fato" | "Fake";
    tipo_reacao_ID?: 1 | 2 | 3;
}

export interface Tag {
    ID: number;
    Slug: string;
    Nome: string;
}



export interface Comentario {
    ID: number;
    noticia_ID: number;
    usuario_ID: number;
    usuario_Usuario: string;
    usuario_Foto: string;
    tipo_usuario_ID: number,
    Comentario: string;
    comentario_ID?: any;
    DataHora: string;
    tipo_reacao_ID: number;
    Likes: number;
    Deslikes: number;
}

interface Link {
    ID: number,
    Link: string,
}

export interface Dado {
    ID: number;
    Titulo: string;
    Link: string;
    Slug: string;
    Resenha: string;
    Texto: string;
    usuario_Usuario: string;
    usuario_Nome: string;
    usuario_Foto: string;
    usuario_Premium: boolean;
    editor_Usuario: string,
    editor_Nome: string,
    editor_Foto: string,
    noticia_confiabilidade_ID: number,
    noticia_confiabilidade_Nome: string,
    Capa: string;
    DataHora: string;
    DataHoraVerificacao: string;
    Tags: Tag[];
    Links: Link[];
    tipo_reacao_ID: number;
    Likes: number;
    Deslikes: number;
    TotalComentarios: number;
    Comentarios: Comentario[];
}

export interface RespostaComumNoticiasListar {
    Status: boolean;
    Dados: Dado[];
    Mensagem: string;
}

export const comumNoticiasListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/noticias/listar", dados);
}

export default comumNoticiasListar
