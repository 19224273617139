import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumNoticiasVer {
    ID?: number;
    Slug?: string;
}

interface Noticia {
    ID: number;
    Titulo: string;
    Link: string;
    Slug: string;
    Resenha: string;
    Texto: string;
    usuario_Usuario: string;
    usuario_Nome: string;
    usuario_Foto: string;
    usuario_Premium: boolean;
    editor_Usuario: string,
    editor_Nome: string,
    editor_Foto: string,
    noticia_confiabilidade_ID: number,
    noticia_confiabilidade_Nome: string,
    Capa: string;
    DataHora: string;
    DataHoraVerificacao: string;
    tipo_reacao_ID: number;
    Likes: number;
    Deslikes: number;
    TotalComentarios: number;
}

interface Tag {
    ID: number;
    Nome: string;
    Slug: string;
}

interface Link {
    ID: number;
    Link: string;
}

interface Dados {
    Noticia: Noticia;
    Tags: Tag[];
    Links: Link[];
}

export interface RespostaComumNoticiasVer {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumNoticiasVer = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/noticias/ver", dados);
}

export default comumNoticiasVer