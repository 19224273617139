import { List, ListItem, ListItemText, Typography, ListItemAvatar, Avatar, Divider, CardActionArea } from "@material-ui/core";
import { List as IconeLista } from "@material-ui/icons";
import { useContext, Fragment } from "react";
import { useHistory } from "react-router";
import { contextoNoticias } from "../../contextos/noticias";

const ListaNoticias = () => {
    const noticias = useContext(contextoNoticias);
    const history = useHistory();
    const verNoticia = (slugNoticia: string) => {
        history.push(`/noticia/${slugNoticia}`);
    }

    return <>
        <List>
            {noticias.estado.Noticias.map(noticia => {
                return <Fragment key={noticia.ID}>
                    <CardActionArea onClick={() => {
                        verNoticia(noticia.Slug);
                    }}>
                        <ListItem>
                            <ListItemText primary={
                                <Typography noWrap>
                                    {noticia.Titulo}
                                </Typography>
                            } secondary={
                                <Typography noWrap>
                                    Postado {noticia.DataHora} • {noticia.Texto}
                                </Typography>
                            } />
                            <ListItemAvatar>
                                <Avatar variant="square" src={noticia.Capa} >
                                    <IconeLista />
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                    </CardActionArea>
                    <Divider variant="fullWidth" component="li" />
                </Fragment>
            })}
        </List>
    </>
}

export default ListaNoticias;