type Estado = {
    ID: number,
    tipo: string,
    mensagem: string,
    dispensado: boolean
    funcoes?: {
        Nome: string,
        Funcao: () => void
    }[],
};

type Despacho = {
    acao: 'NovaMensagem',
    dados: Omit<Estado, 'ID' | 'dispensado'>
} | {
    acao: 'DispensarMensagem',
    dados: Pick<Estado, 'ID'>
};

export const redutorMensagens = (estado: Estado[], despacho: Despacho): Estado[] => {
    switch (despacho.acao) {
        case 'NovaMensagem': return [...estado, {
            ID: new Date().getTime(),
            dispensado: false,
            ...despacho.dados
        }]
        case 'DispensarMensagem': return estado.filter(e => e.ID !== despacho.dados.ID)
        default: return estado
    }
}