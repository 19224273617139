import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumComentariosRespostasListar {
    comentario_ID: number;
    Pagina: number;
    Limite: number;
}

interface Resposta {
    ID: number;
    usuario_ID: number;
    usuario_Usuario: string;
    usuario_Foto: string;
    tipo_usuario_ID: number;
    Comentario: string;
    DataHora: string;
    tipo_reacao_ID: number;
    Likes: number;
    Deslikes: number;
    Prioridade: number;
}

interface Dados {
    Respostas: Resposta[];
}

export interface RespostaComumComentariosRespostasListar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumComentariosRespostasListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/comentarios/respostas/listar", dados);
}

export default comumComentariosRespostasListar