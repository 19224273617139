import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import contextoAutenticacao from "../../contextos/autenticacao";

export const ConfirmarContaDialogo = () => {
    const { estado, acoes } = useContext(contextoAutenticacao);
    const [tipo, definirTipo] = useState<"confirmacao" | "trocarEmail">("confirmacao");
    const mudarTipo = () => {
        definirTipo(tipo === "confirmacao" ? "trocarEmail" : "confirmacao");
    };
    const [codigo, definirCodigo] = useState("");
    const [novoEmail, definirNovoEmail] = useState(estado.Email);
    const confirmarClique = async () => {
        await acoes.confirmarEmail({codigoVerificacao: codigo});
    }
    const trocarEmailClique = async () => {
        if(!novoEmail) {
            return;
        }
        const sucesso = await acoes.alterarEmail({novoEmail});
        //console.log(sucesso);
        if(sucesso) {
            definirTipo("confirmacao");
        }
    }
    return <Dialog fullWidth open={estado.EmailConfirmado === false}>
        <DialogTitle>{tipo === "confirmacao" ? "Confirme seu E-mail" : "Trocar e-mail"}</DialogTitle>
        <DialogContent>
            {tipo === "confirmacao" && <>
                Enviamos um código para {estado.Email}. <Button onClick={mudarTipo} size="small" variant="contained">Não é seu E-mail?</Button><br /><br />
                <TextField label="Digite o código" variant="outlined" fullWidth value={codigo} onChange={(e)=>definirCodigo(e.target.value)} /><br /><br />
                <Button fullWidth variant="contained" onClick={confirmarClique}>Enviar</Button><br /><br />
            </>}
            {tipo === "trocarEmail" && <>
                <TextField type="email" label="E-mail" variant="outlined" fullWidth value={novoEmail} onChange={(e)=>definirNovoEmail(e.target.value)} /> <br /><br />
                <Button fullWidth variant="contained" onClick={trocarEmailClique}>Trocar</Button><br /><br />
            </>}
        </DialogContent>
    </Dialog>
}