import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import comumComentariosListar, { RequisicaoComumComentariosListar, RespostaComumComentariosListar } from "../servicos/comumComentariosListar";
import comumComentariosRespostasListar, { RequisicaoComumComentariosRespostasListar, RespostaComumComentariosRespostasListar } from "../servicos/comumComentariosRespostasListar";
import leitorComentariosAdicionar, { RequisicaoLeitorComentariosAdicionar, RespostaLeitorComentariosAdicionar } from "../servicos/leitorComentariosAdicionar";
import leitorComentariosExcluir, { RequisicaoLeitorComentariosExcluir, RespostaLeitorComentariosExcluir } from "../servicos/leitorComentariosExcluir";
import leitorComentariosReacaoAtualizar, { RequisicaoLeitorComentariosReacaoAtualizar, RespostaLeitorComentariosReacaoAtualizar } from "../servicos/leitorComentariosReacaoAtualizar";
import contextoAutenticacao from "./autenticacao";
import { contextoNoticia } from "./noticia";
import { contextoServico } from "./servicos";


interface Estado extends RequisicaoComumComentariosListar {
    Comentarios: RespostaComumComentariosListar['Dados']['Comentarios'],
    Respostas: RespostaComumComentariosListar['Dados']['Respostas'],
    PaginacaoRespostas: { [comentario_ID: number]: number }
}

interface ContextoComentarios {
    estado: Estado;
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        carregar: () => Promise<void>;
        carregarRespostas: (comentario_ID: number) => Promise<void>;
        reacao: (comentario_ID: number) => Promise<void>;
        excluir: (requisicao: RequisicaoLeitorComentariosExcluir) => Promise<boolean>;
        comentar: (requisicao: RequisicaoLeitorComentariosAdicionar) => Promise<boolean>;
        mostrarMais: () => void;
    }
}

export const contextoComentarios = createContext({} as ContextoComentarios);

export const ProvedorComentarios = ({ children, noticia_Slug }: { children: ReactNode, noticia_Slug: string }) => {

    const estadoPadrao: Estado = {
        Limite: 10,
        Pagina: 1,
        noticia_Slug,
        Comentarios: [],
        Respostas: [],
        PaginacaoRespostas: {}
    }

    const [estado, definirEstado] = useState(estadoPadrao);
    const { usarRota } = useContext(contextoServico);
    const usuario = useContext(contextoAutenticacao).estado;

    const { estado: estadoNoticia, acoes: acoesNoticia } = useContext(contextoNoticia);

    const acoes = {
        definirEstado,
        carregar: async () => {
            const requisicao: RequisicaoComumComentariosListar = {
                noticia_Slug,
                Limite: estado.Limite,
                Pagina: estado.Pagina,
            };
            const resposta: RespostaComumComentariosListar = await usarRota(comumComentariosListar, requisicao);
            if (resposta.Status) {
                definirEstado({ ...estado, Respostas: [...estado.Respostas, ...resposta.Dados.Respostas], Comentarios: [...estado.Comentarios, ...resposta.Dados.Comentarios] });
            }
        },
        carregarRespostas: async (comentario_ID: number) => {
            const Pagina = estado.PaginacaoRespostas[comentario_ID] || 2;
            const requisicao: RequisicaoComumComentariosRespostasListar = {
                comentario_ID,
                Pagina,
                Limite: 10,
            };
            const resposta: RespostaComumComentariosRespostasListar = await usarRota(comumComentariosRespostasListar, requisicao);
            if (resposta.Status) {
                const Respostas = resposta.Dados.Respostas.map(resposta => ({ ...resposta, comentario_ID }));// { ...resposta.Dados.Respostas, comentario_ID };
                definirEstado({
                    ...estado,
                    Respostas: [...estado.Respostas, ...Respostas],
                    PaginacaoRespostas: {
                        ...estado.PaginacaoRespostas,
                        [comentario_ID]: Pagina + 1
                    }
                });
            }
        },
        reacao: async (comentario_ID: number) => {

            let antigo_tipo_reacao_ID =
                estado.Comentarios.find(comentario => comentario.ID === comentario_ID)?.tipo_reacao_ID ||
                estado.Respostas.find(resposta => resposta.ID === comentario_ID)?.tipo_reacao_ID
                || 1;


            let tipo_reacao_ID = antigo_tipo_reacao_ID === 2 ? 1 : 2;

            const requisicao: RequisicaoLeitorComentariosReacaoAtualizar = { comentario_ID, reacao_tipo_ID: tipo_reacao_ID };
            const resposta: RespostaLeitorComentariosReacaoAtualizar = await usarRota(leitorComentariosReacaoAtualizar, requisicao);

            if (resposta.Status) {
                definirEstado({
                    ...estado,
                    Comentarios: estado.Comentarios.map(comentario => comentario.ID === comentario_ID ? { ...comentario, tipo_reacao_ID, Likes: comentario.Likes + (tipo_reacao_ID === 1 ? -1 : +1) } : comentario),
                    Respostas: estado.Respostas.map(resposta => resposta.ID === comentario_ID ? { ...resposta, tipo_reacao_ID } : resposta)
                });
            }
        },
        excluir: async (requisicao: RequisicaoLeitorComentariosExcluir) => {
            const resposta: RespostaLeitorComentariosExcluir = await usarRota(leitorComentariosExcluir, requisicao);
            if (resposta.Status) {

                const totalComentarios = estado.Comentarios.length + estado.Respostas.length;
                const comentariosFiltrado = estado.Comentarios.filter(
                    comentario => comentario.ID !== requisicao.comentario_ID
                );
                const respostasFiltrado = estado.Respostas.filter(
                    resposta =>
                        resposta.ID !== requisicao.comentario_ID &&
                        resposta.comentario_ID !== requisicao.comentario_ID
                );
                const comentariosApagados = totalComentarios - (comentariosFiltrado.length + respostasFiltrado.length);

                definirEstado({
                    ...estado,
                    Comentarios: comentariosFiltrado,
                    Respostas: respostasFiltrado,
                });

                estadoNoticia && estadoNoticia.Dados && acoesNoticia.definirEstado({
                    ...estadoNoticia,
                    Dados: {
                        ...estadoNoticia.Dados,
                        Noticia: {
                            ...estadoNoticia.Dados.Noticia,
                            TotalComentarios: estadoNoticia.Dados.Noticia.TotalComentarios - comentariosApagados
                        }
                    }
                });

                return true;
            }
            return false;
        },
        comentar: async (requisicao: RequisicaoLeitorComentariosAdicionar) => {
            const resposta: RespostaLeitorComentariosAdicionar = await usarRota(leitorComentariosAdicionar, requisicao);
            if (resposta.Status) {
                if (requisicao.comentario_ID) {
                    definirEstado({
                        ...estado,
                        Respostas: [
                            ...estado.Respostas,
                            {
                                ...requisicao,
                                ID: resposta.Dados.ID,
                                usuario_ID: Number(usuario.usuario_ID),
                                usuario_Foto: String(usuario.Foto),
                                usuario_Usuario: String(usuario.Nome),
                                tipo_usuario_ID: Number(usuario.tipo_usuario_ID),
                                DataHora: 'agora',
                                tipo_reacao_ID: 1,
                                Likes: 0,
                                Deslikes: 0,
                                comentario_ID: requisicao.comentario_ID || 0,
                                Prioridade: 1,
                            },
                        ],
                    });

                } else {
                    definirEstado({
                        ...estado,
                        Comentarios: [
                            {
                                Comentario: requisicao.Comentario,
                                DataHora: 'Agora',
                                Likes: 0,
                                Deslikes: 0,
                                ID: resposta.Dados.ID,
                                TotalRespostas: 0,
                                tipo_reacao_ID: 1,
                                usuario_Foto: String(usuario.Foto),
                                usuario_ID: Number(usuario.usuario_ID),
                                usuario_Usuario: String(usuario.Usuario),
                                tipo_usuario_ID: Number(usuario.tipo_usuario_ID),
                                Prioridade: 1
                            },
                            ...estado.Comentarios
                        ]
                    });
                }
                estadoNoticia.Dados && acoesNoticia.definirEstado({ ...estadoNoticia, Dados: { ...estadoNoticia.Dados, Noticia: { ...estadoNoticia.Dados.Noticia, TotalComentarios: estadoNoticia.Dados.Noticia.TotalComentarios + 1 } } })
                return true;
            }
            return false;
        },
        mostrarMais: () => {
            definirEstado({ ...estado, Pagina: Number(estado.Pagina) + 1 });
        }
    };

    useEffect(() => {
        acoes.carregar();
    }, [estado.Pagina]);

    const valores = {
        estado,
        acoes
    };

    return <contextoComentarios.Provider value={valores as ContextoComentarios}>
        {children}
    </contextoComentarios.Provider>
}

export default contextoComentarios;