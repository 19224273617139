import { Typography, ListItem, ListItemText, ListItemSecondaryAction, Button, Collapse, ListItemIcon, List } from "@material-ui/core";
import { ExpandMore, Link } from "@material-ui/icons";
import { useContext, useState } from "react";
import { contextoNoticia } from "../../../contextos/noticia";
import se from "../../../funcoes/se";

export const Resenha = () => {

    const { estado } = useContext(contextoNoticia);
    const { Dados } = estado;
    const noticia = Dados?.Noticia;
    const links = Dados?.Links;
    const tipo = noticia?.noticia_confiabilidade_ID;
    const [aberto, definirAberto] = useState(false);

    if (!noticia) {
        return <></>
    }

    return <>
        <Typography variant="h5">Resenha do Editor</Typography>
        <List>
            <ListItem style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <Typography variant="body1" style={{ whiteSpace: aberto ? "pre-line" : "nowrap" }} noWrap>
                    {noticia.Resenha ? noticia.Resenha : tipo === 1 && "A equipe do Notícia Comprovada está trabalhando para determinar a confiabilidade desta postagem."}
                </Typography>
            </ListItem>
            <Collapse in={aberto}>
                {links?.map(link => {
                    return <ListItem key={link.ID} button onClick={() => {
                        window.open(link.Link, '_blank');
                    }}>
                        <ListItemIcon>
                            <Link />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography variant="subtitle2" noWrap>
                                {link.Link}
                            </Typography>
                        } />
                    </ListItem>
                })}
            </Collapse>
            <Button fullWidth variant="outlined" startIcon={
                <ExpandMore style={{ transform: se(aberto, 'rotate(180deg)'), transitionDuration: '0.3s', transitionProperty: 'transform' }} />
            } onClick={() => definirAberto(!aberto)} size="small" style={{ minWidth: '0px' }} >
                {aberto ? "Mostrar Menos" : "Mostrar Mais"}
            </Button>
        </List>
        <br/>
    </>
}

export default Resenha