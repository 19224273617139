import { Button, Card, CardContent, LinearProgress, Typography, useMediaQuery } from "@material-ui/core";
import { SentimentDissatisfiedOutlined } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useContext } from "react"
import { contextoNoticias, EstadoPersonalizado, ProvedorNoticias } from "../../contextos/noticias"
import Anuncio from "../Anuncio";
import NoticiaCartao from "../NoticiasCartao";
import TagsLista from "../TagsLista";

const UsarNoticias = () => {
    const { estado, acoes } = useContext(contextoNoticias);
    const movel = useMediaQuery('(max-width:600px)');
    if (estado.Noticias.length === 0 && !estado.carregando) {
        return <>
            <br />
            <Card>
                <CardContent style={{ textAlign: 'center' }}>
                    <br />
                    <SentimentDissatisfiedOutlined /> <br />
                    Não há notícias para mostrar
                    <br />
                </CardContent>
            </Card>
            <br />
            <Typography variant="h5">Tags em Destaque</Typography>
            <TagsLista tipo="destaques" />
        </>
    }
    return <>
        <br />
        <ToggleButtonGroup exclusive value={estado.Ordem} onChange={(e, v) => { v != null && acoes.trocarOrdem(v) }} size="small" style={{ width: "100%", height: '25px' }}>
            <ToggleButton value={"Popularidade"} style={{ width: "100%" }}>POPULARES</ToggleButton>
            <ToggleButton value={"Recentes"} style={{ width: "100%" }}>RECENTES</ToggleButton>
        </ToggleButtonGroup>
        {estado.Noticias.map((noticia, index) => {
            return <div key={noticia.ID}>
                <br />
                <NoticiaCartao noticia_ID={noticia.ID} />
                {(index % 8 === 0) && <>
                    {movel && <Anuncio anuncio_posicao_ID={1} />}
                    {!movel && <Anuncio anuncio_posicao_ID={3} />}
                </>}
            </div>
        })}
        {estado.maisDisponivel && !estado.carregando && <><br /><Button onClick={acoes.mostrarMais} fullWidth variant="outlined">Carregar Mais</Button><br /></>}
        {estado.carregando && <><br /><br /><LinearProgress /></>}
        <br />
    </>
}

export const Noticias = ({ estadoPersonalizado }: { estadoPersonalizado?: EstadoPersonalizado | undefined }) => {
    //console.log(estadoPersonalizado);
    return <ProvedorNoticias estadoPersonalizado={estadoPersonalizado}>
        <UsarNoticias />
    </ProvedorNoticias>
}

export default Noticias