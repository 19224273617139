import { DialogContent, TextField, Card, CardMedia, CardContent, Typography, DialogActions, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useContext, useEffect } from "react";
import { contextoAplicacao } from "../../../contextos/aplicacao";
import contextoPublicacao from "../../../contextos/publicacao";
import contextoTags from "../../../contextos/tags";

export const Whatsapp = () => {

    const { estado: estadoTags } = useContext(contextoTags);
    const { estado, acoes } = useContext(contextoPublicacao);
    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
    useEffect(() => {
        return () => {
            acoes.limpar();
        }
    }, []);
    return <>
        <DialogContent>
            <TextField multiline minRows={6} value={estado.Texto} onChange={e => acoes.definirEstado({ ...estado, Texto: e.target.value })} InputLabelProps={{ style: { color: "#858585" } }} variant="filled" label="Cole a mensagem do whatsapp aqui." fullWidth />
            <br /><br />
            <TextField value={estado.Comentario} onChange={e => acoes.definirEstado({ ...estado, Comentario: e.target.value })} InputLabelProps={{ style: { color: "#858585" } }} variant="standard" label="Comentário (opcional)" fullWidth />
            <br /><br />
            <Autocomplete
                multiple
                id="tags-standard"
                options={estadoTags.Tags}
                getOptionLabel={(tag) => tag.Nome}
                value={estadoTags.Tags.filter(tag => estado.Tags_IDs.includes(tag.ID))}
                onChange={(_, value) => acoes.definirEstado({ ...estado, Tags_IDs: value.map(tag => tag.ID) })}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tags"
                        placeholder="Pesquisar por Tags..."
                    /> 
                )}
            />
            <br />
        </DialogContent>

        <DialogActions>
            <Button onClick={() => acoesAplicacao.definirEstado({ ...estadoAplicacao, adicionarAberto: false })}>Cancelar</Button>
            <Button onClick={() => acoes.publicarZap()}>Enviar</Button>
        </DialogActions>
    </>
}

export default Whatsapp;