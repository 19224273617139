import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';


const writeAndOpenFile = async (data: Blob, fileName: string) => {
    var reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async function () {
        var base64data = reader.result;
        try {
            const result = await Filesystem.writeFile({
                path: fileName,
                data: <string>base64data,
                directory: Directory.Data,
                recursive: true
            });
            FileOpener.open(result.uri, data.type)
                .then(() => console.log('File is opened'))
                .catch(e => console.log('Error opening file', e));
            console.log('Wrote file', result.uri);
        } catch (e) {
            console.error('Unable to write file', e);
        }
    }
}

export const baixarArquivo = async (href: string, arquivo: string) => {
    const platform = Capacitor.getPlatform();
    if(platform === 'web') {
        const a = document.createElement('a');
        a.href = href;
        a.download = arquivo;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
    }
    else {
        const data = await (await fetch(href)).blob();
        await writeAndOpenFile(data, arquivo);
    }
}