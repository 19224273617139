import { useContext } from "react";
import contextoAutenticacao from "../../../contextos/autenticacao";
import { ProvedorNoticias } from "../../../contextos/noticias";
import ListaNoticias from "../../../templates/ListaNoticias";

export const Postagens = () => {

    const { estado } = useContext(contextoAutenticacao);

    return <>
        <ProvedorNoticias key={"PublicacoesLeitor"} estadoPersonalizado={{
            Limite: 15,
            Pagina: 1,
            Termo: '',
            TagsIDs: [],
            Ordem: "Recentes",
            Seguindo: false,
            Pendente: false,
            usuario_ID: estado.usuario_ID,
        }}>
            <ListaNoticias/>
        </ProvedorNoticias>
    </>
}

export default Postagens;