import { Button, Card, CardContent, Container, List, ListItem, TextField, Typography } from "@material-ui/core";
import { ChevronLeft, Search } from "@material-ui/icons";
import { Fragment, useState } from "react"
import { useHistory } from "react-router";
import { ProvedorNoticias } from "../../contextos/noticias";
import ListaNoticias from "../../templates/ListaNoticias";
import TagsLista from "../../templates/TagsLista";

export const Pesquisa = () => {

    const history = useHistory();

    const [termoCampo, definirTermoCampo] = useState("");

    const [termoPesquisa, definirTermoPesquisa] = useState("");

    const acaoVoltar = () => {
        history.goBack();
    }

    const acaoPesquisar = () => {
        definirTermoPesquisa(termoCampo);
    }

    return <Fragment>
        <br />
        <Container>
            <TextField onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    acaoPesquisar();
                }
            }} fullWidth autoFocus color="primary" variant="outlined" value={termoCampo} onChange={e => definirTermoCampo(e.target.value)} InputProps={{
                startAdornment: <><Button onClick={acaoVoltar} color="inherit"><ChevronLeft color="inherit" /></Button></>,
                endAdornment: <><Button onClick={acaoPesquisar} color="default"><Search color="inherit" /></Button></>
            }} InputLabelProps={{ style: { color: "#858585" } }} />
            {termoPesquisa ? <Fragment>
                <List>
                    <ListItem>
                        <Typography noWrap>Exibindo resultados de pesquisa para `{termoPesquisa}`</Typography>
                    </ListItem>
                </List>
                <TagsLista tipo="pesquisa" termo={termoPesquisa} />
                <ProvedorNoticias key={"Pesquisando" + termoPesquisa} estadoPersonalizado={{
                    Limite: 15,
                    Pagina: 1,
                    Termo: termoPesquisa,
                    TagsIDs: [],
                    Ordem: "Recentes",
                    Seguindo: false,
                    Pendente: false,
                    usuario_ID: undefined,
                    tipo: "Tudo"
                }}>
                    <ListaNoticias />
                </ProvedorNoticias>
            </Fragment> : <Fragment>
                <br /><br /><Card>
                    <CardContent>
                        <br /><br />
                        Digite um termo e clique sobre a lupa para pesquisar...
                        <br /><br />
                    </CardContent>
                </Card> <br />
                <Typography variant="h5">Tags em Destaque</Typography>
                <TagsLista tipo="destaques" />
            </Fragment>}
        </Container>
    </Fragment>
}