import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumCadastrar {
    Nome: string;
    Senha: string;
    Email: string;
    Usuario: string;
    Bio?: string;
    Foto?: string;
}

export interface RespostaComumCadastrar {
    Status: boolean;
    Mensagem: string;
}

export const comumCadastrar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/cadastrar", dados);
}

export default comumCadastrar