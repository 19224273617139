import { Dialog, DialogContentText } from "@material-ui/core"
import { Close } from "@material-ui/icons";
import { useContext } from "react"
import { contextoAplicacao } from "../contextos/aplicacao";
import contextoAutenticacao from "../contextos/autenticacao";
import BarraMensagens from "../templates/BarraMensagens";
import PoliticaCookies from "../templates/PoliticaCookies";
import { AdicionarNoticia } from "./templates/AdicionarNoticia";
import AutenticacaoCadastrar from "./templates/AutenticacaoCadastrar";
import AutenticacaoEntrar from "./templates/AutenticacaoEntrar";
import AutenticacaoPerfil from "./templates/AutenticacaoPerfil";
import { BaixarApp } from "./templates/BaixarApp";
import { ConfirmarContaDialogo } from "./templates/ConfirmarContaDialogo";
import PoliticaPrivacidade from "./templates/PoliticaPrivacidade";

export const AutenticacaoDialogo = () => {
    const { estado, acoes } = useContext(contextoAplicacao);
    const { estado: estadoAutenticacao } = useContext(contextoAutenticacao);

    return <Dialog scroll="paper" fullWidth open={estado.loginAberto} onClose={() => acoes.definirEstado({ ...estado, loginAberto: false })}>
        <>
            <div style={{
                position: 'absolute',
                margin: '10px',
                right: '0px'
            }} onClick={() => acoes.definirEstado({ ...estado, loginAberto: false })}>
                <Close style={{ fontSize: '30px', color: '#858585' }} />
            </div>
            {
                (estadoAutenticacao.autenticado)
                    ? (
                        <AutenticacaoPerfil/>
                    )
                    : (
                        estado.tipoLogin === "Entrar"
                            ? <AutenticacaoEntrar />
                            : <AutenticacaoCadastrar />
                    )
            }
        </>
    </Dialog>
}

export const AdicionarNoticiaDialogo = () => {
    const { estado, acoes } = useContext(contextoAplicacao);
    return <Dialog fullWidth open={estado.adicionarAberto} onClose={() => acoes.definirEstado({ ...estado, adicionarAberto: false })}>
        <div style={{
            position: 'absolute',
            margin: '10px',
            right: '0px'
        }} onClick={() => acoes.definirEstado({ ...estado, adicionarAberto: false })}>
            <Close style={{ fontSize: '30px', color: '#858585' }} />
        </div>
        <AdicionarNoticia />
    </Dialog>
}

const PoliticaPrivacidadeDialogo = () => {
    const { estado, acoes } = useContext(contextoAplicacao);
    return <Dialog fullWidth open={estado.politicaAberto} onClose={() => acoes.definirEstado({ ...estado, politicaAberto: false })}>
        <div style={{
            position: 'absolute',
            margin: '10px',
            right: '0px'
        }} onClick={() => acoes.definirEstado({ ...estado, politicaAberto: false })}>
            <Close style={{ fontSize: '30px', color: '#858585' }} />
        </div>
        <PoliticaPrivacidade/>
    </Dialog>
}

export const Dialogos = () => {
    return <>
        <AutenticacaoDialogo />
        <AdicionarNoticiaDialogo />
        <PoliticaPrivacidadeDialogo/>
        <PoliticaCookies />
        <BarraMensagens />
        <ConfirmarContaDialogo/>
        <BaixarApp/>
    </>
}

export default Dialogos