import { ListItem, ListItemAvatar, Avatar, ListItemText, TextField, Button } from "@material-ui/core";
import { Close, Send } from "@material-ui/icons";
import { useContext, useRef, useState } from "react";
import contextoComentarios from "../../../contextos/comentarios";
import { contextoNoticia } from "../../../contextos/noticia";

interface Propriedades {
    popover?: boolean;
    comentario_ID?: number;
    arroba?: string;
    definirAberto?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ComentariosDigitacao = ({
    popover = false,
    comentario_ID = undefined,
    arroba = "",
    definirAberto = undefined
}: Propriedades) => {
    const noticia_ID = useContext(contextoNoticia).estado.Dados?.Noticia.ID || 0;
    const [caixaVisivel, definirCaixaVisivel] = useState(false);
    const [comentario, definirComentario] = useState("");
    const { acoes } = useContext(contextoComentarios);

    const eventoComentar = async () => {
        let comentarioTexto = comentario;
        if (arroba.length > 0) {
            comentarioTexto = `${arroba} ${comentario}`;
        }
        const comentado = await acoes.comentar({ noticia_ID, Comentario: comentarioTexto, comentario_ID });
        if (comentado) {
            definirCaixaVisivel(false);
            definirComentario("");
            definirAberto && definirAberto(false);
        }
    };

    const BotaoComentar = () => {
        return <Button style={{ minWidth: '0px' }} onClick={eventoComentar} variant={popover ? "text" : "contained"} size={popover ? "small" : "medium"}>{popover ? <Send /> : "Comentar"}</Button>
    }

    return <>
        <ListItem style={{ padding: '0px' }}>
            {!popover && <ListItemAvatar>
                <Avatar src=""></Avatar>
            </ListItemAvatar>}
            <ListItemText primary={
                <TextField
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            eventoComentar();
                        }
                    }}
                    InputProps={{
                        endAdornment: popover && <>
                            <Button style={{ minWidth: '0px' }} variant="text" onClick={() => {
                                definirAberto && definirAberto(false);
                            }}>
                                <Close />
                            </Button>
                            <BotaoComentar />
                        </>
                    }}
                    value={comentario} onChange={(e) => definirComentario(e.target.value)} onFocus={() => definirCaixaVisivel(true)} variant="standard" placeholder={popover ? "Adicionar uma resposta" : "Digite seu Comentário"} style={{ width: '100%' }} autoFocus={popover ? true : false} />
            } secondary={
                <div style={{ display: 'flex', marginTop: '5px' }}>
                    <div style={{ flexGrow: 1 }}></div>
                    {(caixaVisivel || popover) && <div>
                        {!popover &&
                            <>
                                <Button onClick={() => definirCaixaVisivel(false)}>Cancelar</Button>
                                <BotaoComentar />
                            </>
                        }

                    </div>}
                </div>
            } />
        </ListItem>
    </>
};

export default ComentariosDigitacao;