import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, FormControlLabel, FormLabel, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Radio, RadioGroup } from "@material-ui/core";
import { NightsStay, RadioButtonChecked, RadioButtonUnchecked, WbSunny } from "@material-ui/icons";
import { useContext } from "react";
import { contextoAplicacao } from "../../contextos/aplicacao";
import contextoConfiguracoes from "../../contextos/configuracoes";

export default function PoliticaCookies() {

  const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);
  const { estado: estadoConfiguracoes, acoes: acoesConfiguracoes } = useContext(contextoConfiguracoes);

  const acaoPrivacidade = () => {
    acoesAplicacao.definirEstado({ ...estadoAplicacao, politicaAberto: true });
  }

  const acaoAceitar = () => {
    acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, cookiesAceitos: true });
  };
  return (
    <div>
      <Drawer
        open={!estadoConfiguracoes.cookiesAceitos}
        anchor="bottom"
      >
        <List>
          <ListSubheader>Tema</ListSubheader>

          <ListItem button onClick={() => {
            acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, usarTemaPetro: false });
          }}>

            <ListItemIcon>
              {estadoConfiguracoes.usarTemaPetro ? <RadioButtonUnchecked /> : <RadioButtonChecked />}
            </ListItemIcon>
            <ListItemText>
              Tema Claro
            </ListItemText>
            <WbSunny />
          </ListItem>


          <ListItem button onClick={() => {
            acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, usarTemaPetro: true });
          }}>
            <ListItemIcon>
              {estadoConfiguracoes.usarTemaPetro ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
            </ListItemIcon>
            <ListItemText>
              Tema Escuro
            </ListItemText>
            <NightsStay />
          </ListItem>


          <ListSubheader>Cookies</ListSubheader>
          <ListItem>
            Nosso site usa cookies para melhorar sua experiência. Leia nossa política de privacidade para saber mais.
          </ListItem>

        </List>
        <DialogActions>
          <Button onClick={acaoPrivacidade}>
            Política de Privacidade
          </Button>
          <Button onClick={acaoAceitar}>
            Aceitar
          </Button>
        </DialogActions>
      </Drawer>
    </div>
  );
}