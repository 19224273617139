import { Avatar, Button, Card, CardActionArea, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { ThumbUpOutlined, ThumbDownOutlined, ChatBubbleOutlineOutlined, ShareOutlined, FavoriteBorder, SendRounded, ThumbUp, ThumbDown, Favorite } from "@material-ui/icons";
import { useContext } from "react"
import { useHistory } from "react-router";
import contextoConfiguracoes from "../../contextos/configuracoes";
import { contextoNoticias } from "../../contextos/noticias"
import baseURL from "../../funcoes/baseURL";
import compartilharNoticia from "../../funcoes/compartilharNoticia";
import percentualLikes from "../../funcoes/percentualLikes";
import ReprodutorIncorporado from "../ReprodutorIncorporado";
import estiloIndex from "./estilo";

export const NoticiaCartao = ({ noticia_ID }: { noticia_ID: number }) => {

    const history = useHistory();
    const { estado, acoes } = useContext(contextoNoticias);
    const { usarTemaPetro } = useContext(contextoConfiguracoes).estado;
    const noticia = estado.Noticias.filter(noticia => noticia.ID === noticia_ID)[0];

    const estilo = estiloIndex();

    const stopPropagation = (event: any) => event.stopPropagation();

    const acaoAreaAcao = () => {
        history.push('/noticia/' + noticia.Slug);
    }

    const tipo = noticia.noticia_confiabilidade_ID;

    return <Card>
        <CardActionArea onClick={acaoAreaAcao}>
            <Grid container>
                <Grid item xs={12} md={6} style={{
                    minHeight: "200px",
                    background: `url("${noticia.Capa}")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}>
                    <ReprodutorIncorporado link={noticia.Link}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" src={noticia.usuario_Foto}>
                                {noticia.usuario_Nome?.substr(0, 1)}
                            </Avatar>
                        }
                        action={
                            <>
                                {tipo === 1 && <img className={estilo.selo} src={`${baseURL("imagem")}/analise.svg`} />}
                                {tipo === 2 && <img className={estilo.selo} src={`${baseURL("imagem")}/indeterminada.svg`} />}
                                {tipo === 3 && <img className={estilo.selo} src={`${baseURL("imagem")}/verificada.svg`} />}
                                {tipo === 4 && <img className={estilo.selo} src={`${baseURL("imagem")}/falsa.svg`} />}
                                {tipo === 5 && <img className={estilo.selo} src={`${baseURL("imagem")}/opiniao.svg`} />}
                            </>
                        }
                        title={<>
                            {noticia.usuario_Nome} {noticia.usuario_Premium &&
                                <img src="/imagens/selecionado.png" />
                            }
                        </>}
                        subheader={
                            noticia.Tags.map(tag => {
                                return <Button size="small" style={{
                                    display: 'inline - block',
                                    width: 'auto',
                                    textAlign: 'left',
                                    paddingLeft: '0px',
                                    paddingRight: '3px',
                                    minWidth: '0px'
                                }} onTouchStart={stopPropagation} onMouseDown={stopPropagation} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    history.push("/tag/" + tag.Slug);
                                }}>
                                    <span style={{ fontWeight: 'bold' }}>#{tag.Nome}&nbsp;</span>
                                </Button>
                            })
                        }
                    />


                    <CardContent style={{ marginTop: '-15px', minHeight: '200px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px', fontWeight: 'bold' }}>
                            {/* <img style={{ width: '16px', height: '16px' }} src="https://s2.glbimg.com/nMQRSo35mM4QdMphrT4FsjQuMeM=/32x32/smart/filters:strip_icc()/s.glbimg.com/jo/g1/f/original/2018/05/23/favicon-g1.jpeg" />
                        &nbsp;&nbsp;g1.globo.com • 10d */}
                            {!noticia.Link.includes("api.whatsapp.com") && <>
                                {(new URL(noticia.Link)).host} •&nbsp;
                            </>}
                            {noticia.DataHora}
                        </div>
                        <Typography variant="h6" style={{ marginTop: '15px' }}>
                            {(() => {
                                let texto = noticia.Titulo || "";
                                if (texto.length > 140) {
                                    texto = texto.substring(0, 140);
                                    texto = texto.substring(0, texto.lastIndexOf(' ')) + '...';
                                }
                                return texto;
                            })()}
                            {/* <Button size="small" endIcon={<ChevronRight/>}>Ler Mais</Button> */}
                        </Typography>
                        <Grid style={{ marginTop: '15px' }} container>
                            <Grid item xs={3}>
                                <Button fullWidth size="small" startIcon={noticia.tipo_reacao_ID === 2 ? <ThumbUp /> : <ThumbUpOutlined />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); acoes.reacao(2, noticia.ID); }} onTouchStart={stopPropagation} onMouseDown={stopPropagation}>{noticia.Likes}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth size="small" startIcon={noticia.tipo_reacao_ID === 3 ? <ThumbDown /> : <ThumbDownOutlined />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); acoes.reacao(3, noticia.ID) }} onTouchStart={stopPropagation} onMouseDown={stopPropagation}>{noticia.Deslikes}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth size="small" startIcon={<ChatBubbleOutlineOutlined />} onTouchStart={stopPropagation} onMouseDown={stopPropagation}>{noticia.TotalComentarios}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth size="small" startIcon={<ShareOutlined />} onTouchStart={stopPropagation} onMouseDown={stopPropagation} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    compartilharNoticia({
                                        text: noticia.Texto,
                                        title: noticia.Titulo,
                                        url: "https://noticiaverificada.com/noticia/" + noticia.Slug
                                    });
                                }}>Enviar</Button>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '-13px' }}>
                                <div style={{ transition: 'width 0.3s', display: 'inline-block', background: usarTemaPetro ? '#eee' : 'gray', width: percentualLikes(noticia.Likes, noticia.Deslikes, "Like"), height: '2px' }}></div>
                                <div style={{ transition: 'width 0.3s', display: 'inline-block', background: usarTemaPetro ? 'gray' : '#eee', width: percentualLikes(noticia.Likes, noticia.Deslikes, "Deslike"), height: '2px' }}></div>
                            </Grid>
                        </Grid>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginTop: '15px'
                        }}>
                            {
                                noticia.Comentarios.filter((_, index) => index < 2).map(comentario => (
                                    <>
                                        <div style={{ flexGrow: 1, display: 'flex', width: '100%', marginBottom: '8px' }}>
                                            <Avatar style={{ width: '20px', height: '20px', fontSize: '10px', marginRight: '6px' }}>{comentario.usuario_Usuario.split('')[0]}</Avatar>
                                            <div>

                                                <Typography variant="body2" style={{ fontSize: '10px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>@{comentario.usuario_Usuario} </span>

                                                    {[1, 3].includes(comentario.tipo_usuario_ID) && <>
                                                        <img src="/imagens/selecionado.png" />
                                                    </>} • {comentario.DataHora}
                                                </Typography>

                                                {comentario.Comentario}

                                            </div>
                                        </div>
                                        {/* <div>
                                            {comentario.tipo_reacao_ID === 1 ? <ThumbUp style={{ width: '20px' }} /> : <ThumbUpOutlined style={{ width: '20px' }} />}
                                            {comentario.tipo_reacao_ID === 2 ? <ThumbDown style={{ width: '20px' }} /> : <ThumbDownOutlined style={{ width: '20px' }} />}
                                        </div> */}
                                    </>
                                ))
                            }
                        </div>
                    </CardContent>

                    <div style={{ borderTop: '1px solid #eee', padding: '10px', display: 'flex', alignItems: 'center', color: '#999' }}>
                        <div style={{ flexGrow: 1 }}>Digite seu comentário...</div>
                        <SendRounded />
                    </div>

                </Grid>
            </Grid>
        </CardActionArea>
    </Card >
}

export default NoticiaCartao