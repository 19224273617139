import { AxiosInstance, AxiosResponse } from "axios";

interface Tag {
    usuario_ID: number;
    tag_ID: number;
    Nome: string;
}

interface Usuario {
    ID: number;
    Nome: string;
    Usuario: string;
    tipo_usuario_ID: number;
    DataHora: string;
    Tags: Tag[];
}

export interface RespostaEditorUsuariosListar {
    Status: boolean;
    Dados: Usuario[];
    Mensagem: string;
}


export const editorUsuariosListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/usuarios/listar", dados);
}

export default editorUsuariosListar