import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoComumMetatagsListar {
    Link: string;
}

interface Metatags {
    Titulo: string;
    Capa: string;
    Texto: string;
}

interface Dados {
    Metatags: Metatags;
}

export interface RespostaComumMetatagsListar {
    Status: boolean;
    Dados: Dados;
    Mensagem: string;
}

export const comumMetatagsListar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/comum/metatags/listar", dados);
}

export default comumMetatagsListar
