import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoEditorTagsAdicionar {
    Nome: string;
}

interface Dados {
    ID: number,
    Slug: string
}
export interface RespostaEditorTagsAdicionar {
    Status: boolean;
    Dados: Dados
    Mensagem: string;
}

export const editorTagsAdicionar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/editor/tags/adicionar", dados);
}

export default editorTagsAdicionar
