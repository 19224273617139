import { Card, Grid, CardContent, Typography, Button, CardHeader } from "@material-ui/core"
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, ChatBubbleOutlineOutlined, ShareOutlined } from "@material-ui/icons"
import { useContext } from "react"
import { useHistory } from "react-router"
import contextoConfiguracoes from "../../../contextos/configuracoes"
import { contextoNoticia } from "../../../contextos/noticia"
import compartilharNoticia from "../../../funcoes/compartilharNoticia"
import percentualLikes from "../../../funcoes/percentualLikes"
import ReprodutorIncorporado from "../../../templates/ReprodutorIncorporado"
import Descricao from "./Descricao"

export const Cartao = () => {

    const { estado, acoes } = useContext(contextoNoticia);
    const noticia = estado.Dados?.Noticia;
    const tags = estado.Dados?.Tags;
    const usarTemaPetro = useContext(contextoConfiguracoes).estado.usarTemaPetro;

    const history = useHistory();
    const stopPropagation = (event: any) => event.stopPropagation();

    return noticia ? <>
        <Card>
            <Grid container>
                <Grid item xs={12} md={6} style={{
                    minHeight: '200px',
                    background: `url("${noticia.Capa}")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}>
                    <ReprodutorIncorporado link={noticia.Link}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardContent>
                        <Typography variant="h6">{noticia.Titulo}</Typography>
                        <br />
                        <Typography variant="body1">{noticia.Texto}</Typography>
                        <br />
                        <Descricao />
                        <br />
                        Postado por {noticia.usuario_Nome} {noticia.usuario_Premium && <>
                            <img src="/imagens/selecionado.png" />
                        </>} • {noticia.DataHora}
                        {!noticia.Link.includes("api.whatsapp.com") && <><br />
                            {(new URL(noticia.Link).hostname)} <Button onClick={() => window.open(noticia.Link, '_blank')}>Ver matéria completa</Button>
                        </>}
                        <br />
                        {tags && tags.map(tag => {
                            return <Button size="small" style={{
                                display: 'inline - block',
                                width: 'auto',
                                textAlign: 'left',
                                paddingLeft: '0px',
                                paddingRight: '3px',
                                minWidth: '0px'
                            }} onTouchStart={stopPropagation} onMouseDown={stopPropagation} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push("/tag/" + tag.Slug);
                            }}>
                                <span style={{ fontWeight: 'bold' }}>#{tag.Nome}&nbsp;</span>
                            </Button>
                        })}
                        <Grid style={{ marginTop: '15px' }} container>
                            <Grid item xs={4}>
                                <Button onClick={() => acoes.reacao(2)} fullWidth size="small" startIcon={noticia.tipo_reacao_ID === 2 ? <ThumbUp /> : <ThumbUpOutlined />}>{noticia.Likes}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={() => acoes.reacao(3)} fullWidth size="small" startIcon={noticia.tipo_reacao_ID === 3 ? <ThumbDown /> : <ThumbDownOutlined />}>{noticia.Deslikes}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth size="small" startIcon={<ShareOutlined />} onClick={() => {
                                    compartilharNoticia({
                                        text: noticia.Texto,
                                        title: noticia.Titulo,
                                        url: "https://noticiaverificada.com/noticia/" + noticia.Slug
                                    });
                                }}>Enviar</Button>
                            </Grid>
                            <Grid item xs={8} style={{ marginTop: '-13px' }}>
                                <div style={{ transition: 'width 0.3s', display: 'inline-block', background: usarTemaPetro ? '#eee' : 'gray', width: percentualLikes(noticia.Likes, noticia.Deslikes, "Like"), height: '2px' }}></div>
                                <div style={{ transition: 'width 0.3s', display: 'inline-block', background: usarTemaPetro ? 'gray' : '#eee', width: percentualLikes(noticia.Likes, noticia.Deslikes, "Deslike"), height: '2px' }}></div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    </> : <>Notícia não encontrada!</>
}