import { Box, Tabs, Tab, DialogTitle, DialogContent } from "@material-ui/core";
import { useState } from "react";
import { ProvedorPublicacao } from "../../../contextos/publicacao";
import NoticiaLink from "./NoticiaLink";
import Whatsapp from "./Whatsapp";



export const AdicionarNoticia = () => {
    const [abaSelecionada, definirAbaSelecionada] = useState<"link" | "whatsapp">("link");
    return <ProvedorPublicacao>
        <DialogTitle>Adicionar Notícia</DialogTitle>
        <DialogContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={abaSelecionada} onChange={(_, novoValor) => definirAbaSelecionada(novoValor)} aria-label="Tipo de Notícia">
                    <Tab label="Link" value="link" />
                    <Tab label="Whatsapp" value="whatsapp" />
                </Tabs>
            </Box>
        </DialogContent>
        {abaSelecionada === "link" && <NoticiaLink />}
        {abaSelecionada === "whatsapp" && <Whatsapp />}
    </ProvedorPublicacao>
}

export default AdicionarNoticia;