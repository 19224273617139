const parserYoutube = (link: string) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = link.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}
export const ReprodutorIncorporado = ({link}: {link: string}) => {
    if (!link.includes("youtube.com/")) {
        return <></>
    }
    return <iframe
        src={`https://www.youtube.com/embed/${parserYoutube(link)}`}
        style={{ width: "100%", height: "100%", border: "0px" }}
        title="Reprodutor de vídeo do Youtube"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
}
export default ReprodutorIncorporado;