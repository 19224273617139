import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorEmailConfirmar {
	codigoVerificacao: string;
}

export interface RespostaLeitorEmailConfirmar {
    Status: boolean;
    Mensagem: string;
}

export const leitorEmailConfirmar = async (buscador: AxiosInstance, dados: RequisicaoLeitorEmailConfirmar): Promise<AxiosResponse> => {
    return buscador.post("/leitor/email/confirmar", dados);
}

export default leitorEmailConfirmar;