import { useMediaQuery } from "@material-ui/core";
import { Route, Switch } from "react-router";
import Anuncio from "../../templates/Anuncio";
import BarraAplicativoPrincipal from "../../templates/BarraAplicativoPrincipal";
import BotaoFlutuante from "../../templates/BotaoFlutuante";
import Noticias from "../../templates/Noticias";

const Inicio = () => {
    const movel = useMediaQuery('(max-width:600px)');
    return <>
        <BarraAplicativoPrincipal>
            <Switch>
                <Route path="/" exact render={props => {
                    //console.log("/");
                    return <Noticias key={props.match.path} estadoPersonalizado={
                        {
                            Limite: 15,
                            Pagina: 1,
                            Termo: '',
                            TagsIDs: [],
                            Ordem: "Popularidade",
                            Seguindo: false,
                            Pendente: false,
                            usuario_ID: undefined,
                            tipo: "Tudo"
                        }
                    } />
                }} />

                <Route path="/seguindo" exact render={props => {
                    //console.log("/seguindo");
                    return <Noticias key={props.match.path} estadoPersonalizado={
                        {
                            Limite: 15,
                            Pagina: 1,
                            Termo: '',
                            TagsIDs: [],
                            Ordem: "Popularidade",
                            Seguindo: true,
                            Pendente: false,
                            usuario_ID: undefined
                        }
                    } />
                }} />

                <Route path="/fato" exact render={props => {
                    return <Noticias key={props.match.path} estadoPersonalizado={
                        {
                            Limite: 15,
                            Pagina: 1,
                            Termo: '',
                            TagsIDs: [],
                            Ordem: "Popularidade",
                            Seguindo: false,
                            Pendente: false,
                            usuario_ID: undefined,
                            tipo: "Fato"
                        }
                    } />
                }} />

                <Route path="/fake" exact render={props => {
                    return <Noticias key={props.match.path} estadoPersonalizado={
                        {
                            Limite: 15,
                            Pagina: 1,
                            Termo: '',
                            TagsIDs: [],
                            Ordem: "Popularidade",
                            Seguindo: false,
                            Pendente: false,
                            usuario_ID: undefined,
                            tipo: "Fake"
                        }
                    } />
                }} />
            </Switch>
        </BarraAplicativoPrincipal>
        {movel && <Anuncio anuncio_posicao_ID={2} />}
        <BotaoFlutuante />
    </>;
}
export default Inicio