export const findGetParameter = (parameterName: string) => {
    var result = null,
        tmp = [];
    document.location.search
        .substring(1)
        .split("&")
        .forEach(item => {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

export default findGetParameter;