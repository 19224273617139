import { Avatar, Box, Button, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, SwipeableDrawer } from "@material-ui/core"
import { Close, Person } from "@material-ui/icons"
import { useContext, useEffect, useState } from "react"
import { contextoAplicacao } from "../../contextos/aplicacao";
import { contextoServico } from "../../contextos/servicos";
import comumUsuariosPremiumListar, { RespostaComumUsuariosPremiumListar } from "../../servicos/comumUsuariosPremiumListar";

export default () => {

    const { buscador } = useContext(contextoServico);
    const { estado, acoes } = useContext(contextoAplicacao);
    const [usuarios, definirUsuarios] = useState<RespostaComumUsuariosPremiumListar["Dados"]>();
    const aoAbrir = () => {
        acoes.definirEstado({ ...estado, usuariosPremiumAberto: true });
    }
    const aoFechar = () => {
        acoes.definirEstado({ ...estado, usuariosPremiumAberto: false });
    }
    useEffect(() => {
        (async () => {
            const { data: resposta }: { data: RespostaComumUsuariosPremiumListar } = await comumUsuariosPremiumListar(buscador);
            definirUsuarios(resposta.Dados);
        })()
    }, []);
    return <>
        <SwipeableDrawer anchor="left" open={estado.usuariosPremiumAberto} onOpen={aoAbrir} onClose={aoFechar}>
            <div style={{
                width: "330px",
                maxWidth: "100%",
                paddingTop: "20px"
            }}>
                <List>
                    <ListItem>
                        Usuários Assinantes
                        <Button style={{ marginLeft: 'auto' }} size="large" endIcon={<Close />} onClick={aoFechar} />
                    </ListItem>
                    {!usuarios ? <>
                        <br />
                        <LinearProgress />
                    </> :
                        usuarios.map(usuario => (
                            <ListItem key={usuario.ID}>
                                <ListItemAvatar>
                                    <Avatar src={usuario.Foto}>
                                        <Person />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={usuario.Nome} secondary={
                                    <>
                                        @{usuario.Usuario} <img src="/imagens/selecionado.png" />
                                    </>
                                } />
                            </ListItem>
                        ))
                    }
                    {Array.from(new Array(10).keys()).map((i) => {
                        return
                    })}
                </List>
            </div>
        </SwipeableDrawer>
    </>
}