import { Typography, Divider, List, Card } from "@material-ui/core";
import { ChatBubble, SentimentDissatisfied } from "@material-ui/icons";
import { useContext, useState } from "react";
import { contextoNoticia } from "../../../contextos/noticia";
import { estiloComentarios } from "../estilo";
import ComentariosDigitacao from "./ComentariosDigitacao";
import ComentariosListagem from "./ComentariosListagem";

const Comentarios = () => {

    const { estado } = useContext(contextoNoticia);
    const { Dados } = estado;
    const noticia = Dados?.Noticia;

    if(!noticia) {
        return <></>
    }

    return <>
        <Typography variant="h5">{noticia.TotalComentarios} Comentário{noticia.TotalComentarios !== 1 && "s"}</Typography>
        <br />
        <List>
            <ComentariosDigitacao />
            <ComentariosListagem />
            {
                noticia.TotalComentarios === 0 ? <>
                    <Card style={{
                        textAlign: "center",
                        marginTop: '60px',
                        marginBottom: '40px',
                        padding: '30px',
                        paddingTop: '68px',
                        paddingBottom: '68px',
                        bottom: '0px'
                    }}>
                        <SentimentDissatisfied /> <br />
                        <Typography variant="h6">
                            Parece que não há comentários ainda
                        </Typography>
                        <Typography variant="subtitle2">
                            Seja o primeiro a dar uma opinião!
                        </Typography>
                    </Card>
                </> : noticia.TotalComentarios < 2 &&
                <>
                    <Card style={{
                        textAlign: "center",
                        marginTop: '40px',
                        marginBottom: '40px',
                        padding: '30px',
                        paddingTop: '26px',
                        paddingBottom: '26px',
                        bottom: '0px'
                    }}>
                        <ChatBubble /> <br />
                        <Typography variant="h6">
                            Queremos ouvir sua opinião
                        </Typography>
                        <Typography variant="subtitle2">
                            Use a caixa de comentários logo a cima para comentar na publicação!
                        </Typography>
                    </Card>
                </>
            }
        </List>
    </>
}

export default Comentarios;