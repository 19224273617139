import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useContext } from "react";
import { contextoAplicacao } from "../../contextos/aplicacao";
import contextoAutenticacao from "../../contextos/autenticacao";
import { EsconderNaRolagem } from "../EsconderNaRolagem";
import estiloIndex from "./estilo";

const BotaoFlutuante = () => {

    const { estado, acoes } = useContext(contextoAplicacao);
    const { estado: estadoAutenticacao } = useContext(contextoAutenticacao);
    const estilo = estiloIndex();

    const acaoCoroa = () => {
        acoes.definirEstado({ ...estado, usuariosPremiumAberto: !estado.usuariosPremiumAberto });
    }

    const acaoMais = () => {
        if (estadoAutenticacao.autenticado) {
            acoes.definirEstado({ ...estado, adicionarAberto: !estado.adicionarAberto });
        }
        else {
            acoes.definirEstado({ ...estado, loginAberto: true });
        }
    };

    return <>
        <EsconderNaRolagem direcao="left">
            <Fab onClick={acaoCoroa} color="primary" className={estilo.fab2}>
                <img src="/imagens/crown.svg" alt="coroa" style={{
                    width: "36px"
                }} />
            </Fab>
        </EsconderNaRolagem>
        <EsconderNaRolagem direcao="left">
            <Fab onClick={acaoMais} color="primary" className={estilo.fab}>
                <Add />
            </Fab>
        </EsconderNaRolagem>
    </>
}
export default BotaoFlutuante;