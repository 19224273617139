import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import findGetParameter from "../funcoes/findGetParameter";
import comumAutenticacaoSegurancaAlterar, { RequisicaoComumAutenticacaoSegurancaAlterar, RespostaComumAutenticacaoSegurancaAlterar } from "../servicos/comumAutenticacaoSegurancaAlterar";
import comumAutenticacaoSegurancaSolicitar, { RequisicaoComumAutenticacaoSegurancaSolicitar } from "../servicos/comumAutenticacaoSegurancaSolicitar";
import contextoAutenticacao from "./autenticacao";
import { contextoServico } from "./servicos";


interface Estado {
    tipoAlteracao: "AlterarSenha" | "AlterarEmail" | "ExcluirConta",
    email?: string,
    codigo?: string,
    novaSenha?: string,
    confirmarNovaSenha?: string,
    novoEmail?: string
}

interface ContextoSeguranca {
    estado: Estado;
    acoes: {
        solicitarAlteracao: () => Promise<void>;
        alterar: () => Promise<void>;
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
    };
}

export const contextoSeguranca = createContext({} as ContextoSeguranca);

export const ProvedorSeguranca = ({ children }: { children: ReactNode }) => {

    const history = useHistory();

    const valoresPadrao: Estado = {
        tipoAlteracao: "AlterarSenha"
    }

    const [estado, definirEstado] = useState(valoresPadrao);

    const { usarRota, despachoMensagens } = useContext(contextoServico);

    const {acoes: acoesSeguranca} = useContext(contextoAutenticacao);

    const acoes = {
        solicitarAlteracao: async () => {
            const requisicao: RequisicaoComumAutenticacaoSegurancaSolicitar = {
                tipoAlteracao: estado.tipoAlteracao,
                email: estado.email
            };
            const resposta: RespostaComumAutenticacaoSegurancaAlterar = await usarRota(
                comumAutenticacaoSegurancaSolicitar, requisicao
            );
            if (resposta.Status) {
                despachoMensagens({ acao: "NovaMensagem", dados: { mensagem: resposta.Mensagem, tipo: "success" } });
                history.push("/seguranca/confirmar");
            }
        },
        alterar: async () => {
            if(!estado.codigo) {
                despachoMensagens({acao: "NovaMensagem", dados: {mensagem: "Digite o código corretamente!", tipo: "error"}});
                return;
            }
            if(estado.novaSenha !== estado.confirmarNovaSenha && estado.tipoAlteracao === "AlterarSenha") {
                despachoMensagens({acao: "NovaMensagem", dados: {mensagem: "As senhas não conferem!", tipo: "error"}});
                return;
            }
            const requisicao: RequisicaoComumAutenticacaoSegurancaAlterar = {
                tipoAlteracao: estado.tipoAlteracao,
                token: estado.codigo,
                novoEmail: estado.tipoAlteracao === "AlterarEmail" ? estado.novoEmail : undefined,
                novaSenha: estado.tipoAlteracao === "AlterarSenha" ? estado.novaSenha : undefined
            };
            const resposta: RespostaComumAutenticacaoSegurancaAlterar = await usarRota(comumAutenticacaoSegurancaAlterar, requisicao);
            if(resposta.Status) {
                despachoMensagens({acao: "NovaMensagem", dados: {mensagem: resposta.Mensagem, tipo: "success"}});
                if(estado.tipoAlteracao === "AlterarSenha") {
                    await acoesSeguranca.sair();
                    await acoesSeguranca.entrar(String(estado.email), String(estado.novaSenha));
                }
                history.push("/");
                return;
            }
            despachoMensagens({acao: "NovaMensagem", dados: {mensagem: resposta.Mensagem || "Não foi possível alterar sua senha.", tipo: "error"}});
        },
        definirEstado
    }

    const valores = {
        estado,
        acoes
    }

    useEffect(() => {
        const tipoAlteracao = Buffer.from(findGetParameter("ta") || "", 'base64').toString() || null;
        const codigo = Buffer.from(findGetParameter("c") || "", 'base64').toString() || null;
        if (
            tipoAlteracao
            && (
                tipoAlteracao === "AlterarSenha" ||
                tipoAlteracao === "AlterarEmail" ||
                tipoAlteracao === "ExcluirConta"
            )
            && codigo
        ) {
            //console.log(tipoAlteracao, codigo);
            definirEstado({ ...estado, tipoAlteracao, codigo });
        }
    }, []);

    const {estado: estadoAutenticacao} = useContext(contextoAutenticacao);

    useEffect(() => {
        if(estadoAutenticacao.Email) {
            definirEstado({...estado, email: estadoAutenticacao.Email});
        }
    },[estadoAutenticacao.Email]);


    return <contextoSeguranca.Provider value={valores}>
        {children}
    </contextoSeguranca.Provider>
}