import { useMediaQuery } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useState, useEffect, useContext } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import contextoAutenticacao from "../../contextos/autenticacao";
import baseURL from "../../funcoes/baseURL";
import se from "../../funcoes/se";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import FullyFrame from "../../templates/FullyFrame";

const Gerenciamento = () => {

    const [abaAtiva, definirAbaAtiva] = useState("/gerenciamento/noticias");

    const history = useHistory();

    useEffect(() => {
        history.listen((e) => {
            definirAbaAtiva(e.pathname);
        })
    }, [history])

    useEffect(() => {
        definirAbaAtiva(history.location.pathname);
    }, [])

    const max450px = useMediaQuery('(max-width: 450px)');
    const style = {
        color: "#ccc",
        borderColor: 'gray',
        fontSize: se(max450px, "9px")
    };

    const { tipo_usuario_ID } = useContext(contextoAutenticacao).estado;

    return <>
        <BarraAplicativoSecundaria fullWidth backToHome>
            <ToggleButtonGroup value={abaAtiva} size="small" style={{ height: '25px' }}>
                <ToggleButton value="/gerenciamento/noticias" onClick={() => history.push("/gerenciamento/noticias")} style={style}>
                    Notícias
                </ToggleButton>
                {tipo_usuario_ID === 1 &&
                    <ToggleButton value="/gerenciamento/anuncios" onClick={() => history.push("/gerenciamento/anuncios")} style={style}>
                        Anúncios
                    </ToggleButton>
                }
                {tipo_usuario_ID === 1 &&
                    <ToggleButton value="/gerenciamento/tags" onClick={() => history.push("/gerenciamento/tags")} style={style}>
                        Tags
                    </ToggleButton>
                }
                {tipo_usuario_ID === 1 &&
                    <ToggleButton value="/gerenciamento/usuarios" onClick={() => history.push("/gerenciamento/usuarios")} style={style}>
                        Usuários
                    </ToggleButton>
                }
            </ToggleButtonGroup>
        </BarraAplicativoSecundaria>
        <Switch>
            <Route path="/gerenciamento/noticias">
                <div style={{ width: "100%", height: "calc(100vh - 40px)" }}>
                    <FullyFrame src={`${baseURL("admin")}/noticia_ver.php?token={token}&theme={theme}`} />
                </div>
            </Route>
            <Route path="/gerenciamento/anuncios">
                <div style={{ width: "100%", height: "calc(100vh - 40px)" }}>
                    <FullyFrame src={`${baseURL("admin")}/anuncio.php?token={token}&theme={theme}`} />
                </div>
            </Route>
            <Route path="/gerenciamento/tags">
                <div style={{ width: "100%", height: "calc(100vh - 40px)" }}>
                    <FullyFrame src={`${baseURL("admin")}/tag.php?token={token}&theme={theme}`} />
                </div>
            </Route>
            <Route path="/gerenciamento/usuarios">
                <div style={{ width: "100%", height: "calc(100vh - 40px)" }}>
                    <FullyFrame src={`${baseURL("admin")}/usuario_ver.php?token={token}&theme={theme}`} />
                </div>
            </Route>
        </Switch>
    </>;
}

export default Gerenciamento;