import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button, Divider } from "@material-ui/core"
import { Fragment, useContext } from "react"
import { useHistory } from "react-router";
import contextoTags from "../../contextos/tags"

export const Tags = ({ tipo, termo }: { tipo: "destaques" | "seguindo" | "pesquisa", termo?: string }) => {

    const { estado, acoes } = useContext(contextoTags);
    let tags = estado.Tags;

    const history = useHistory();
    const acaoVerTag = (tagSlug: string) => {
        history.push(`/tag/${tagSlug}`);
    }

    if (tipo === "destaques" || tipo === "seguindo") {
        tags = tags.sort((a, b) => b.Quantidade - a.Quantidade).filter((tag, index) => tipo === "destaques" ? (index <= 5) : (tag.Seguindo));
    }
    if (tipo === "pesquisa") {
        tags = tags.filter(tag => {
            return (
                (tag.Nome + tag.Slug).replaceAll(" ", "").toUpperCase()
            ).includes(
                (termo || "").replaceAll(" ", "").toUpperCase()
            );
        }).filter((_, index) => index <= 5)
    }

    return <>
        <List>
            {tags.map(tag => (
                <Fragment key={tag.ID}>
                    <ListItem button onClick={() => acaoVerTag(tag.Slug)} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <ListItemAvatar>
                            <Avatar>#</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={
                            <Typography variant="subtitle1" noWrap>
                                {tag.Nome}
                            </Typography>
                        } secondary={
                            <Typography variant="body1" noWrap>
                                {tag.Quantidade} Publicações
                            </Typography>
                        } />
                        <Button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            acoes.seguirTag(tag.ID)
                        }} variant={"contained"} color={tag.Seguindo ? "default" : "primary"}>
                            {tag.Seguindo ? "Seguindo" : "Seguir"}
                        </Button>
                    </ListItem><Divider variant="inset" component="li" />
                </Fragment>
            ))}
        </List>
    </>
}

export default Tags