import { Capacitor } from "@capacitor/core";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import comumAutenticacaoEntrar, { RequisicaoComumAutenticacaoEntrar, RespostaComumAutenticacaoEntrar } from "../servicos/comumAutenticacaoEntrar";
import comumAutenticacaoSair, { RespostaComumAutenticacaoSair } from "../servicos/comumAutenticacaoSair";
import comumCadastrar, { RequisicaoComumCadastrar, RespostaComumCadastrar } from "../servicos/comumCadastrar";
import comumInicio, { RespostaComumInicio } from "../servicos/comumInicio";
import leitorAtualizar, { RequisicaoLeitorAtualizar, RespostaLeitorAtualizar } from "../servicos/leitorAtualizar";
import { contextoAplicacao } from "./aplicacao";
import contextoConfiguracoes from "./configuracoes";
import { contextoServico } from "./servicos";

import OneSignalCordova from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';
import { sha1 } from "../funcoes/sha1";
import leitorEmailConfirmar, { RequisicaoLeitorEmailConfirmar, RespostaLeitorEmailConfirmar } from "../servicos/leitorEmailConfirmar";
import leitorEmailAlterar, { RequisicaoLeitorEmailAlterar, RespostaLeitorEmailAlterar } from "../servicos/leitorEmailAlterar";

interface Estado {
    autenticado: boolean,
    Nome?: RespostaComumAutenticacaoEntrar['Dados']['Nome'],
    tipo_usuario_ID?: RespostaComumAutenticacaoEntrar['Dados']['tipo_usuario_ID'],
    usuario_ID?: RespostaComumAutenticacaoEntrar['Dados']['usuario_ID'],
    Foto?: RespostaComumAutenticacaoEntrar['Dados']['Foto'],
    Usuario?: RespostaComumAutenticacaoEntrar['Dados']['Usuario'],
    Email?: RespostaComumAutenticacaoEntrar['Dados']['Email'],
    EmailConfirmado?: RespostaComumAutenticacaoEntrar['Dados']['EmailConfirmado'],
}

interface ContextoAutenticacao {
    estado: Estado,
    acoes: {
        definirEstado: React.Dispatch<React.SetStateAction<Estado>>;
        entrar: (Login: string, Senha: string) => Promise<void>;
        entrarPeloToken: (Token: string) => Promise<void>;
        sair: () => Promise<void>;
        cadastrar: (dados: RequisicaoComumCadastrar) => Promise<void>;
        alterarPerfil: ({Nome, Usuario, FotoPerfil}: {Nome?: string, Usuario?: string, FotoPerfil?: File}) => Promise<void>;
        navegar: (rota: string, requerLogin?: boolean) => boolean;
        confirmarEmail: (dados: RequisicaoLeitorEmailConfirmar) => Promise<boolean>;
        alterarEmail: (dados: RequisicaoLeitorEmailAlterar) => Promise<boolean>;
    }
}

export const contextoAutenticacao = createContext({} as ContextoAutenticacao);

export const ProvedorAutenticacao = ({ children }: { children: ReactNode }) => {

    const estadoPadrao: Estado = {
        autenticado: false
    };

    const [estado, definirEstado] = useState(estadoPadrao);

    const { usarRota, buscador, despachoMensagens } = useContext(contextoServico);

    const { estado: estadoConfiguracoes, acoes: acoesConfiguracoes } = useContext(contextoConfiguracoes);

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);

    const history = useHistory();

    const nativo = Capacitor.isNativePlatform();

    const acoes = {
        definirEstado,
        entrar: async (Login: string, Senha: string) => {
            const requisicao: RequisicaoComumAutenticacaoEntrar = { Login, Senha };
            const resposta: RespostaComumAutenticacaoEntrar = await usarRota(comumAutenticacaoEntrar, requisicao);
            if (resposta.Status) {
                definirEstado({
                    autenticado: true,
                    Nome: resposta.Dados.Nome,
                    tipo_usuario_ID: resposta.Dados.tipo_usuario_ID,
                    usuario_ID: resposta.Dados.usuario_ID,
                    Foto: resposta.Dados.Foto,
                    Usuario: resposta.Dados.Usuario,
                    Email: resposta.Dados.Email,
                    EmailConfirmado: resposta.Dados.EmailConfirmado
                });
                acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, tokenAutenticacao: resposta.Dados.Token });

                //console.log("Fechar tela login...");
                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: false });
            }
            else {
                definirEstado({ autenticado: false });
                acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, tokenAutenticacao: undefined });
            }
        },
        entrarPeloToken: async (Token: string) => {
            const resposta: RespostaComumInicio = await usarRota(comumInicio);
            if (resposta.Status && resposta.Dados.Usuario) {
                definirEstado({
                    autenticado: true,
                    Nome: resposta.Dados.Usuario.Nome,
                    tipo_usuario_ID: resposta.Dados.Usuario.TipoID,
                    usuario_ID: resposta.Dados.Usuario.ID,
                    Foto: resposta.Dados.Usuario.Foto,
                    Usuario: resposta.Dados.Usuario.Usuario,
                    Email: resposta.Dados.Usuario.Email,
                    EmailConfirmado: resposta.Dados.Usuario.EmailConfirmado
                });
                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: false });
            }
            else {
                definirEstado({ autenticado: false });
                acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, tokenAutenticacao: undefined });
            }
        },
        sair: async () => {
            const resposta: RespostaComumAutenticacaoSair = await usarRota(comumAutenticacaoSair);
            if (resposta.Status) {
                definirEstado({
                    autenticado: false
                });
                acoesConfiguracoes.definirEstado({ ...estadoConfiguracoes, tokenAutenticacao: undefined })
            }
        },
        cadastrar: async (dados: RequisicaoComumCadastrar) => {
            const resposta: RespostaComumCadastrar = await usarRota(comumCadastrar, dados);
            if (resposta.Status) {
                acoes.entrar(dados.Email, dados.Senha);
            }
        },
        alterarPerfil: async ({Nome, Usuario, FotoPerfil}: {Nome?: string, Usuario?: string, FotoPerfil?: File}) => {
            // Upload Foto
            const formulario = new FormData();
            FotoPerfil && formulario.append("Arquivo", FotoPerfil, FotoPerfil.name);
            let LinkFotoPerfil: string | undefined = undefined;
            if (FotoPerfil) {
                const resposta = await buscador.post("/leitor/anexos/adicionar", formulario);
                if (resposta.data && resposta.data.Status) {
                    LinkFotoPerfil = resposta.data.Dados[0] || undefined;
                }
            }
            // Atualizar
            const requisicaoLeitorAtualizar: RequisicaoLeitorAtualizar = {
                Nome: Nome,
                Usuario: Usuario,
                Foto: LinkFotoPerfil
            };
            const respostaLeitorAtualizar: RespostaLeitorAtualizar = await usarRota(leitorAtualizar, requisicaoLeitorAtualizar);
            if (respostaLeitorAtualizar.Status) {
                definirEstado({ ...estado, Nome, Foto: LinkFotoPerfil ? LinkFotoPerfil : estado.Foto });
                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: false });
            }
        },
        navegar: (rota: string, requerLogin = true) => {
            if (requerLogin && !estado.autenticado) {
                acoesAplicacao.definirEstado({ ...estadoAplicacao, loginAberto: true });
                return false;
            }
            history.push(rota);
            return true;
        },
        confirmarEmail: async (dados: RequisicaoLeitorEmailConfirmar) => {
            const resposta: RespostaLeitorEmailConfirmar = await usarRota(leitorEmailConfirmar, dados);
            if (resposta.Status) {
                definirEstado({ ...estado, EmailConfirmado: true });
                return true;
            }
            despachoMensagens({acao: "NovaMensagem", dados: {mensagem: resposta.Mensagem, tipo: "error"}});
            return false;
        },
        alterarEmail: async (dados: RequisicaoLeitorEmailAlterar) => {
            const resposta: RespostaLeitorEmailAlterar = await usarRota(leitorEmailAlterar, dados);
            if (resposta.Status) {
                definirEstado({ ...estado, Email: dados.novoEmail });
                return true;
            }
            despachoMensagens({acao: "NovaMensagem", dados: {mensagem: resposta.Mensagem, tipo: "error"}});
            return false;
        }
    }

    const dados: ContextoAutenticacao = {
        estado,
        acoes
    };

    const [prosiga, definirProsiga] = useState(false);

    useEffect(() => {
        if(estado.autenticado && estado.usuario_ID) {
            if (nativo) {
                OneSignalCordova.setExternalUserId(sha1(estado.usuario_ID.toString()));
                //console.log("SUBSCRIBE");
            }
            else {
                OneSignalWeb.setExternalUserId(sha1(estado.usuario_ID.toString()));
            }
        }
    }, [estado.autenticado]);

    useEffect(() => {
        const Token = estadoConfiguracoes.tokenAutenticacao;
        if (Token) {
            acoes.entrarPeloToken(Token).then(() => {
                definirProsiga(true);
            });
            return;
        }
        definirProsiga(true);
    }, [])

    return <contextoAutenticacao.Provider value={dados}>
        {prosiga && children}
    </contextoAutenticacao.Provider>
}

export default contextoAutenticacao