import { Card, Container, Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core"
import { TabContext, TabPanel } from "@material-ui/lab";
import { useState } from "react";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria"
import AbaNoticias from "./templates/AbaNoticias";
import AbaTags from "./templates/AbaTags";
import AbaUsuarios from "./templates/AbaUsuarios";

export const Administracao = () => {

    const [aba, definirAba] = useState("1");

    const dispositivoMovel = useMediaQuery("(max-width: 959px)");
    return <>
        <BarraAplicativoSecundaria />
        <br />
        <Container maxWidth="md">
            <TabContext value={aba}>
                <Grid container spacing={2} style={{ minHeight: dispositivoMovel ? '1px' : '80vh' }}>
                    <Grid item xs={12} md={3}>
                        <Card style={{ height: '100%' }}>
                            <Tabs orientation={dispositivoMovel ? "horizontal" : "vertical"} value={aba} onChange={(_, valor) => definirAba(valor)} variant="fullWidth">
                                <Tab value="1" label="Notícias" />
                                <Tab value="2" label="Tags" />
                                <Tab value="3" label="Usuários" />
                            </Tabs>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Card style={{ minHeight: dispositivoMovel ? '1px' : '80vh' }}>
                            <TabPanel value={"1"} style={{padding: '0px'}}>
                                <AbaNoticias />
                            </TabPanel>
                            <TabPanel value={"2"} style={{padding: '0px'}}>
                                <AbaTags />
                            </TabPanel>
                            <TabPanel value={"3"} style={{padding: '0px'}}>
                                <AbaUsuarios />
                            </TabPanel>
                        </Card>
                    </Grid>
                </Grid>
            </TabContext>
        </Container>
    </>
}

export default Administracao