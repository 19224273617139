import React, { ReactNode, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { AccountCircle, Notifications, Search, Brightness6 } from '@material-ui/icons';
import { CardActionArea, IconButton } from '@material-ui/core';
import { useContext } from 'react';
import contextoConfiguracoes from '../../contextos/configuracoes';
import { estiloIndex } from './estilo';
import { EsconderNaRolagem } from '../EsconderNaRolagem';
import se from '../../funcoes/se';
import { useHistory } from 'react-router';
import { contextoAplicacao } from '../../contextos/aplicacao';
import baseURL from '../../funcoes/baseURL';
import contextoAutenticacao from '../../contextos/autenticacao';

const BarraAplicativoPrincipal = (props: { children: ReactNode }) => {

    const estilo = estiloIndex();

    const { acoes, estado } = useContext(contextoConfiguracoes);

    const links = [
        {
            nome: "Destaques",
            link: "/",
            requerLogin: false
        },
        {
            nome: "Seguindo",
            link: "/seguindo",
            requerLogin: true
        },
        {
            nome: "Fato",
            link: "/fato",
            requerLogin: false
        },
        {
            nome: "Fake",
            link: "/fake",
            requerLogin: false
        }
    ];

    const history = useHistory();

    const { estado: estadoAplicacao, acoes: acoesAplicacao } = useContext(contextoAplicacao);

    const { acoes: acoesAutenticacao } = useContext(contextoAutenticacao);

    const acaoConta = () => {
        acoesAplicacao.definirEstado({ ...estadoAplicacao, menuAberto: true });
    };

    const acaoPesquisar = () => {
        history.push("/pesquisa");
    };

    const acaoNotificacoes = () => {
        history.push("/notificacoes");
    };

    const acaoLogo = () => {
        history.push("/");
    };

    const [abaAtiva, definirAbaAtiva] = useState("/");

    useEffect(() => {
        history.listen((e) => {
            definirAbaAtiva(e.pathname);
        })
    }, [history])

    useEffect(() => {
        definirAbaAtiva(history.location.pathname);
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <EsconderNaRolagem>
                <AppBar>
                    <Container maxWidth={"md"}>
                        <div className={estilo.topoPrincipal}>
                            <div style={{ display: 'flex', marginTop: '-2px' }}>
                                <CardActionArea onClick={acaoLogo}>
                                    <img src={`${baseURL("imagem")}/logotipo.svg`} style={{ height: '36px', maxHeight: "36px", maxWidth: "calc(100% - 15px)", filter: estado.usarTemaPetro ? 'grayscale(1)' : '' }} />
                                </CardActionArea>
                            </div>
                            <div style={{ flexGrow: 1 }}></div>
                            <IconButton color="primary" className={estilo.buttonHeader} onClick={acoes.trocarTema}>
                                <Brightness6 />
                            </IconButton>
                            <IconButton color="primary" className={estilo.buttonHeader} onClick={acaoPesquisar}>
                                <Search />
                            </IconButton>
                            <IconButton color="primary" className={estilo.buttonHeader} onClick={acaoNotificacoes}>
                                <Notifications />
                            </IconButton>
                            <IconButton color="primary" className={estilo.buttonHeader} onClick={acaoConta}>
                                <AccountCircle />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                            {
                                links.map(link => (
                                    <Typography
                                        onClick={() => {
                                            if (acoesAutenticacao.navegar(link.link, link.requerLogin)) {
                                                definirAbaAtiva(link.nome);
                                            }
                                        }}
                                        className={se(link.nome !== "Fake", estilo.link)}
                                    >
                                        <div className={se(abaAtiva === link.link, estilo.linkAtivo) + " " + estilo.linkTexto}>
                                            <CardActionArea>
                                                <Typography>
                                                    {link.nome}
                                                </Typography>
                                            </CardActionArea>
                                        </div>
                                    </Typography>
                                ))
                            }
                        </div>
                    </Container>
                </AppBar>
            </EsconderNaRolagem>
            <Container maxWidth={"md"}>
                <div className={estilo.containerMargin} />
                <Box my={2}>
                    {props.children}
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default BarraAplicativoPrincipal