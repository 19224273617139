import React, { useContext, useState } from "react"
import { CardHeader, Avatar, Button, Tab, Tabs, Container, AppBar, ListItem, ListItemText, ListItemAvatar } from "@material-ui/core";
import { useParams } from "react-router"
import { contextoNoticias, ProvedorNoticias } from "../../contextos/noticias";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import Noticias from "../../templates/Noticias";
import contextoTags from "../../contextos/tags";
// import exemplos from "../../contexts/exemplos";
// import HideAppBarName from "../HideAppBarName";
// import CartaoNoticiaExemplo from "../../components/CartaoNoticiaExemplo";

const UsarTag = () => {
    let params: { slug: string } = useParams();

    const { estado, acoes } = useContext(contextoTags);

    const tag = estado.Tags.find(tag => tag.Slug === params.slug);

    return <div>
        <BarraAplicativoSecundaria />
        <br />
        {tag && <Container maxWidth="md">
            <ListItem style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <ListItemAvatar>
                    <Avatar>
                        #
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={tag.Nome}
                    secondary={
                        <>{tag.Quantidade} Postage{tag.Quantidade !== 1 ? 'ns' : "m"}</>
                    }
                />
                <Button onClick={() => acoes.seguirTag(tag.ID)} variant={"contained"} color={tag.Seguindo ? "default" : "primary"}>
                    {tag.Seguindo ? "Seguindo" : "Seguir"}
                </Button>
            </ListItem>
            <Noticias key={"tag" + tag.ID} estadoPersonalizado={{
                Limite: 15,
                Pagina: 1,
                Termo: '',
                TagsIDs: [tag.ID],
                Ordem: "Popularidade",
                Seguindo: false,
                Pendente: false,
                usuario_ID: undefined
            }} />
        </Container>}
    </div>
}

export const Tag = () => {
    return <UsarTag />
}

export default Tag;