import { Box, Button, ButtonBase, Card, CardContent, Container, Grid, List, ListItem, ListSubheader, Slider, Typography } from "@material-ui/core";
import BarraAplicativoSecundaria from "../../templates/BarraAplicativoSecundaria";
import AvatarEditor from 'react-avatar-editor'
import { useContext, useEffect, useRef, useState } from "react";
import SelecionarNoticia from "./selecionarNoticia";
import molduras from "./molduras";
import EditorFotos from "./editorFotos";
import RenderizarNoticia from "./renderizarNoticia";
import { baixarArquivo } from "../../funcoes/baixarArquivo";
import contextoAutenticacao from "../../contextos/autenticacao";

const Selador = () => {


    const editorRef = useRef<AvatarEditor>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [foto, definirFoto] = useState("");

    const [moldura, definirMoldura] = useState(molduras[0]);

    const imageRef = useRef<HTMLImageElement>(null);

    const aoAbrirArquivo: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const arquivos = e?.target.files;
        if (arquivos && arquivos.length > 0) {
            definirFoto(URL.createObjectURL(arquivos[0]));
        }
    }

    const abrirArquivo = () => {
        inputRef.current?.click();
    }

    const salvarFoto = () => {
        if (imageRef.current && editorRef.current && canvasRef.current) {
            const imagemMoldura = imageRef.current;
            const canvasFinal = canvasRef.current;
            const contextCanvasFinal = canvasFinal.getContext("2d") as CanvasRenderingContext2D;
            const canvasEditor = editorRef.current.getImage();
            canvasFinal.width = imagemMoldura.naturalWidth;
            canvasFinal.height = imagemMoldura.naturalHeight;
            contextCanvasFinal.drawImage(canvasEditor, 0, 0, imagemMoldura.naturalWidth, imagemMoldura.naturalHeight);
            contextCanvasFinal.drawImage(imagemMoldura, 0, 0, imagemMoldura.naturalWidth, imagemMoldura.naturalHeight);
            canvasFinal.toBlob((blob) => {
                if(blob) {
                    const url = URL.createObjectURL(blob);
                    baixarArquivo(url, `${new Date().getTime()}-foto.jpg`);
                }
            }, "image/jpeg", 0.9);
        }
    }

    const [noticia, selecionarNoticia] = useState<{
        Titulo: string;
        Link: string;
        Slug: string;
        Texto: string;
        usuario_Nome: string;
        usuario_Foto: string;
        Capa: string;
        DataHoraVerificacao: string;
        noticia_confiabilidade_ID: number;
        noticia_confiabilidade_Nome: string;
        usuario_Usuario: string;
    }>();

    useEffect(() => {
        //console.log("Selecionado: ", noticia);
    }, [noticia]);

    const [noticiaSrc, definirNoticiaSrc] = useState("");

    const salvarNoticia = () => {
        baixarArquivo(noticiaSrc, `${new Date().getTime()}-noticia.jpg`);
    }

    const {estado: estadoAutenticacao} = useContext(contextoAutenticacao);

    if(![1, 3, 4].includes(Number(estadoAutenticacao.tipo_usuario_ID))) {
        return <></>
    }

    return <>
        <BarraAplicativoSecundaria />
        <input style={{ display: "none" }} type="file" ref={inputRef} onChange={aoAbrirArquivo} />
        <canvas style={{ display: "none" }} ref={canvasRef} />
        <br />
        <br />
        <Container maxWidth="md">
            <Card>
                {/* <Typography variant="subtitle1" style={{
                    textAlign: "center",
                    padding: "5px",
                    backgroundColor: "#333",
                }}>Adicionar Selo na Foto</Typography> */}
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column" }}>
                            <List>
                                <ListSubheader>
                                    Selecione
                                </ListSubheader>
                                {molduras.map((molduraItem) => (
                                    <ListItem selected={molduraItem.url === moldura.url} onClick={() => {
                                        definirMoldura(molduraItem);
                                    }}>
                                        <ButtonBase key={molduraItem.nome}>
                                            {molduraItem.nome}
                                        </ButtonBase>
                                    </ListItem>
                                ))}
                            </List>
                            <Box style={{ flexGrow: 1 }} />
                            {moldura.tipo === "imagem" ? <>
                                <Button fullWidth variant="contained" onClick={abrirArquivo}>Abrir Foto</Button> <br />
                            </> : <>
                                <SelecionarNoticia setState={selecionarNoticia} />
                            </>}
                            {moldura.tipo === "imagem" && (
                                <Button fullWidth variant="contained" onClick={salvarFoto}>Salvar</Button>
                            )}
                            {moldura.tipo === "noticia" && (
                                <Button fullWidth variant="contained" onClick={salvarNoticia}>Salvar</Button>
                            )}
                        </Grid>
                        <Grid item xs={12} md={8} style={{
                            background: "gray"
                        }}>
                            {(moldura.tipo === "imagem" && foto) && <>
                                <EditorFotos imageRef={imageRef} editorRef={editorRef} moldura={moldura} foto={foto} />
                            </>}
                            {(moldura.tipo === "noticia" && noticia) && <>
                                <RenderizarNoticia noticia={noticia} moldura={moldura} definirNoticiaSrc={definirNoticiaSrc} />
                            </>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    </>
}
export default Selador;