import { createStyles, makeStyles, Theme } from "@material-ui/core";
 
export const estiloIndex = makeStyles((tema: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        buttonHeader: {
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "4px",
            marginRight: "5px",
            '&:hover': {
                backgroundColor: "white",
            },
            '&:last-child': {
                marginRight: "0px"
            },
            width: '25px',
            height: '25px',
        },
    });
});