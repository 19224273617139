import { AxiosInstance, AxiosResponse } from "axios";

export interface RequisicaoLeitorPagamentoCriar {
    plano_ID: number
}

export interface RespostaLeitorPagamentoCriar {
    Status: boolean;
    Mensagem: string;
    Dados: {
        id: string,
        init_point: string,
    }
}

export const leitorPagamentoCriar = async (buscador: AxiosInstance, dados: any): Promise<AxiosResponse> => {
    return buscador.post("/leitor/pagamento/criar", dados);
}

export default leitorPagamentoCriar;